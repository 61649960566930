
import i18n from "@/i18n";
import Vue from "vue";

export default Vue.extend({
  name: "SectionProduct",
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
    },
  },
  computed:{
    isPrinting(){
      return this.$store.getters["datamanager/isPrinting"];
    },
  },
  methods: {
    mustBeChecked(value: boolean, noDataValue: boolean) {
      if (this.isEditable) {
        if (!noDataValue && !value) return [i18n.t("rules.required")];
        else return [];
      }
    },
    clearHemophiliaProductPlasmaRow() {
      if (this.tempData.patientsHemophiliaProduct_Plasma_HasNoData) {
        this.tempData.patientsHemophiliaProduct_Plasma_TreatedTotal = null;
      }
    },
    clearHemophiliaProductCryoprecipitateRow() {
      if (this.tempData.patientsHemophiliaProduct_Cryoprecipitate_HasNoData) {
        this.tempData.patientsHemophiliaProduct_Cryoprecipitate_TreatedTotal =
          null;
      }
    },
    clearHemophiliaProductPlasmaDerivedConcentrateRow() {
      if (
        this.tempData
          .patientsHemophiliaProduct_PlasmaDerivedConcentrate_HasNoData
      ) {
        this.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_TreatedTotal =
          null;
      }
    },
    clearHemophiliaProductRecombinantConcentrateRow() {
      if (
        this.tempData.patientsHemophiliaProduct_RecombinantConcentrate_HasNoData
      ) {
        this.tempData.patientsHemophiliaProduct_RecombinantConcentrate_TreatedTotal =
          null;
      }
    },
    clearHemophiliaProductRecombinantExtendedHalfLifeConcentrateRow() {
      if (
        this.tempData
          .patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_HasNoData
      ) {
        this.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_TreatedTotal =
          null;
      }
    },
    clearHemophiliaProductDDAVPRow() {
      if (this.tempData.patientsHemophiliaProduct_DDAVP_HasNoData) {
        this.tempData.patientsHemophiliaProduct_DDAVP_TreatedTotal = null;
      }
    },
    clearHemophiliaWithInhibitorsProductEmicizumabRow() {
      if (
        this.tempData
          .patientsHemophiliaWithInhibitorsProduct_Emicizumab_HasNoData
      ) {
        this.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_TreatedTotal =
          null;
      }
    },
    clearHemophiliaWithoutInhibitorsProductEmicizumabRow() {
      if (
        this.tempData
          .patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_HasNoData
      ) {
        this.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_TreatedTotal =
          null;
      }
    },
    clearVWDProductPlasmaRow() {
      if (this.tempData.patientsVWDProduct_Plasma_HasNoData) {
        this.tempData.patientsVWDProduct_Plasma_TreatedTotal = null;
      }
    },
    clearVWDProductCryoprecipitateRow() {
      if (this.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData) {
        this.tempData.patientsVWDProduct_Cryoprecipitate_TreatedTotal = null;
      }
    },
    clearVWDProductPlasmaDerivedConcentrateRow() {
      if (this.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData) {
        this.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedTotal =
          null;
      }
    },
    clearVWDProductDDAVPRow() {
      if (this.tempData.patientsVWDProduct_DDAVP_HasNoData) {
        this.tempData.patientsVWDProduct_DDAVP_TreatedTotal = null;
      }
    },
    clearVWDProductDDAVPNasalRow() {
      if (this.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData) {
        this.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedTotal = null;
      }
    },
    clearVWDProductDDAVPIVOrSubcutaneousRow() {
      if (this.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData) {
        this.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedTotal =
          null;
      }
    },
    clearVWDProductRecombinantConcentrateRow() {
      if (this.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData) {
        this.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedTotal =
          null;
      }
    },
    clearVWDProductTranexamicAcidRow() {
      if (this.tempData.patientsVWDProduct_TranexamicAcid_HasNoData) {
        this.tempData.patientsVWDProduct_TranexamicAcid_TreatedTotal = null;
      }
    },
  },
});
