
import Vue from "vue";

export default Vue.extend({
  name: "SectionSeverity",
  data() {
    return {
      totalNotSumErrors: [] as string[],
    };
  },
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
    },
  },
  computed: {
    resaltGrey() {
      if (
        this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male &&
        this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female &&
        this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male &&
        this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
      ) {
        this.tempData.severityHemophiliaIsInformationAccurate = null;
        return true;
      }
      return false;
    },
    sumOfHAM(): number {
      return (
        (this.tempData.patientsHemophiliaTypeA_Severity_Mild_Male || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Moderate_Male || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Severe_Male || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Unknown_Male || 0)
      );
    },
    sumOfHAF(): number {
      return (
        (this.tempData.patientsHemophiliaTypeA_Severity_Mild_Female || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Moderate_Female || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Severe_Female || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Unknown_Female || 0)
      );
    },
    sumOfHBM(): number {
      return (
        (this.tempData.patientsHemophiliaTypeB_Severity_Mild_Male || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Moderate_Male || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Severe_Male || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Unknown_Male || 0)
      );
    },
    sumOfHBF(): number {
      return (
        (this.tempData.patientsHemophiliaTypeB_Severity_Mild_Female || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Moderate_Female || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Severe_Female || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Unknown_Female || 0)
      );
    },
  },
  methods: {
    removeFromErrors(column: string) {
      let index = this.totalNotSumErrors.indexOf(column);
      if (index > -1) {
        this.totalNotSumErrors.splice(index, 1);
      }
    },
    ruleTotalSum(column: string) {
      if (
        column == "HAM" &&
        !this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male &&
        this.tempData.patientsHemophiliaTypeA_Gender_Male != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeA_Gender_Male - this.sumOfHAM !=
          0
        ) {
          if (!this.totalNotSumErrors.includes(column)) {
            this.totalNotSumErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromErrors(column);
          return [true];
        }
      }
      if (
        column == "HAF" &&
        !this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female &&
        this.tempData.patientsHemophiliaTypeA_Gender_Female != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeA_Gender_Female - this.sumOfHAF !=
          0
        ) {
          if (!this.totalNotSumErrors.includes(column)) {
            this.totalNotSumErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromErrors(column);
          return [true];
        }
      }
      if (
        column == "HBM" &&
        !this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male &&
        this.tempData.patientsHemophiliaTypeB_Gender_Male != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeB_Gender_Male - this.sumOfHBM !=
          0
        ) {
          if (!this.totalNotSumErrors.includes(column)) {
            this.totalNotSumErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromErrors(column);
          return [true];
        }
      }
      if (
        column == "HBF" &&
        !this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female &&
        this.tempData.patientsHemophiliaTypeB_Gender_Female != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeB_Gender_Female - this.sumOfHBF !=
          0
        ) {
          if (!this.totalNotSumErrors.includes(column)) {
            this.totalNotSumErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromErrors(column);
          return [true];
        }
      }
    },
    clearHemophiliaTypeASeverityMaleRow() {
      if (this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male) {
        this.tempData.patientsHemophiliaTypeA_Severity_Mild_Male = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Moderate_Male = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Severe_Male = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Unknown_Male = null;
        this.removeFromErrors("HAM");
      }
    },
    clearHemophiliaTypeASeverityFemaleRow() {
      if (this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female) {
        this.tempData.patientsHemophiliaTypeA_Severity_Mild_Female = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Moderate_Female = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Severe_Female = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Unknown_Female = null;
        this.removeFromErrors("HAF");
      }
    },
    clearHemophiliaTypeBSeverityMaleRow() {
      if (this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male) {
        this.tempData.patientsHemophiliaTypeB_Severity_Mild_Male = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Moderate_Male = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Severe_Male = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Unknown_Male = null;
        this.removeFromErrors("HBM");
      }
    },
    clearHemophiliaTypeBSeverityFemaleRow() {
      if (this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female) {
        this.tempData.patientsHemophiliaTypeB_Severity_Mild_Female = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Moderate_Female = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Severe_Female = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Unknown_Female = null;
        this.removeFromErrors("HBF");
      }
    },
    clearVWDSevereRow(numba: number) {
      if (numba == 1 && this.tempData.patientsVWD_Severe_Total_IsNotKnown) {
        this.tempData.patientsVWD_Severe_Total = null;
      }
      if (
        numba == 2 &&
        this.tempData
          .patientsVWD_Severe_ReceivingReplacementTherapy_Total_IsNotKnown
      ) {
        this.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total = null;
      }

      if (
        this.tempData.patientsVWD_Severe_Total_IsNotKnown &&
        this.tempData
          .patientsVWD_Severe_ReceivingReplacementTherapy_Total_IsNotKnown
      ) {
        this.tempData.severityVWDIsInformationAccurate = null;
      }
    },
  },
});
