
import Vue from "vue";

export default Vue.extend({
  name: "CareQuestion",
  data() {
    return {
      radioTxt: this.initRadioTxt as number | null,
      numberTxt: this.initNumberTxt as number | null,
      percentageTxt: this.initPercentageTxt as number | null,
      radioGrp: 0 as number,
      isRadioTxt: false,
      isValid: true,
    };
  },
  props: {
    //question label
    label: {
      type: String,
      required: true,
    },
    //1 or 2
    checkRadio: {
      type: Number,
      default: 2,
    },
    //radio btn labels
    radio1_label: {
      type: String,
      default: "datamanager.care.precise",
    },
    radio2_label: {
      type: String,
      default: "datamanager.care.estimate",
    },
    radio3_label: {
      type: String,
      default: "datamanager.care.notknown",
    },
    //
    initRadioGrp: {
      type: Object,
      required: true,
    },
    initRadioTxt: {
      type: Number,
    },
    initNumberTxt: {
      type: Number,
    },
    initPercentageTxt: {
      type: Number,
    },
    //
    has3rd_label: {
      type: Boolean,
      default: true,
    },
    //
    specialValidation: {
      type: String,
      default: "", //"" = none, "percentage" = validation < 100, "maxNumber" = validate < maxNumber
    },
    specialValidationMax: {
      type: Number,
      default: 0,
    },
    //
    isEditable: {
      type: Boolean,
      required: true,
    },
    hasCancelled: {
      type: Number,
      required: true,
    },
    isSpecialRadioInit: {
      type: Boolean,
      default: false,
    },
    isNumberAndPercentage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getRadioInitValue(): number {
      if (!this.isSpecialRadioInit) {
        if (this.initRadioGrp) {
          if (this.initRadioGrp.rad2 == null && !this.initRadioGrp.rad3) {
            return 0;
          }
          if (this.initRadioGrp.rad2 != null && this.initRadioGrp.rad3) {
            return 0;
          } else if (this.initRadioGrp.rad2 == true) {
            return 1;
          } else if (this.initRadioGrp.rad2 == false) {
            return 2;
          } else if (this.initRadioGrp.rad3) {
            return 3;
          } else return 0;
        } else return 0;
      } else {
        if (this.initRadioGrp) {
          if (
            (this.initRadioGrp.rad1 || this.initRadioGrp.rad1 == 0) &&
            !this.initRadioGrp.rad2 &&
            !this.initRadioGrp.rad3
          ) {
            return 1;
          } else if (this.initRadioGrp.rad2 && !this.initRadioGrp.rad3) {
            return 2;
          } else if (this.initRadioGrp.rad3 && !this.initRadioGrp.rad2) {
            return 3;
          } else return 0;
        } else return 0;
      }
    },
  },
  methods: {
    showTextField() {
      if (this.isSpecialRadioInit) {
        if (
          (!this.initRadioGrp.rad2 && !this.initRadioGrp.rad3) ||
          (this.initRadioGrp.rad2 && this.initRadioGrp.rad3)
        )
          return true;
      } else {
        if (
          !this.initRadioGrp.rad3 ||
          (this.initRadioGrp.rad2 != null && this.initRadioGrp.rad3)
        )
          return true;
      }
      return false;
    },
    grpRule() {
      if (this.radioGrp == 0 && this.isEditable) {
        return [this.$t("rules.plsselect")];
      } else return [];
    },
    specialVal() {
      if (
        this.specialValidation == "maxNumber" &&
        this.specialValidationMax == null
      ) {
        return [this.$t("rules.previousquestionempty")];
      }

      if (this.radioTxt != null && this.radioTxt >= 0) {
        if (this.specialValidation == "percentage" && this.radioTxt > 100) {
          return [this.$t("rules.percentnumber")];
        }
        if (
          this.specialValidation == "maxNumber" &&
          this.radioTxt > this.specialValidationMax
        ) {
          return [
            this.$t("rules.smallerorequalthan").toString() + " " + this.specialValidationMax,
          ];
        }

        if (this.radioTxt < 0) {
          return [this.$t("rules.positivenumber")];
        }

        return [];
      } else if (this.radioTxt != null) {
        //Marche pour champ vide et\ou texte
        return [this.$t("rules.numberspecified")];
      } else return [];
    },
    numberVal() {
      if (this.numberTxt != null && this.numberTxt >= 0) {
        // if (
        //   this.specialValidation == "maxNumber" &&
        //   this.numberTxt > this.specialValidationMax
        // ) {
        //   return [
        //     this.$t("rules.smallerorequalthan").toString() + " " + this.specialValidationMax,
        //   ];
        // }

        if (this.numberTxt < 0) {
          return [this.$t("rules.positivenumber")];
        }

        return [];
      } else if (this.numberTxt != null) {
        //Marche pour champ vide et\ou texte
        return [this.$t("rules.numberspecified")];
      } else return [];
    },
    percentageVal() {
      if (this.percentageTxt != null && this.percentageTxt >= 0) {
        if (this.percentageTxt > 100) {
          return [this.$t("rules.percentnumber")];
        }
        if (this.percentageTxt < 0) {
          return [this.$t("rules.positivenumber")];
        }
        return [];
      } else if (this.percentageTxt != null) {
        //Marche pour champ vide et\ou texte
        return [this.$t("rules.numberspecified")];
      } else return [];
    },
    radioTextCheck() {
      if (this.isNumberAndPercentage) {
        if (this.checkRadio == 1) {
          // si "Available"
          if (this.radioGrp == 1) {
            if (this.numberTxt == null && this.percentageTxt == null) {
              this.numberTxt = 0;
              this.percentageTxt = 0;
              let res = {
                radio: this.radioGrp,
                number: this.numberTxt,
                percentage: this.numberTxt,
              };
              this.$emit("res", res);
            }
          } else {
            //Si "Not Known" ou "Not Applicable"
            if (this.radioGrp != 0) {
              this.numberTxt = null;
              this.percentageTxt = null;
              let res = {
                radio: this.radioGrp,
                number: this.numberTxt,
                percentage: this.numberTxt,
              };
              this.$emit("res", res);
            }
          }
        } else if (this.checkRadio == 2) {
          //Si "Precise" ou "Estimate"
          if (this.radioGrp == 1 || this.radioGrp == 2) {
            if (this.numberTxt == null && this.percentageTxt == null)  {
              this.numberTxt = 0;
              this.percentageTxt = 0;
              let res = {
                radio: this.radioGrp,
                number: this.numberTxt,
                percentage: this.numberTxt,
              };
              this.$emit("res", res);
            }
          } else {
            //Si "Not Known"
            if (this.radioGrp != 0) {
              this.numberTxt = null;
              this.percentageTxt = null;
              let res = {
                radio: this.radioGrp,
                number: this.numberTxt,
                percentage: this.numberTxt,
              };
              this.$emit("res", res);
            }
          }
        }
      }
      else {
        if (this.checkRadio == 1) {
          // si "Available"
          if (this.radioGrp == 1) {
            if (this.radioTxt == null) {
              this.radioTxt = 0;
              let res = {
                radio: this.radioGrp,
                number: this.radioTxt,
              };
              this.$emit("res", res);
            }
          } else {
            //Si "Not Known" ou "Not Applicable"
            if (this.radioGrp != 0) {
              this.radioTxt = null;
              let res = {
                radio: this.radioGrp,
                number: this.radioTxt,
              };
              this.$emit("res", res);
            }
          }
        } else if (this.checkRadio == 2) {
          //Si "Precise" ou "Estimate"
          if (this.radioGrp == 1 || this.radioGrp == 2) {
            if (this.radioTxt == null) {
              this.radioTxt = 0;
              let res = {
                radio: this.radioGrp,
                number: this.radioTxt,
              };
              this.$emit("res", res);
            }
          } else {
            //Si "Not Known"
            if (this.radioGrp != 0) {
              this.radioTxt = null;
              let res = {
                radio: this.radioGrp,
                number: this.radioTxt,
              };
              this.$emit("res", res);
            }
          }
        }
      }
    },
    changeRadio() {
      if (this.isNumberAndPercentage) {
        if (((this.numberTxt || this.numberTxt == 0) || (this.percentageTxt || this.percentageTxt == 0))  && !this.radioGrp) {
          this.radioGrp = 1;
        }
        let res = {
          radio: this.radioGrp,
          number: this.numberTxt,
          percentage: this.percentageTxt
        };
        this.$emit("res", res);
      }
      else {
        if ((this.radioTxt || this.radioTxt == 0) && !this.radioGrp) {
          this.radioGrp = 1;
        }
        let res = {
          radio: this.radioGrp,
          number: this.radioTxt,
        };
        this.$emit("res", res);
      }
    },
  },
  created() {
    this.radioGrp = this.getRadioInitValue;
    this.radioTextCheck();
  },
  watch: {
    hasCancelled(res) {
      this.radioGrp = this.getRadioInitValue;
      this.radioTextCheck();
    },
  },
});
