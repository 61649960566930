
import Vue from 'vue'
import CareQuestion from '../UI-Components/CareQuestion.vue'
import FormEditFooter from '../UI-Components/FormEditFooter.vue'
import { SaveRes } from '@/models/saveres'
import { Snackbar } from '@/models/snackbar'
import { CareSection } from '@/models/caresection'
import i18n from '@/i18n'
import formrevalidate from '../../mixins/formrevalidate'

export default Vue.extend({
  name: 'Care',
  mixins: [formrevalidate],
  props: {
    isAllowedToEdit: {
      type: Boolean,
      default:false
    }
  },
  data: () => ({
    isEditable: false,
    radio: null,
    tempData: {} as CareSection,
    initData: {} as CareSection,
    shallSkipDialog: false,
    hasCancelled: 0,
    curKey: 'init',
    incrementedKey: 0,
    isFormValid: true,
  }),
  computed: {
    isPrinting(){
      return this.$store.getters["datamanager/isPrinting"];
    },
    isSurveyYearModifiable() {
      return this.$store.getters['datamanager/getIsSurveyYearModifiable']
    },
    sectionsStatuses() {
      return this.$store.getters['datamanager/getSectionsStatuses']
    },
    hasUnsavedChanges: {
      get() {
        return this.$store.getters['datamanager/getHasUnsavedChanges']
      },
      set(value: boolean) {
        return this.$store.dispatch(
          'datamanager/changeHasUnsavedChanges',
          value
        )
      },
    },
    careSection() {
      return this.$store.getters['datamanager/getCareSection']
    },
    careSectionSaveRes() {
      return this.$store.getters['datamanager/getCareSectionSaveRes']
    },
    countrySelected() {
      return this.$store.getters['datamanager/getCountrySelected']
    },
    yearSelected() {
      return this.$store.getters["datamanager/getCurrentSurvey"].year;
    },
  },
  methods: {
    async fetchSectionsStatuses() {
      await this.$store.dispatch('datamanager/fetchSectionsStatuses', {
        country: this.countrySelected,
      })
    },
    triggerSnack(snack: Snackbar) {
      this.$store.dispatch('general/changeSnackbar', snack)
    },
    resetValidation() {
      let refForm: any = this.$refs.form
      if (refForm != undefined) refForm.resetValidation()
    },
    updateQ14Htc(res: string | null, numb: number | null) {
      if (numb != null) {
        this.tempData.htC_Total = numb
        this.tempData.htC_IsNotApplicable = false
        this.tempData.htC_IsNotKnown = false
        if (numb == 0) {
          this.tempData.hccC_Total = null
          this.tempData.hccC_IsNotKnown = false
          this.tempData.hccC_IsNotApplicable = true
        }
      } else {
        if (res == '2') {
          this.tempData.htC_Total = null
          this.tempData.htC_IsNotApplicable = false
          this.tempData.htC_IsNotKnown = true
        } else if (res == '3') {
          this.tempData.htC_IsNotKnown = false
          this.tempData.htC_Total = null
          this.tempData.htC_IsNotApplicable = true
        }
      }
    },
    updateQ14Hccc(res: string | null, txt: number | null) {
      if (txt != null) {
        this.tempData.hccC_Total = txt
        this.tempData.hccC_IsNotKnown = false
        this.tempData.hccC_IsNotApplicable = false
      } else {
        if (res == '2') {
          this.tempData.hccC_Total = null
          this.tempData.hccC_IsNotKnown = true
          this.tempData.hccC_IsNotApplicable = false
        } else if (res == '3') {
          this.tempData.hccC_Total = null
          this.tempData.hccC_IsNotKnown = false
          this.tempData.hccC_IsNotApplicable = true
        }
      }
    },
    updateQ14HtcAccess(res: string | null, txt: number | null) {
      if (txt != null) {
        this.tempData.patientsHTCAccessPercentage = txt
        this.tempData.patientsHTCAccessPercentage_IsNotKnown = false
      } else {
        if (res == '2') {
          this.tempData.patientsHTCAccessPercentage = null
          this.tempData.patientsHTCAccessPercentage_IsNotKnown = true
        }
      }
    },
    updateQ15PercentOfChildOnPro(res: string | null, txt: number | null) {
      if (txt != null) {
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage = txt
      }
      if (res == '1') {
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsPrecise = true
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '2') {
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsPrecise = false
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '3') {
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsNotKnown = true
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsPrecise = null
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage = null
      }
    },
    updateQ15PercentOfChildOnPro2023(res: string | null, numberTxt: number | null, percentageTxt: number | null) {
      if (numberTxt != null) {
        this.tempData.severeHemophiliaChildrenOnProphylaxisNumber = numberTxt
      }
      if (percentageTxt != null) {
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage = percentageTxt
      }
      if (res == '1') {
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsPrecise = true
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '2') {
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsPrecise = false
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '3') {
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsNotKnown = true
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsPrecise = null
        this.tempData.severeHemophiliaChildrenOnProphylaxisPercentage = null
        this.tempData.severeHemophiliaChildrenOnProphylaxisNumber = null
      }
    },
    updateQ15PercentOfAdultOnPro(res: string | null, txt: number | null) {
      if (txt != null) {
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage = txt
      }
      if (res == '1') {
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsPrecise = true
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '2') {
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsPrecise = false
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '3') {
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsNotKnown = true
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsPrecise = null
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage = null
      }
    },
    updateQ15PercentOfAdultOnPro2023(res: string | null, numberTxt: number | null, percentageTxt: number | null) {
      if (numberTxt != null) {
        this.tempData.severeHemophiliaAdultsOnProphylaxisNumber = numberTxt
      }
      if (percentageTxt != null) {
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage = percentageTxt
      }
      if (res == '1') {
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsPrecise = true
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '2') {
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsPrecise = false
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '3') {
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsNotKnown = true
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsPrecise = null
        this.tempData.severeHemophiliaAdultsOnProphylaxisPercentage = null
        this.tempData.severeHemophiliaAdultsOnProphylaxisNumber = null
      }
    },
    updateQ15PercentOfVWDT3OnPro(res: string | null, txt: number | null) {
      if (txt != null) {
        this.tempData.vwdPatientsOnProphylaxisPercentage = txt
      }
      if (res == '1') {
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsPrecise = true
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '2') {
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsPrecise = false
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '3') {
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsNotKnown = true
        this.tempData.vwdPatientsOnProphylaxisPercentage = null
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsPrecise = null
      }
    },
    updateQ15PercentOfVWDT3OnPro2023(res: string | null, numberTxt: number | null, percentageTxt: number | null) {
      if (numberTxt != null) {
        this.tempData.vwdPatientsOnProphylaxisNumber = numberTxt
      }
      if (percentageTxt != null) {
        this.tempData.vwdPatientsOnProphylaxisPercentage = percentageTxt
      }
      if (res == '1') {
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsPrecise = true
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '2') {
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsPrecise = false
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsNotKnown = false
      } else if (res == '3') {
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsNotKnown = true
        this.tempData.vwdPatientsOnProphylaxisPercentage = null
        this.tempData.vwdPatientsOnProphylaxisPercentage_IsPrecise = null
        this.tempData.vwdPatientsOnProphylaxisNumber = null
      }
    },
    updateQ16NumPatientsGotITI(res: string | null, txt: number | null) {
      if (txt != null) {
        this.tempData.inhibitorsPatients_Total = txt
        if (txt == 0) {
          this.tempData.inhibitorsNewPatients_Total = 0
        }
      }
      if (res == '1') {
        this.tempData.inhibitorsPatients_IsPrecise = true
        this.tempData.inhibitorsPatients_IsNotKnown = false
      } else if (res == '2') {
        this.tempData.inhibitorsPatients_IsPrecise = false
        this.tempData.inhibitorsPatients_IsNotKnown = false
      } else if (res == '3') {
        this.tempData.inhibitorsPatients_IsNotKnown = true
        this.tempData.inhibitorsPatients_Total = null
        this.tempData.inhibitorsPatients_IsPrecise = null
      }
    },
    updateQ16NumPatientsStartedITI(res: string | null, txt: number | null) {
      if (txt != null) {
        this.tempData.inhibitorsNewPatients_Total = txt
      }
      if (res == '1') {
        this.tempData.inhibitorsNewPatients_IsPrecise = true
        this.tempData.inhibitorsNewPatients_IsNotKnown = false
      } else if (res == '2') {
        this.tempData.inhibitorsNewPatients_IsPrecise = false
        this.tempData.inhibitorsNewPatients_IsNotKnown = false
      } else if (res == '3') {
        this.tempData.inhibitorsNewPatients_IsNotKnown = true
        this.tempData.inhibitorsNewPatients_IsPrecise = null
        this.tempData.inhibitorsNewPatients_Total = null
      }
    },
    updateQ17NumPatientsVillaLastYear(res: string | null, txt: number | null) {
      if (txt != null) {
        this.tempData.inhibitorsFactorVIIAPatients_Total = txt
      }
      if (res == '1') {
        this.tempData.inhibitorsFactorVIIAPatients_IsPrecise = true
        this.tempData.inhibitorsFactorVIIAPatients_IsNotKnown = false
      } else if (res == '2') {
        this.tempData.inhibitorsFactorVIIAPatients_IsPrecise = false
        this.tempData.inhibitorsFactorVIIAPatients_IsNotKnown = false
      } else if (res == '3') {
        this.tempData.inhibitorsFactorVIIAPatients_IsNotKnown = true
        this.tempData.inhibitorsFactorVIIAPatients_Total = null
        this.tempData.inhibitorsFactorVIIAPatients_IsPrecise = null
      }
    },
    updateQ17NumPatientsFeibaLastYear(res: string | null, txt: number | null) {
      if (txt != null) {
        this.tempData.inhibitorsFEIBAPatients_Total = txt
      }
      if (res == '1') {
        this.tempData.inhibitorsFEIBAPatients_IsPrecise = true
        this.tempData.inhibitorsFEIBAPatients_IsNotKnown = false
      } else if (res == '2') {
        this.tempData.inhibitorsFEIBAPatients_IsPrecise = false
        this.tempData.inhibitorsFEIBAPatients_IsNotKnown = false
      } else if (res == '3') {
        this.tempData.inhibitorsFEIBAPatients_IsNotKnown = true
        this.tempData.inhibitorsFEIBAPatients_Total = null
        this.tempData.inhibitorsFEIBAPatients_IsPrecise = null
      }
    },
    saveit() {
      this.isEditable = false
      this.shallSkipDialog = false
      this.saveSurveyCareSection()
        .then((e) => {
          if (e.status == 200) {
            let snack = {
              isSnacking: true,
              snackColor: 'success',
              snackMessage: this.$t('basic.savesuccess').toString(),
              snackTimeout: '2000',
            }
            this.triggerSnack(snack)
            this.fetchSectionsStatuses()
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let snack = {
              isSnacking: true,
              snackColor: 'orange',
              snackMessage:
                this.$t('basic.savewarning').toString() +
                err.response.statusText,
              snackTimeout: '2000',
            }
            this.triggerSnack(snack)
          } else {
            let snack = {
              isSnacking: true,
              snackColor: 'error',
              snackMessage:
                this.$t('basic.saveerror').toString() + err.response.statusText,
              snackTimeout: '2000',
            }
            this.triggerSnack(snack)
          }
          this.isEditable = true
        })
    },
    willSave() {
      this.tempData.careSectionIsComplete = true
      this.saveit()
    },
    willSaveAsDraft() {
      this.tempData.careSectionIsComplete = false
      this.saveit()
    },
    reloadTempData() {
      this.incrementedKey += 1
      this.tempData = { ...this.initData }
      this.hasCancelled = this.hasCancelled + 1
      this.resetValidation()
    },
    async saveSurveyCareSection() {
      await this.$store.dispatch('datamanager/saveSurveyCareSection', {
        country: this.countrySelected,
        newData: this.tempData,
      })
      return this.careSectionSaveRes as SaveRes
    },
  },
  watch: {
    countrySelected: {
      handler(res) {
        this.curKey =
          i18n.locale == 'en'
            ? res.name_en
            : i18n.locale == 'fr'
            ? res.name_fr
            : i18n.locale == 'es'
            ? res.name_es
            : res.name_en
      },
      deep: true,
    },
    careSection(res) {
      this.initData = { ...res }
      if (res.careSectionIsComplete != null) {
        this.isEditable = false
      } else {
        if (!this.sectionsStatuses.isApproved && this.isAllowedToEdit && this.isSurveyYearModifiable) {
          this.isEditable = true
        }
        this.shallSkipDialog = true
      }
      this.reloadTempData()
    },
    isSurveyYearModifiable: {
      handler(res) {
        if (res == false) {
          this.isEditable = false
        }
      },
      deep: true,
    },
    tempData: {
      handler(res) {
        if (JSON.stringify(res) === JSON.stringify(this.initData)) {
          this.hasUnsavedChanges = false
        } else this.hasUnsavedChanges = true && this.isEditable
      },
      deep: true,
    },
  },
  components: {
    'care-question': CareQuestion,
    'form-edit-footer': FormEditFooter,
  },
})
