
import Vue from "vue";

export default Vue.extend({
  name: "SectionSeverityFrom2022",
  data() {
    return {
      totalNotSumMaleErrors: [] as string[],
      totalNotSumFemaleErrors: [] as string[],
      isTotalNotSumErrors: [] as string[],
    };
  },
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
    },
  },
  watch: {
    isEditable(res) {
      if (!res) {
        this.isTotalNotSumErrors = [];
      }
    },
  },
  computed: {
    resultGrey() {
      if (
        this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male &&
        this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female &&
        this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male &&
        this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
      ) {
        this.tempData.severityHemophiliaIsInformationAccurate = null;
        return true;
      }
      return false;
    },
    sumOfHAM(): number {
      return (
        (this.tempData.patientsHemophiliaTypeA_Severity_Mild_Male || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Moderate_Male || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Severe_Male || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Unknown_Male || 0)
      );
    },
    sumOfHAF(): number {
      return (
        //(this.tempData.patientsHemophiliaTypeA_Severity_Carrier_Female || 0) + // Carrier is no longer took into account
        (this.tempData.patientsHemophiliaTypeA_Severity_Mild_Female || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Moderate_Female || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Severe_Female || 0) +
        (this.tempData.patientsHemophiliaTypeA_Severity_Unknown_Female || 0)
      );
    },
    sumOfHBM(): number {
      return (
        (this.tempData.patientsHemophiliaTypeB_Severity_Mild_Male || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Moderate_Male || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Severe_Male || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Unknown_Male || 0)
      );
    },
    sumOfHBF(): number {
      return (
        //(this.tempData.patientsHemophiliaTypeB_Severity_Carrier_Female || 0) + // Carrier is no longer took into account
        (this.tempData.patientsHemophiliaTypeB_Severity_Mild_Female || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Moderate_Female || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Severe_Female || 0) +
        (this.tempData.patientsHemophiliaTypeB_Severity_Unknown_Female || 0)
      );
    },
    sumOfSevereVWDTotal(): number {
      return (
        (this.tempData.patientsVWD_Severe_Male || 0) +
        (this.tempData.patientsVWD_Severe_Female || 0)
      );
    },
    sumOfSevereVWDTherapyTotal(): number {
      return (
        (this.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Male ||
          0) +
        (this.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Female ||
          0)
      );
    },
  },
  methods: {
    removeFromMaleErrors(column: string) {
      let index = this.totalNotSumMaleErrors.indexOf(column);
      if (index > -1) {
        this.totalNotSumMaleErrors.splice(index, 1);
      }
    },
    removeFromFemaleErrors(column: string) {
      let index = this.totalNotSumFemaleErrors.indexOf(column);
      if (index > -1) {
        this.totalNotSumFemaleErrors.splice(index, 1);
      }
    },
    ruleTotalSum(column: string) {
      if (
        column == "HAM" &&
        !this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male &&
        this.tempData.patientsHemophiliaTypeA_Gender_Male != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeA_Gender_Male - this.sumOfHAM !=
          0
        ) {
          if (!this.totalNotSumMaleErrors.includes(column)) {
            this.totalNotSumMaleErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromMaleErrors(column);
          return [true];
        }
      }
      if (
        column == "HAF" &&
        !this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female &&
        this.tempData.patientsHemophiliaTypeA_Gender_Female != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeA_Gender_Female - this.sumOfHAF !=
          0
        ) {
          if (!this.totalNotSumFemaleErrors.includes(column)) {
            this.totalNotSumFemaleErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromFemaleErrors(column);
          return [true];
        }
      }
      if (
        column == "HBM" &&
        !this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male &&
        this.tempData.patientsHemophiliaTypeB_Gender_Male != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeB_Gender_Male - this.sumOfHBM !=
          0
        ) {
          if (!this.totalNotSumMaleErrors.includes(column)) {
            this.totalNotSumMaleErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromMaleErrors(column);
          return [true];
        }
      }
      if (
        column == "HBF" &&
        !this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female &&
        this.tempData.patientsHemophiliaTypeB_Gender_Female != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeB_Gender_Female - this.sumOfHBF !=
          0
        ) {
          if (!this.totalNotSumFemaleErrors.includes(column)) {
            this.totalNotSumFemaleErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromFemaleErrors(column);
          return [true];
        }
      }
    },
    ruleTotalIsSum(
      total: number,
      men: number,
      menNoData: boolean,
      women: number,
      womenNoData: boolean,
      unkwn: number,
      unkwnNoData: boolean,
      question: string,
      totalNodata: boolean
    ) {
      if (!totalNodata) {
        if (menNoData == true && womenNoData == true && unkwnNoData == true) {
          this.removeFromErrors(question);
          if (total != null && total >= 0 && typeof total == "number")
            return [];
          else return [this.$i18n.t("rules.required")];
        } else if (total != null && total >= 0 && typeof total == "number") {
          if (total - men - women - unkwn != 0) {
            if (!this.isTotalNotSumErrors.includes(question)) {
              this.isTotalNotSumErrors.push(question);
            }
            return [false];
          } else {
            this.removeFromErrors(question);
            return [];
          }
        } else {
          this.removeFromErrors(question);
          return [this.$i18n.t("rules.required")];
        }
      } else {
        this.removeFromErrors(question);
        return [];
      }
    },
    removeFromErrors(question: string) {
      let index = this.isTotalNotSumErrors.indexOf(question);
      if (index > -1) {
        this.isTotalNotSumErrors.splice(index, 1);
      }
    },
    clearHemophiliaTypeASeverityMaleRow() {
      if (this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male) {
        this.tempData.patientsHemophiliaTypeA_Severity_Mild_Male = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Moderate_Male = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Severe_Male = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Unknown_Male = null;
        this.removeFromMaleErrors("HAM");
      }
    },
    clearHemophiliaTypeASeverityFemaleRow() {
      if (this.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female) {
        this.tempData.patientsHemophiliaTypeA_Severity_Carrier_Female = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Mild_Female = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Moderate_Female = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Severe_Female = null;
        this.tempData.patientsHemophiliaTypeA_Severity_Unknown_Female = null;
        this.removeFromFemaleErrors("HAF");
      }
    },
    clearHemophiliaTypeBSeverityMaleRow() {
      if (this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male) {
        this.tempData.patientsHemophiliaTypeB_Severity_Mild_Male = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Moderate_Male = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Severe_Male = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Unknown_Male = null;
        this.removeFromMaleErrors("HBM");
      }
    },
    clearHemophiliaTypeBSeverityFemaleRow() {
      if (this.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female) {
        this.tempData.patientsHemophiliaTypeB_Severity_Carrier_Female = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Mild_Female = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Moderate_Female = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Severe_Female = null;
        this.tempData.patientsHemophiliaTypeB_Severity_Unknown_Female = null;
        this.removeFromFemaleErrors("HBF");
      }
    },
    clearVWDSevereTotalRow(numba: number) {
      if (numba == 1 && this.tempData.patientsVWD_Severe_Total_IsNotKnown) {
        this.tempData.patientsVWD_Severe_Total = null;
      }
      if (
        numba == 2 &&
        this.tempData
          .patientsVWD_Severe_ReceivingReplacementTherapy_Total_IsNotKnown
      ) {
        this.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total = null;
      }
    },
    clearVWDSevereMaleRow(numba: number) {
      if (numba == 1 && this.tempData.patientsVWD_Severe_IsNotKnown_Male) {
        this.tempData.patientsVWD_Severe_Male = null;
      }
      if (
        numba == 2 &&
        this.tempData
          .patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Male
      ) {
        this.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Male = null;
      }
    },
    clearVWDSevereFemaleRow(numba: number) {
      if (numba == 1 && this.tempData.patientsVWD_Severe_IsNotKnown_Female) {
        this.tempData.patientsVWD_Severe_Female = null;
      }
      if (
        numba == 2 &&
        this.tempData
          .patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Female
      ) {
        this.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Female = null;
      }
    },
    clearVWDSevereUnknownRow(numba: number) {
      if (numba == 1 && this.tempData.patientsVWD_Severe_IsNotKnown_Unknown) {
        this.tempData.patientsVWD_Severe_Unknown = null;
      }
      if (
        numba == 2 &&
        this.tempData
          .patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Unknown
      ) {
        this.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Unknown = null;
      }
    },
  },
});
