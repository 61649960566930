
import Vue from "vue";

export default Vue.extend({
  name: "SectionAge",
  data() {
    return {
      totalNotSumErrors: [] as string[],
    };
  },
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
    },
  },
  computed: {
    resaltGrey(){
      if (
          this.tempData.patientsVWD_Age_HasNoData
          && this.tempData.patientsHemophiliaTypeUnknown_Age_HasNoData
          && this.tempData.patientsHemophiliaTypeB_Age_HasNoData
          && this.tempData.patientsHemophiliaTypeA_Age_HasNoData
      ){
        this.tempData.ageIsInformationAccurate = null
        return true
      }
      return false
    },
    sumOfQ1HA(): number {
      let x = this.tempData.patientsHemophiliaTypeA_Age_0To4 || 0;
      let y = this.tempData.patientsHemophiliaTypeA_Age_5To13 || 0;
      let z = this.tempData.patientsHemophiliaTypeA_Age_14To18 || 0;
      let a = this.tempData.patientsHemophiliaTypeA_Age_19To44 || 0;
      let b = this.tempData.patientsHemophiliaTypeA_Age_45OrOlder || 0;
      let c = this.tempData.patientsHemophiliaTypeA_Age_Unknown || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
    sumOfQ1HB(): number {
      let x = this.tempData.patientsHemophiliaTypeB_Age_0To4 || 0;
      let y = this.tempData.patientsHemophiliaTypeB_Age_5To13 || 0;
      let z = this.tempData.patientsHemophiliaTypeB_Age_14To18 || 0;
      let a = this.tempData.patientsHemophiliaTypeB_Age_19To44 || 0;
      let b = this.tempData.patientsHemophiliaTypeB_Age_45OrOlder || 0;
      let c = this.tempData.patientsHemophiliaTypeB_Age_Unknown || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
    sumOfQ1HC(): number {
      let x = this.tempData.patientsHemophiliaTypeUnknown_Age_0To4 || 0;
      let y = this.tempData.patientsHemophiliaTypeUnknown_Age_5To13 || 0;
      let z = this.tempData.patientsHemophiliaTypeUnknown_Age_14To18 || 0;
      let a = this.tempData.patientsHemophiliaTypeUnknown_Age_19To44 || 0;
      let b = this.tempData.patientsHemophiliaTypeUnknown_Age_45OrOlder || 0;
      let c = this.tempData.patientsHemophiliaTypeUnknown_Age_Unknown || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
    sumOfQ2(): number {
      let x = this.tempData.patientsVWD_Age_0To4 || 0;
      let y = this.tempData.patientsVWD_Age_5To13 || 0;
      let z = this.tempData.patientsVWD_Age_14To18 || 0;
      let a = this.tempData.patientsVWD_Age_19To44 || 0;
      let b = this.tempData.patientsVWD_Age_45OrOlder || 0;
      let c = this.tempData.patientsVWD_Age_Unknown || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
  },
  methods: {
    ruleTotalSum(column: string) {
      //Column = HemophiliaA (Q1A)
      if (
        column == "Q1A" &&
        !this.tempData.patientsHemophiliaTypeA_Age_HasNoData &&
        !this.tempData.patientsHemophiliaTypeA_Gender_HasNoData &&
        this.tempData.patientsHemophiliaTypeA_Gender_Total != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeA_Gender_Total - this.sumOfQ1HA !=
          0
        ) {
          if (!this.totalNotSumErrors.includes(column)) {
            this.totalNotSumErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromErrors("Q1A");
          return [true];
        }
      }
      //Column = HemophiliaB (Q1B)
      if (
        column == "Q1B" &&
        !this.tempData.patientsHemophiliaTypeB_Age_HasNoData &&
        !this.tempData.patientsHemophiliaTypeB_Gender_HasNoData &&
        this.tempData.patientsHemophiliaTypeB_Gender_Total != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeB_Gender_Total - this.sumOfQ1HB !=
          0
        ) {
          if (!this.totalNotSumErrors.includes(column)) {
            this.totalNotSumErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromErrors("Q1B");
          return [true];
        }
      }
      //Column = HemophiliaUnknown (Q1C)
      if (
        column == "Q1C" &&
        !this.tempData.patientsHemophiliaTypeUnknown_Age_HasNoData &&
        !this.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData &&
        this.tempData.patientsHemophiliaTypeUnknown_Gender_Total != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeUnknown_Gender_Total -
            this.sumOfQ1HC !=
          0
        ) {
          if (!this.totalNotSumErrors.includes(column)) {
            this.totalNotSumErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromErrors("Q1C");
          return [true];
        }
      }
      //Column = VWD (Q2)
      if (
        column == "Q2" &&
        !this.tempData.patientsVWD_Age_HasNoData &&
        !this.tempData.patientsVWD_Gender_HasNoData &&
        this.tempData.patientsVWD_Gender_Total != null
      ) {
        if (this.tempData.patientsVWD_Gender_Total - this.sumOfQ2 != 0) {
          if (!this.totalNotSumErrors.includes(column)) {
            this.totalNotSumErrors.push(column);
          }
          return [false];
        } else {
          this.removeFromErrors("Q2");
          return [true];
        }
      }
    },
    removeFromErrors(column: string) {
      let index = this.totalNotSumErrors.indexOf(column);
      if (index > -1) {
        this.totalNotSumErrors.splice(index, 1);
      }
    },
    clearHemophiliaTypeAAgeRow() {
      if (this.tempData.patientsHemophiliaTypeA_Age_HasNoData) {
        this.tempData.patientsHemophiliaTypeA_Age_0To4 = null;
        this.tempData.patientsHemophiliaTypeA_Age_5To13 = null;
        this.tempData.patientsHemophiliaTypeA_Age_14To18 = null;
        this.tempData.patientsHemophiliaTypeA_Age_19To44 = null;
        this.tempData.patientsHemophiliaTypeA_Age_45OrOlder = null;
        this.tempData.patientsHemophiliaTypeA_Age_Unknown = null;
        this.removeFromErrors("Q1A");
      }
    },
    clearHemophiliaTypeBAgeRow() {
      if (this.tempData.patientsHemophiliaTypeB_Age_HasNoData) {
        this.tempData.patientsHemophiliaTypeB_Age_0To4 = null;
        this.tempData.patientsHemophiliaTypeB_Age_5To13 = null;
        this.tempData.patientsHemophiliaTypeB_Age_14To18 = null;
        this.tempData.patientsHemophiliaTypeB_Age_19To44 = null;
        this.tempData.patientsHemophiliaTypeB_Age_45OrOlder = null;
        this.tempData.patientsHemophiliaTypeB_Age_Unknown = null;
        this.removeFromErrors("Q1B");
      }
    },
    clearHemophiliaTypeUnknownAgeRow() {
      if (this.tempData.patientsHemophiliaTypeUnknown_Age_HasNoData) {
        this.tempData.patientsHemophiliaTypeUnknown_Age_0To4 = null;
        this.tempData.patientsHemophiliaTypeUnknown_Age_5To13 = null;
        this.tempData.patientsHemophiliaTypeUnknown_Age_14To18 = null;
        this.tempData.patientsHemophiliaTypeUnknown_Age_19To44 = null;
        this.tempData.patientsHemophiliaTypeUnknown_Age_45OrOlder = null;
        this.tempData.patientsHemophiliaTypeUnknown_Age_Unknown = null;
        this.removeFromErrors("Q1C");
      }
    },
    clearVWDAgeRow() {
      if (this.tempData.patientsVWD_Age_HasNoData) {
        this.tempData.patientsVWD_Age_0To4 = null;
        this.tempData.patientsVWD_Age_5To13 = null;
        this.tempData.patientsVWD_Age_14To18 = null;
        this.tempData.patientsVWD_Age_19To44 = null;
        this.tempData.patientsVWD_Age_45OrOlder = null;
        this.tempData.patientsVWD_Age_Unknown = null;
        this.removeFromErrors("Q2");
      }
    },
  },
  watch: {
    isEditable(res) {
      if (!res) {
        this.totalNotSumErrors = [];
      }
    },
  },
});
