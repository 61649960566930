
import i18n from "@/i18n";
import Vue from "vue";

export default Vue.extend({
  name: "SectionProductFrom2022",
  data() {
    return {
      isTotalNotSumErrors: [] as string[],
    };
  },
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
    },
  },
  watch: {
    isEditable(res) {
      if (!res) {
        this.isTotalNotSumErrors = [];
      }
    },
  },
  computed: {
    isPrinting() {
      return this.$store.getters["datamanager/isPrinting"];
    },
  },
  methods: {
    ruleTotalIsSum(
      total: number,
      men: number,
      women: number,
      unkwn: number,
      question: string,
      nodata: boolean
    ) {
      if (!nodata) {
        if (total != null && total >= 0 && typeof total == "number") {
          if (total - men - women - unkwn != 0) {
            if (!this.isTotalNotSumErrors.includes(question)) {
              this.isTotalNotSumErrors.push(question);
            }
            return [false];
          } else {
            this.removeFromErrors(question);
            return [];
          }
        } else {
          this.removeFromErrors(question);
          return [this.$i18n.t("rules.required")];
        }
      } else {
        this.removeFromErrors(question);
        return [];
      }
    },
    removeFromErrors(question: string) {
      let index = this.isTotalNotSumErrors.indexOf(question);
      if (index > -1) {
        this.isTotalNotSumErrors.splice(index, 1);
      }
    },
    mustBeChecked(checkValue: boolean, value: number, noDataValue: boolean) {
      if (noDataValue == null) return [];

      if (this.isEditable) {
        //if (!noDataValue && value != null && !checkValue)
        if (value != null && value != 0 && !checkValue) return [i18n.t("rules.required")];
        else return [];
      }
    },
    clearHemophiliaProductPlasmaRow() {
      if (this.tempData.patientsHemophiliaProduct_Plasma_HasNoData) {
        this.tempData.patientsHemophiliaProduct_Plasma_TreatedTotal = null;
      }
    },
    clearHemophiliaProductCryoprecipitateRow() {
      if (this.tempData.patientsHemophiliaProduct_Cryoprecipitate_HasNoData) {
        this.tempData.patientsHemophiliaProduct_Cryoprecipitate_TreatedTotal = null;
      }
    },
    clearHemophiliaProductPlasmaDerivedConcentrateRow() {
      if (
        this.tempData
          .patientsHemophiliaProduct_PlasmaDerivedConcentrate_HasNoData
      ) {
        this.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_TreatedTotal = null;
      }
    },
    clearHemophiliaProductRecombinantConcentrateRow() {
      if (
        this.tempData.patientsHemophiliaProduct_RecombinantConcentrate_HasNoData
      ) {
        this.tempData.patientsHemophiliaProduct_RecombinantConcentrate_TreatedTotal = null;
      }
    },
    clearHemophiliaProductRecombinantExtendedHalfLifeConcentrateRow() {
      if (
        this.tempData
          .patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_HasNoData
      ) {
        this.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_TreatedTotal = null;
      }
    },
    clearHemophiliaProductDDAVPRow() {
      if (this.tempData.patientsHemophiliaProduct_DDAVP_HasNoData) {
        this.tempData.patientsHemophiliaProduct_DDAVP_TreatedTotal = null;
      }
    },
    clearHemophiliaWithInhibitorsProductEmicizumabRow() {
      if (
        this.tempData
          .patientsHemophiliaWithInhibitorsProduct_Emicizumab_HasNoData
      ) {
        this.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_TreatedTotal = null;
      }
    },
    clearHemophiliaWithoutInhibitorsProductEmicizumabRow() {
      if (
        this.tempData
          .patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_HasNoData
      ) {
        this.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_TreatedTotal = null;
      }
    },
    clearVWDProductPlasmaRow() {
      if (this.tempData.patientsVWDProduct_Plasma_HasNoData) {
        this.tempData.patientsVWDProduct_Plasma_TreatedTotal = null;
        this.tempData.patientsVWDProduct_Plasma_TreatedMale = null;
        this.tempData.patientsVWDProduct_Plasma_TreatedFemale = null;
        this.tempData.patientsVWDProduct_Plasma_TreatedUnknown = null;
      }
    },
    clearVWDProductCryoprecipitateRow() {
      if (this.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData) {
        this.tempData.patientsVWDProduct_Cryoprecipitate_TreatedTotal = null;
        this.tempData.patientsVWDProduct_Cryoprecipitate_TreatedMale = null;
        this.tempData.patientsVWDProduct_Cryoprecipitate_TreatedFemale = null;
        this.tempData.patientsVWDProduct_Cryoprecipitate_TreatedUnknown = null;
      }
    },
    clearVWDProductPlasmaDerivedConcentrateRow() {
      if (this.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData) {
        this.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedTotal = null;
        this.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedMale = null;
        this.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedFemale = null;
        this.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedUnknown = null;
      }
    },
    clearVWDProductDDAVPRow() {
      if (this.tempData.patientsVWDProduct_DDAVP_HasNoData) {
        this.tempData.patientsVWDProduct_DDAVP_TreatedTotal = null;
        this.tempData.patientsVWDProduct_DDAVP_TreatedMale = null;
        this.tempData.patientsVWDProduct_DDAVP_TreatedFemale = null;
        this.tempData.patientsVWDProduct_DDAVP_TreatedUnknown = null;
      }
    },
    clearVWDProductDDAVPNasalRow() {
      if (this.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData) {
        this.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedTotal = null;
        this.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedMale = null;
        this.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedFemale = null;
        this.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedUnknown = null;
      }
    },
    clearVWDProductDDAVPIVOrSubcutaneousRow() {
      if (this.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData) {
        this.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedTotal = null;
        this.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedMale = null;
        this.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedFemale = null;
        this.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedUnknown = null;
      }
    },
    clearVWDProductRecombinantConcentrateRow() {
      if (this.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData) {
        this.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedTotal = null;
        this.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedMale = null;
        this.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedFemale = null;
        this.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedUnknown = null;
      }
    },
    clearVWDProductTranexamicAcidRow() {
      if (this.tempData.patientsVWDProduct_TranexamicAcid_HasNoData) {
        this.tempData.patientsVWDProduct_TranexamicAcid_TreatedTotal = null;
        this.tempData.patientsVWDProduct_TranexamicAcid_TreatedMale = null;
        this.tempData.patientsVWDProduct_TranexamicAcid_TreatedFemale = null;
        this.tempData.patientsVWDProduct_TranexamicAcid_TreatedUnknown = null;
      }
    },
    clearVWDProductHormonalTherapyRow() {
      if (this.tempData.patientsVWDProduct_HormonalTherapy_HasNoData) {
        this.tempData.patientsVWDProduct_HormonalTherapy_TreatedTotal = null;
        this.tempData.patientsVWDProduct_HormonalTherapy_TreatedMale = null;
        this.tempData.patientsVWDProduct_HormonalTherapy_TreatedFemale = null;
        this.tempData.patientsVWDProduct_HormonalTherapy_TreatedUnknown = null;
      }
    },
  },
});
