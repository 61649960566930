
import Vue from "vue";

export default Vue.extend({
  name: "SectionComment",
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isPrinting():boolean{
      return this.$store.getters["datamanager/isPrinting"];
    }
  }
});
