var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"care"},[_c('h3',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t('datamanager.care.label')))]),_c('v-divider',{staticClass:"pt-4 mt-2"}),_c('h3',[_vm._v(_vm._s(_vm.$t('datamanager.care.sectionc')))]),_c('p',[_vm._v(_vm._s(_vm.$t('datamanager.care.htcdefinition')))]),_c('p',[_vm._v(_vm._s(_vm.$t('datamanager.care.intro')))]),_c('v-divider'),_c('v-form',{key:_vm.countrySelected.name_en + _vm.curKey + ' ' + _vm.tempData.key,ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-simple-table',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{staticClass:"row-height"},[_c('td',[_c('h4',[_vm._v(_vm._s(_vm.$t('basic.country')))])]),_c('td',{attrs:{"colspan":"3"}},[_c('h4',[_vm._v(_vm._s(_vm.countrySelected.name_en))])])]),_c('care-question',{key:'q14a-' + _vm.incrementedKey,attrs:{"label":_vm.$t('datamanager.care.q14_howmanyhtc'),"checkRadio":1,"radio1_label":'datamanager.care.available',"radio2_label":'datamanager.care.notknown',"radio3_label":'datamanager.care.notapplicable',"isEditable":_vm.isEditable,"initRadioTxt":_vm.tempData.htC_Total == '' ? null : _vm.tempData.htC_Total,"isSpecialRadioInit":true,"initRadioGrp":{
              rad1: _vm.tempData.htC_Total,
              rad2: _vm.tempData.htC_IsNotKnown,
              rad3: _vm.tempData.htC_IsNotApplicable,
            },"hasCancelled":_vm.hasCancelled},on:{"res":function($event){return _vm.updateQ14Htc($event.radio, $event.number)}}}),_c('care-question',{key:'q14b-' + _vm.incrementedKey + _vm.tempData.htC_Total,attrs:{"label":_vm.$t('datamanager.care.q14_howmanyhtcarehccc'),"checkRadio":1,"radio1_label":'datamanager.care.available',"radio2_label":'datamanager.care.notknown',"radio3_label":'datamanager.care.notapplicable',"isSpecialRadioInit":true,"specialValidation":'maxNumber',"specialValidationMax":_vm.tempData.htC_Total != '' ? _vm.tempData.htC_Total : 0,"isEditable":_vm.isEditable,"initRadioTxt":_vm.tempData.hccC_Total == '' ? null : _vm.tempData.hccC_Total,"initRadioGrp":{
              rad1: _vm.tempData.hccC_Total,
              rad2: _vm.tempData.hccC_IsNotKnown,
              rad3: _vm.tempData.hccC_IsNotApplicable,
            },"hasCancelled":_vm.hasCancelled},on:{"res":function($event){return _vm.updateQ14Hccc($event.radio, $event.number)}}}),_c('care-question',{key:'q14c-' + _vm.incrementedKey,attrs:{"label":_vm.$t('datamanager.care.q14_percentofpatientaccess'),"checkRadio":1,"has3rd_label":false,"radio1_label":'datamanager.care.available',"radio2_label":'datamanager.care.notknown',"radio3_label":'datamanager.care.notapplicable',"specialValidation":'percentage',"isEditable":_vm.isEditable,"isSpecialRadioInit":true,"initRadioTxt":_vm.tempData.patientsHTCAccessPercentage == ''
                ? null
                : _vm.tempData.patientsHTCAccessPercentage,"initRadioGrp":{
              rad1: _vm.tempData.patientsHTCAccessPercentage,
              rad2: _vm.tempData.patientsHTCAccessPercentage_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled},on:{"res":function($event){return _vm.updateQ14HtcAccess($event.radio, $event.number)}}}),_c('tr',[(_vm.yearSelected < 2023)?_c('td',{staticClass:"pt-7",attrs:{"colspan":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t('datamanager.care.q15_label')))])]):_c('td',{staticClass:"pt-7",attrs:{"colspan":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t('datamanager.care.q15_label2023')))])])]),(_vm.yearSelected >= 2023)?_c('tr',[_c('td'),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.care.q15_number")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.care.q15_percentage")))])]),_c('td')]):_vm._e(),(_vm.yearSelected < 2023)?_c('care-question',{key:'q15a-' + _vm.incrementedKey,attrs:{"label":_vm.$t('datamanager.care.q15_percentofchildonpro'),"specialValidation":'percentage',"isEditable":_vm.isEditable,"initRadioTxt":_vm.tempData.severeHemophiliaChildrenOnProphylaxisPercentage == ''
                ? null
                : _vm.tempData.severeHemophiliaChildrenOnProphylaxisPercentage,"initRadioGrp":{
              rad1: _vm.tempData.severeHemophiliaChildrenOnProphylaxisPercentage,
              rad2:
                _vm.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsPrecise,
              rad3:
                _vm.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled},on:{"res":function($event){return _vm.updateQ15PercentOfChildOnPro($event.radio, $event.number)}}}):_c('care-question',{key:'q15a-' + _vm.incrementedKey,attrs:{"label":_vm.$t('datamanager.care.q15_percentofchildonpro2023'),"isEditable":_vm.isEditable,"initNumberTxt":_vm.tempData.severeHemophiliaChildrenOnProphylaxisNumber == ''
                ? null
                : _vm.tempData.severeHemophiliaChildrenOnProphylaxisNumber,"initPercentageTxt":_vm.tempData.severeHemophiliaChildrenOnProphylaxisPercentage == ''
                ? null
                : _vm.tempData.severeHemophiliaChildrenOnProphylaxisPercentage,"initRadioGrp":{
              rad1: _vm.tempData.severeHemophiliaChildrenOnProphylaxisPercentage,
              rad2:
                _vm.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsPrecise,
              rad3:
                _vm.tempData.severeHemophiliaChildrenOnProphylaxisPercentage_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled,"isNumberAndPercentage":true},on:{"res":function($event){return _vm.updateQ15PercentOfChildOnPro2023($event.radio, $event.number, $event.percentage)}}}),(_vm.yearSelected < 2023)?_c('care-question',{key:'q15b-' + _vm.incrementedKey,staticClass:"html2pdf__page-break",attrs:{"label":_vm.$t('datamanager.care.q15_percentofadultonpro'),"specialValidation":'percentage',"isEditable":_vm.isEditable,"initRadioTxt":_vm.tempData.severeHemophiliaAdultsOnProphylaxisPercentage == ''
                ? null
                : _vm.tempData.severeHemophiliaAdultsOnProphylaxisPercentage,"initRadioGrp":{
              rad1: _vm.tempData.severeHemophiliaAdultsOnProphylaxisPercentage,
              rad2:
                _vm.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsPrecise,
              rad3:
                _vm.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled},on:{"res":function($event){return _vm.updateQ15PercentOfAdultOnPro($event.radio, $event.number)}}}):_c('care-question',{key:'q15b-' + _vm.incrementedKey,staticClass:"html2pdf__page-break",attrs:{"label":_vm.$t('datamanager.care.q15_percentofadultonpro2023'),"isEditable":_vm.isEditable,"initNumberTxt":_vm.tempData.severeHemophiliaAdultsOnProphylaxisNumber == ''
                ? null
                : _vm.tempData.severeHemophiliaAdultsOnProphylaxisNumber,"initPercentageTxt":_vm.tempData.severeHemophiliaAdultsOnProphylaxisPercentage == ''
                ? null
                : _vm.tempData.severeHemophiliaAdultsOnProphylaxisPercentage,"initRadioGrp":{
              rad1: _vm.tempData.severeHemophiliaAdultsOnProphylaxisPercentage,
              rad2:
                _vm.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsPrecise,
              rad3:
                _vm.tempData.severeHemophiliaAdultsOnProphylaxisPercentage_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled,"isNumberAndPercentage":true},on:{"res":function($event){return _vm.updateQ15PercentOfAdultOnPro2023($event.radio, $event.number, $event.percentage)}}}),(_vm.yearSelected < 2023)?_c('care-question',{key:'q15c-' + _vm.incrementedKey,attrs:{"label":_vm.$t('datamanager.care.q15_percentofvwdt3onpro'),"specialValidation":'percentage',"isEditable":_vm.isEditable,"initRadioTxt":_vm.tempData.vwdPatientsOnProphylaxisPercentage == ''
                ? null
                : _vm.tempData.vwdPatientsOnProphylaxisPercentage,"initRadioGrp":{
              rad1: _vm.tempData.vwdPatientsOnProphylaxisPercentage,
              rad2: _vm.tempData.vwdPatientsOnProphylaxisPercentage_IsPrecise,
              rad3: _vm.tempData.vwdPatientsOnProphylaxisPercentage_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled},on:{"res":function($event){return _vm.updateQ15PercentOfVWDT3OnPro($event.radio, $event.number)}}}):_c('care-question',{key:'q15c-' + _vm.incrementedKey,attrs:{"label":_vm.$t('datamanager.care.q15_percentofvwdt3onpro2023'),"isEditable":_vm.isEditable,"initNumberTxt":_vm.tempData.vwdPatientsOnProphylaxisNumber == ''
                ? null
                : _vm.tempData.vwdPatientsOnProphylaxisNumber,"initPercentageTxt":_vm.tempData.vwdPatientsOnProphylaxisPercentage == ''
                ? null
                : _vm.tempData.vwdPatientsOnProphylaxisPercentage,"initRadioGrp":{
              rad1: _vm.tempData.vwdPatientsOnProphylaxisPercentage,
              rad2:
                _vm.tempData.vwdPatientsOnProphylaxisPercentage_IsPrecise,
              rad3:
                _vm.tempData.vwdPatientsOnProphylaxisPercentage_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled,"isNumberAndPercentage":true},on:{"res":function($event){return _vm.updateQ15PercentOfVWDT3OnPro2023($event.radio, $event.number, $event.percentage)}}}),_c('tr',[_c('td',{staticClass:"pt-7",attrs:{"colspan":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t('datamanager.care.q16_label')))])])]),_c('care-question',{key:'q16a-' + _vm.incrementedKey,attrs:{"label":_vm.$t('datamanager.care.q16_numpatientsgotiti'),"isEditable":_vm.isEditable,"initRadioTxt":_vm.tempData.inhibitorsPatients_Total == ''
                ? null
                : _vm.tempData.inhibitorsPatients_Total,"initRadioGrp":{
              rad1: _vm.tempData.inhibitorsPatients_Total,
              rad2: _vm.tempData.inhibitorsPatients_IsPrecise,
              rad3: _vm.tempData.inhibitorsPatients_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled},on:{"res":function($event){return _vm.updateQ16NumPatientsGotITI($event.radio, $event.number)}}}),_c('care-question',{key:'q16b-' +
                _vm.incrementedKey /* + tempData.inhibitorsPatients_Total*/,attrs:{"label":_vm.$t('datamanager.care.q16_howmanystartedlastyear'),"specialValidation":'maxNumber',"specialValidationMax":_vm.tempData.inhibitorsPatients_Total != ''
                ? _vm.tempData.inhibitorsPatients_Total
                : 0,"isEditable":_vm.isEditable /* && tempData.inhibitorsPatients_Total > 0*/,"initRadioTxt":_vm.tempData.inhibitorsNewPatients_Total == ''
                ? null
                : _vm.tempData.inhibitorsNewPatients_Total,"initRadioGrp":{
              rad1: _vm.tempData.inhibitorsNewPatients_Total,
              rad2: _vm.tempData.inhibitorsNewPatients_IsPrecise,
              rad3: _vm.tempData.inhibitorsNewPatients_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled},on:{"res":function($event){return _vm.updateQ16NumPatientsStartedITI($event.radio, $event.number)}}}),_c('tr',[_c('td',{staticClass:"pt-7",attrs:{"colspan":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t('datamanager.care.q17_label')))])])]),_c('care-question',{key:'q17a-' + _vm.incrementedKey,attrs:{"label":_vm.$t('datamanager.care.q17_numpatientsvillalastyear'),"isEditable":_vm.isEditable,"initRadioTxt":_vm.tempData.inhibitorsFactorVIIAPatients_Total == ''
                ? null
                : _vm.tempData.inhibitorsFactorVIIAPatients_Total,"initRadioGrp":{
              rad1: _vm.tempData.inhibitorsFactorVIIAPatients_Total,
              rad2: _vm.tempData.inhibitorsFactorVIIAPatients_IsPrecise,
              rad3: _vm.tempData.inhibitorsFactorVIIAPatients_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled},on:{"res":function($event){return _vm.updateQ17NumPatientsVillaLastYear($event.radio, $event.number)}}}),_c('care-question',{key:'q17b-' + _vm.incrementedKey,attrs:{"label":_vm.$t('datamanager.care.q17_numpatientsfeibalastyear'),"isEditable":_vm.isEditable,"initRadioTxt":_vm.tempData.inhibitorsFEIBAPatients_Total,"initRadioGrp":{
              rad1: _vm.tempData.inhibitorsFEIBAPatients_Total,
              rad2: _vm.tempData.inhibitorsFEIBAPatients_IsPrecise,
              rad3: _vm.tempData.inhibitorsFEIBAPatients_IsNotKnown,
            },"hasCancelled":_vm.hasCancelled},on:{"res":function($event){return _vm.updateQ17NumPatientsFeibaLastYear($event.radio, $event.number)}}}),_c('tr',{staticClass:"row-height"},[_c('td',[_vm._v(" "+_vm._s(_vm.$t('basic.comment'))+" ")]),_c('td',{attrs:{"colspan":"3"}},[(_vm.isPrinting)?_c('span',[_vm._v(_vm._s(_vm.tempData.careComment))]):_c('v-textarea',{attrs:{"hide-details":"auto","outlined":_vm.isEditable,"disabled":!_vm.isEditable,"name":"comment","rows":"2","auto-grow":""},model:{value:(_vm.tempData.careComment),callback:function ($$v) {_vm.$set(_vm.tempData, "careComment", $$v)},expression:"tempData.careComment"}})],1)]),_c('tr',[_c('br')])],1)]},proxy:true}])})],1),(!_vm.isPrinting)?_c('form-edit-footer',{attrs:{"isEditable":_vm.isEditable,"currentForm":this.$refs.form,"save":_vm.willSave,"saveAsDraft":_vm.willSaveAsDraft,"isSkippingDialog":_vm.shallSkipDialog,"isAllowedToEdit":_vm.isAllowedToEdit},on:{"isEditable":function($event){_vm.isEditable = $event},"hasCanceled":function($event){return _vm.reloadTempData()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }