
import Vue from "vue";
import { CommentSection } from "@/models/commentsection";
import i18n from "@/i18n";
import moment from "moment";

export default Vue.extend({
  name: "Comment",
  data: () => ({
    newComment: "",
    comments: [] as CommentSection[],
  }),
  computed: {
    commentSection(): CommentSection[] {
      return this.$store.getters["datamanager/getCommentSection"];
    },
    loggedInUserId() {
      return this.$store.getters["auth/getLoggedInUserId"];
    },
    countrySelected() {
      return this.$store.getters["datamanager/getCountrySelected"];
    },
  },
  methods: {
    async fetchCommentSection() {
      return await this.$store.dispatch("datamanager/fetchCommentSection", {
        country: this.countrySelected,
      });
    },
    ClearCommentText() {
      this.newComment = "";
    },
    async AddComment() {
      if (this.newComment.trim().length != 0) {
        let newData = { comment: this.newComment.trim() };
        await this.$store.dispatch("datamanager/addComment", {
          country: this.countrySelected,
          newData: newData,
        });
        this.ClearCommentText();
        this.fetchCommentSection();
      }
    },
  },
  filters: {
    moment: function(date: string) {
      moment.locale(i18n.locale);
      if (date == null) return i18n.t("basic.never");

      return moment(date).format("lll");
    },
  },
  watch: {
    commentSection: {
      handler(res) {
        this.comments = [...res];
      },
      deep: true,
    },
  },
  components: {},
  created() {
    if (this.comments.length == 0) this.comments = this.commentSection;
  },
});
