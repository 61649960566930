
import Vue from "vue";

export default Vue.extend({
  name: "SectionInhibitor",
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
    },
  },
  methods: {
    currentSurveyYear(){
      return this.$store.getters["datamanager/getCurrentSurveyYear"];
    },
    clearHemophiliaTypeAInhibitorsRow() {
      if (
        this.tempData.patientsHemophiliaTypeA_Inhibitors_NewCases_IsNotKnown
      ) {
        this.tempData.patientsHemophiliaTypeA_Inhibitors_NewCases = null;
      }
      if (this.tempData.patientsHemophiliaTypeA_Inhibitors_Total_IsNotKnown) {
        this.tempData.patientsHemophiliaTypeA_Inhibitors_Total = null;
      }
    },
    clearHemophiliaTypeBInhibitorsRow() {
      if (
        this.tempData.patientsHemophiliaTypeB_Inhibitors_NewCases_IsNotKnown
      ) {
        this.tempData.patientsHemophiliaTypeB_Inhibitors_NewCases = null;
      }
      if (this.tempData.patientsHemophiliaTypeB_Inhibitors_Total_IsNotKnown) {
        this.tempData.patientsHemophiliaTypeB_Inhibitors_Total = null;
      }
    },
  },
});
