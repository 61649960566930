<template>
  <v-container class="d-flex justify-center align-center" style="height: 1120px !important;">
    <div  >
      <div class="row justify-center mt-5">
        <v-img
            v-if="picture"
            max-width="200"
            min-width="200"
            :src="picture"
            contain
        ></v-img>
      </div>
      <div class="row justify-center">
        <div class="text-h3">{{title}}</div>

      </div>
      <div class="row justify-center">
        <div class="text-h4">{{subtitle}}</div>
      </div>
    </div>
  </v-container>


</template>

<script>
import Vue from "vue";
import img from "../../assets/img/WFH-En.png"


export default Vue.extend({
  name: "BaseExport",
  components:{},
  props:{
    title:String,
    subtitle:String,
    picture: String
  },
  data() {
    return {
      img
    };
  },
});
</script>
