var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h3',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiongender.title")))]),_c('v-simple-table',{staticClass:"mt-3",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('v-alert',{staticClass:"caption ma-1",attrs:{"text":"","dense":"","color":"teal","border":"left"}},[_c('ul',[_c('li',{class:_vm.isTotalNotSumErrors.length > 0 ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.text_1"))+" ")]),_c('li',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiongender.text_2")))])])])],1)]),_c('tr',[_c('td',{staticClass:"width-35p"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.plsdontestimate")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.total")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.male")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.female")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.unknownSex")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.nodata")))])])]),_c('tr',[_c('td',[_c('b',{class:_vm.isTotalNotSumErrors.includes('q1') ? 'errorred' : ''},[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiongender.q1_a_totalnumber")))])]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData,"required":"","rules":(!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData)
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsHemophiliaTypeA_Gender_Total,
                      _vm.tempData.patientsHemophiliaTypeA_Gender_Male
                        ? _vm.tempData.patientsHemophiliaTypeA_Gender_Male
                        : 0,
                      _vm.tempData.patientsHemophiliaTypeA_Gender_Female
                        ? _vm.tempData.patientsHemophiliaTypeA_Gender_Female
                        : 0,
                      _vm.tempData.patientsHemophiliaTypeA_Gender_Unknown
                        ? _vm.tempData.patientsHemophiliaTypeA_Gender_Unknown
                        : 0,
                      'q1',
                      _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsHemophiliaTypeA_Gender_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Gender_Total", _vm._n($$v))},expression:"tempData.patientsHemophiliaTypeA_Gender_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Gender_Male,"required":"","disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsHemophiliaTypeA_Gender_Male),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Gender_Male", _vm._n($$v))},expression:"tempData.patientsHemophiliaTypeA_Gender_Male"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Gender_Female,"required":"","disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsHemophiliaTypeA_Gender_Female),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Gender_Female", _vm._n($$v))},expression:"tempData.patientsHemophiliaTypeA_Gender_Female"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Gender_Unknown,"required":"","disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData,"rules":!_vm.isEditable?[]:   _vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsHemophiliaTypeA_Gender_Unknown),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Gender_Unknown", _vm._n($$v))},expression:"tempData.patientsHemophiliaTypeA_Gender_Unknown"}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeAGenderRow()}},model:{value:(_vm.tempData.patientsHemophiliaTypeA_Gender_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Gender_HasNoData", $$v)},expression:"tempData.patientsHemophiliaTypeA_Gender_HasNoData"}})],1)]),_c('tr',[_c('td',[_c('b',{class:_vm.isTotalNotSumErrors.includes('q2') ? 'errorred' : ''},[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiongender.q1_b_totalnumber")))])]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsHemophiliaTypeB_Gender_Total,
                      _vm.tempData.patientsHemophiliaTypeB_Gender_Male
                        ? _vm.tempData.patientsHemophiliaTypeB_Gender_Male
                        : 0,
                      _vm.tempData.patientsHemophiliaTypeB_Gender_Female
                        ? _vm.tempData.patientsHemophiliaTypeB_Gender_Female
                        : 0,
                      _vm.tempData.patientsHemophiliaTypeB_Gender_Unknown
                        ? _vm.tempData.patientsHemophiliaTypeB_Gender_Unknown
                        : 0,
                      'q2',
                      _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsHemophiliaTypeB_Gender_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Gender_Total", _vm._n($$v))},expression:"tempData.patientsHemophiliaTypeB_Gender_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsHemophiliaTypeB_Gender_Male),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Gender_Male", _vm._n($$v))},expression:"tempData.patientsHemophiliaTypeB_Gender_Male"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData,"rules":!_vm.isEditable?[]:  _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsHemophiliaTypeB_Gender_Female),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Gender_Female", _vm._n($$v))},expression:"tempData.patientsHemophiliaTypeB_Gender_Female"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData,"rules":!_vm.isEditable?[]:  _vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsHemophiliaTypeB_Gender_Unknown),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Gender_Unknown", _vm._n($$v))},expression:"tempData.patientsHemophiliaTypeB_Gender_Unknown"}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeBGenderRow()}},model:{value:(_vm.tempData.patientsHemophiliaTypeB_Gender_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Gender_HasNoData", $$v)},expression:"tempData.patientsHemophiliaTypeB_Gender_HasNoData"}})],1)]),_c('tr',[_c('td',[_c('b',{class:_vm.isTotalNotSumErrors.includes('q3') ? 'errorred' : ''},[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiongender.q1_unkwn_totalnumber")))])]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeUnknown_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Total,
                      _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Male
                        ? _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Male
                        : 0,
                      _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Female
                        ? _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Female
                        : 0,
                      _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Unknown
                        ? _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Unknown
                        : 0,
                      'q3',
                      _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeUnknown_Gender_Total", _vm._n($$v))},expression:"\n                tempData.patientsHemophiliaTypeUnknown_Gender_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeUnknown_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData,"rules":!_vm.isEditable?[]:   _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Male
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeUnknown_Gender_Male", _vm._n($$v))},expression:"\n                tempData.patientsHemophiliaTypeUnknown_Gender_Male\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeUnknown_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeUnknown_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsHemophiliaTypeUnknown_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeUnknown_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeUnknown_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsHemophiliaTypeUnknown_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeUnknownGenderRow()}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeUnknown_Gender_HasNoData", $$v)},expression:"\n                tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData\n              "}})],1)]),_c('tr',[_c('td',[_c('b',{class:_vm.isTotalNotSumErrors.includes('q4') ? 'errorred' : ''},[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiongender.q2_numberofvwd")))])]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWD_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Gender_Total,"disabled":!_vm.isEditable || _vm.tempData.patientsVWD_Gender_HasNoData,"rules":!_vm.isEditable || _vm.tempData.patientsVWD_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsVWD_Gender_Total,
                      _vm.tempData.patientsVWD_Gender_Male
                        ? _vm.tempData.patientsVWD_Gender_Male
                        : 0,
                      _vm.tempData.patientsVWD_Gender_Female
                        ? _vm.tempData.patientsVWD_Gender_Female
                        : 0,
                      _vm.tempData.patientsVWD_Gender_Unknown
                        ? _vm.tempData.patientsVWD_Gender_Unknown
                        : 0,
                      'q4',
                      _vm.tempData.patientsVWD_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsVWD_Gender_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Gender_Total", _vm._n($$v))},expression:"tempData.patientsVWD_Gender_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWD_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Gender_Male,"disabled":!_vm.isEditable || _vm.tempData.patientsVWD_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsVWD_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsVWD_Gender_Male),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Gender_Male", _vm._n($$v))},expression:"tempData.patientsVWD_Gender_Male"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWD_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Gender_Female,"disabled":!_vm.isEditable || _vm.tempData.patientsVWD_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsVWD_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsVWD_Gender_Female),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Gender_Female", _vm._n($$v))},expression:"tempData.patientsVWD_Gender_Female"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWD_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Gender_Unknown,"disabled":!_vm.isEditable || _vm.tempData.patientsVWD_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsVWD_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsVWD_Gender_Unknown),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Gender_Unknown", _vm._n($$v))},expression:"tempData.patientsVWD_Gender_Unknown"}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"disabled":!_vm.isEditable,"hide-details":"auto"},on:{"change":function($event){return _vm.clearVWDGenderRow()}},model:{value:(_vm.tempData.patientsVWD_Gender_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Gender_HasNoData", $$v)},expression:"tempData.patientsVWD_Gender_HasNoData"}})],1)]),_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiongender.q3_numberofhereditarybleeding" )))])])]),_c('tr',[_c('td',{class:_vm.isTotalNotSumErrors.includes('q5') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_f1deficiency"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorIDeficiency_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsFactorIDeficiency_Gender_Total,
                      _vm.tempData.patientsFactorIDeficiency_Gender_Male
                        ? _vm.tempData.patientsFactorIDeficiency_Gender_Male
                        : 0,
                      _vm.tempData.patientsFactorIDeficiency_Gender_Female
                        ? _vm.tempData.patientsFactorIDeficiency_Gender_Female
                        : 0,
                      _vm.tempData.patientsFactorIDeficiency_Gender_Unknown
                        ? _vm.tempData.patientsFactorIDeficiency_Gender_Unknown
                        : 0,
                      'q5',
                      _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsFactorIDeficiency_Gender_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorIDeficiency_Gender_Total", _vm._n($$v))},expression:"tempData.patientsFactorIDeficiency_Gender_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorIDeficiency_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:  _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsFactorIDeficiency_Gender_Male),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorIDeficiency_Gender_Male", _vm._n($$v))},expression:"tempData.patientsFactorIDeficiency_Gender_Male"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorIDeficiency_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorIDeficiency_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorIDeficiency_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsFactorIDeficiency_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorIDeficiency_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorIDeficiency_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorIDeficiency_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsFactorIDeficiency_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearFactorIDeficiencyGenderRow()}},model:{value:(_vm.tempData.patientsFactorIDeficiency_Gender_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorIDeficiency_Gender_HasNoData", $$v)},expression:"tempData.patientsFactorIDeficiency_Gender_HasNoData"}})],1)]),_c('tr',[_c('td',{class:_vm.isTotalNotSumErrors.includes('q6') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_f2deficiency"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorIIDeficiency_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsFactorIIDeficiency_Gender_Total,
                      _vm.tempData.patientsFactorIIDeficiency_Gender_Male
                        ? _vm.tempData.patientsFactorIIDeficiency_Gender_Male
                        : 0,
                      _vm.tempData.patientsFactorIIDeficiency_Gender_Female
                        ? _vm.tempData.patientsFactorIIDeficiency_Gender_Female
                        : 0,
                      _vm.tempData.patientsFactorIIDeficiency_Gender_Unknown
                        ? _vm.tempData.patientsFactorIIDeficiency_Gender_Unknown
                        : 0,
                      'q6',
                      _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorIIDeficiency_Gender_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorIIDeficiency_Gender_Total", _vm._n($$v))},expression:"\n                tempData.patientsFactorIIDeficiency_Gender_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorIIDeficiency_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsFactorIIDeficiency_Gender_Male),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorIIDeficiency_Gender_Male", _vm._n($$v))},expression:"tempData.patientsFactorIIDeficiency_Gender_Male"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorIIDeficiency_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:  _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorIIDeficiency_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorIIDeficiency_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsFactorIIDeficiency_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorIIDeficiency_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorIIDeficiency_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorIIDeficiency_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsFactorIIDeficiency_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearFactorIIDeficiencyGenderRow()}},model:{value:(_vm.tempData.patientsFactorIIDeficiency_Gender_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorIIDeficiency_Gender_HasNoData", $$v)},expression:"tempData.patientsFactorIIDeficiency_Gender_HasNoData"}})],1)]),_c('tr',[_c('td',{class:_vm.isTotalNotSumErrors.includes('q7') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_f5deficiency"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVDeficiency_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsFactorVDeficiency_Gender_Total,
                      _vm.tempData.patientsFactorVDeficiency_Gender_Male
                        ? _vm.tempData.patientsFactorVDeficiency_Gender_Male
                        : 0,
                      _vm.tempData.patientsFactorVDeficiency_Gender_Female
                        ? _vm.tempData.patientsFactorVDeficiency_Gender_Female
                        : 0,
                      _vm.tempData.patientsFactorVDeficiency_Gender_Unknown
                        ? _vm.tempData.patientsFactorVDeficiency_Gender_Unknown
                        : 0,
                      'q7',
                      _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsFactorVDeficiency_Gender_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVDeficiency_Gender_Total", _vm._n($$v))},expression:"tempData.patientsFactorVDeficiency_Gender_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVDeficiency_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:  _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsFactorVDeficiency_Gender_Male),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVDeficiency_Gender_Male", _vm._n($$v))},expression:"tempData.patientsFactorVDeficiency_Gender_Male"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVDeficiency_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:  _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorVDeficiency_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVDeficiency_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsFactorVDeficiency_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVDeficiency_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:    _vm.tempData.patientsFactorVDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorVDeficiency_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVDeficiency_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsFactorVDeficiency_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearFactorVDeficiencyGenderRow()}},model:{value:(_vm.tempData.patientsFactorVDeficiency_Gender_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVDeficiency_Gender_HasNoData", $$v)},expression:"tempData.patientsFactorVDeficiency_Gender_HasNoData"}})],1)]),_c('tr',[_c('td',{class:_vm.isTotalNotSumErrors.includes('q8') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_f5and8deficiency"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Total,
                      _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Male
                        ? _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Male
                        : 0,
                      _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Female
                        ? _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Female
                        : 0,
                      _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Unknown
                        ? _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Unknown
                        : 0,
                      'q8',
                      _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVAndVIIIDeficiency_Gender_Total", _vm._n($$v))},expression:"\n                tempData.patientsFactorVAndVIIIDeficiency_Gender_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Male
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVAndVIIIDeficiency_Gender_Male", _vm._n($$v))},expression:"\n                tempData.patientsFactorVAndVIIIDeficiency_Gender_Male\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVAndVIIIDeficiency_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsFactorVAndVIIIDeficiency_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVAndVIIIDeficiency_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsFactorVAndVIIIDeficiency_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearFactorVAndVIIIDeficiencyGenderRow()}},model:{value:(
                _vm.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVAndVIIIDeficiency_Gender_HasNoData", $$v)},expression:"\n                tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData\n              "}})],1)]),_c('tr',[_c('td',{class:_vm.isTotalNotSumErrors.includes('q9') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_f7deficiency"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVIIDeficiency_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsFactorVIIDeficiency_Gender_Total,
                      _vm.tempData.patientsFactorVIIDeficiency_Gender_Male
                        ? _vm.tempData.patientsFactorVIIDeficiency_Gender_Male
                        : 0,
                      _vm.tempData.patientsFactorVIIDeficiency_Gender_Female
                        ? _vm.tempData.patientsFactorVIIDeficiency_Gender_Female
                        : 0,
                      _vm.tempData.patientsFactorVIIDeficiency_Gender_Unknown
                        ? _vm.tempData.patientsFactorVIIDeficiency_Gender_Unknown
                        : 0,
                      'q9',
                      _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorVIIDeficiency_Gender_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVIIDeficiency_Gender_Total", _vm._n($$v))},expression:"\n                tempData.patientsFactorVIIDeficiency_Gender_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVIIDeficiency_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorVIIDeficiency_Gender_Male
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVIIDeficiency_Gender_Male", _vm._n($$v))},expression:"\n                tempData.patientsFactorVIIDeficiency_Gender_Male\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVIIDeficiency_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:  _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorVIIDeficiency_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVIIDeficiency_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsFactorVIIDeficiency_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorVIIDeficiency_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorVIIDeficiency_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVIIDeficiency_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsFactorVIIDeficiency_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearFactorVIIDeficiencyGenderRow()}},model:{value:(_vm.tempData.patientsFactorVIIDeficiency_Gender_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorVIIDeficiency_Gender_HasNoData", $$v)},expression:"tempData.patientsFactorVIIDeficiency_Gender_HasNoData"}})],1)]),_c('tr',[_c('td',{class:_vm.isTotalNotSumErrors.includes('q10') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_f10deficiency"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXDeficiency_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsFactorXDeficiency_Gender_Total,
                      _vm.tempData.patientsFactorXDeficiency_Gender_Male
                        ? _vm.tempData.patientsFactorXDeficiency_Gender_Male
                        : 0,
                      _vm.tempData.patientsFactorXDeficiency_Gender_Female
                        ? _vm.tempData.patientsFactorXDeficiency_Gender_Female
                        : 0,
                      _vm.tempData.patientsFactorXDeficiency_Gender_Unknown
                        ? _vm.tempData.patientsFactorXDeficiency_Gender_Unknown
                        : 0,
                      'q10',
                      _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsFactorXDeficiency_Gender_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXDeficiency_Gender_Total", _vm._n($$v))},expression:"tempData.patientsFactorXDeficiency_Gender_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXDeficiency_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsFactorXDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsFactorXDeficiency_Gender_Male),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXDeficiency_Gender_Male", _vm._n($$v))},expression:"tempData.patientsFactorXDeficiency_Gender_Male"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXDeficiency_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:  _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorXDeficiency_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXDeficiency_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsFactorXDeficiency_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXDeficiency_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorXDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorXDeficiency_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXDeficiency_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsFactorXDeficiency_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearFactorXDeficiencyGenderRow()}},model:{value:(_vm.tempData.patientsFactorXDeficiency_Gender_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXDeficiency_Gender_HasNoData", $$v)},expression:"tempData.patientsFactorXDeficiency_Gender_HasNoData"}})],1)]),_c('tr',[_c('td',{class:_vm.isTotalNotSumErrors.includes('q11') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_f11deficiency"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXIDeficiency_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsFactorXIDeficiency_Gender_Total,
                      _vm.tempData.patientsFactorXIDeficiency_Gender_Male
                        ? _vm.tempData.patientsFactorXIDeficiency_Gender_Male
                        : 0,
                      _vm.tempData.patientsFactorXIDeficiency_Gender_Female
                        ? _vm.tempData.patientsFactorXIDeficiency_Gender_Female
                        : 0,
                      _vm.tempData.patientsFactorXIDeficiency_Gender_Unknown
                        ? _vm.tempData.patientsFactorXIDeficiency_Gender_Unknown
                        : 0,
                      'q11',
                      _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorXIDeficiency_Gender_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXIDeficiency_Gender_Total", _vm._n($$v))},expression:"\n                tempData.patientsFactorXIDeficiency_Gender_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXIDeficiency_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsFactorXIDeficiency_Gender_Male),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXIDeficiency_Gender_Male", _vm._n($$v))},expression:"tempData.patientsFactorXIDeficiency_Gender_Male"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXIDeficiency_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorXIDeficiency_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXIDeficiency_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsFactorXIDeficiency_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXIDeficiency_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:  _vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorXIDeficiency_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXIDeficiency_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsFactorXIDeficiency_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":_vm.clearFactorXIDeficiencyGenderRow},model:{value:(_vm.tempData.patientsFactorXIDeficiency_Gender_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXIDeficiency_Gender_HasNoData", $$v)},expression:"tempData.patientsFactorXIDeficiency_Gender_HasNoData"}})],1)]),_c('tr',[_c('td',{class:_vm.isTotalNotSumErrors.includes('q12') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_f13deficiency"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXIIIDeficiency_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsFactorXIIIDeficiency_Gender_Total,
                      _vm.tempData.patientsFactorXIIIDeficiency_Gender_Male
                        ? _vm.tempData.patientsFactorXIIIDeficiency_Gender_Male
                        : 0,
                      _vm.tempData.patientsFactorXIIIDeficiency_Gender_Female
                        ? _vm.tempData.patientsFactorXIIIDeficiency_Gender_Female
                        : 0,
                      _vm.tempData.patientsFactorXIIIDeficiency_Gender_Unknown
                        ? _vm.tempData.patientsFactorXIIIDeficiency_Gender_Unknown
                        : 0,
                      'q12',
                      _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXIIIDeficiency_Gender_Total", _vm._n($$v))},expression:"\n                tempData.patientsFactorXIIIDeficiency_Gender_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXIIIDeficiency_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_Male
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXIIIDeficiency_Gender_Male", _vm._n($$v))},expression:"\n                tempData.patientsFactorXIIIDeficiency_Gender_Male\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXIIIDeficiency_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXIIIDeficiency_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsFactorXIIIDeficiency_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsFactorXIIIDeficiency_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsFactorXIIIDeficiency_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXIIIDeficiency_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsFactorXIIIDeficiency_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearFactorXIIIDeficiencyGenderRow()}},model:{value:(_vm.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsFactorXIIIDeficiency_Gender_HasNoData", $$v)},expression:"tempData.patientsFactorXIIIDeficiency_Gender_HasNoData"}})],1)]),_c('tr',{staticClass:"html2pdf__page-break"},[_c('td',{class:_vm.isTotalNotSumErrors.includes('q13') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_otherhereditary"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Total,
                      _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Male
                        ? _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Male
                        : 0,
                      _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Female
                        ? _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Female
                        : 0,
                      _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Unknown
                        ? _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Unknown
                        : 0,
                      'q13',
                      _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsOtherHereditaryBleedingDisorders_Gender_Total", _vm._n($$v))},expression:"\n                tempData.patientsOtherHereditaryBleedingDisorders_Gender_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Male
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsOtherHereditaryBleedingDisorders_Gender_Male", _vm._n($$v))},expression:"\n                tempData.patientsOtherHereditaryBleedingDisorders_Gender_Male\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsOtherHereditaryBleedingDisorders_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsOtherHereditaryBleedingDisorders_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsOtherHereditaryBleedingDisorders_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsOtherHereditaryBleedingDisorders_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearOtherHereditaryBleedingDisordersGenderRow()}},model:{value:(
                _vm.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsOtherHereditaryBleedingDisorders_Gender_HasNoData", $$v)},expression:"\n                tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData\n              "}})],1)]),_c('tr',{class:_vm.isPrinting?'mt-2':''},[_c('td',{class:_vm.isTotalNotSumErrors.includes('q14') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_plateletglanzmann"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Total,
                      _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Male
                        ? _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Male
                        : 0,
                      _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Female
                        ? _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Female
                        : 0,
                      _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Unknown
                        ? _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Unknown
                        : 0,
                      'q14',
                      _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Total", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Male
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Male", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Male\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearPlateletDisorderGlanzmannThrombastheniaGenderRow()}},model:{value:(
                _vm.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData", $$v)},expression:"\n                tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData\n              "}})],1)]),_c('tr',[_c('td',{class:_vm.isTotalNotSumErrors.includes('q15') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_plateletbernard"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Total,
                      _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Male
                        ? _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Male
                        : 0,
                      _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Female
                        ? _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Female
                        : 0,
                      _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Unknown
                        ? _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Unknown
                        : 0,
                      'q15',
                      _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderBernardSoulierSyndrome_Gender_Total", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Male
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderBernardSoulierSyndrome_Gender_Male", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Male\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData,"rules":!_vm.isEditable?[]:  _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderBernardSoulierSyndrome_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData,"rules":!_vm.isEditable?[]: _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderBernardSoulierSyndrome_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearPlateletDisorderBernardSoulierSyndromeGenderRow()}},model:{value:(
                _vm.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData", $$v)},expression:"\n                tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData\n              "}})],1)]),_c('tr',[_c('td',{class:_vm.isTotalNotSumErrors.includes('q16') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_plateletother"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData,"rules":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
                  ? []
                  : _vm.ruleTotalIsSum(
                      _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Total,
                      _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Male
                        ? _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Male
                        : 0,
                      _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Female
                        ? _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Female
                        : 0,
                      _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Unknown
                        ? _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Unknown
                        : 0,
                      'q16',
                      _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
                    )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderOtherOrUnknown_Gender_Total", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Male,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Male
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderOtherOrUnknown_Gender_Male", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Male\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Female,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Female
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderOtherOrUnknown_Gender_Female", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Female\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Unknown,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
              ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Unknown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderOtherOrUnknown_Gender_Unknown", _vm._n($$v))},expression:"\n                tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Unknown\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearPlateletDisorderOtherOrUnknownGenderRow()}},model:{value:(
                _vm.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData", $$v)},expression:"\n                tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData\n              "}})],1)]),_c('tr',[_c('td',{class:_vm.resaltGrey ? 'grey--text':''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiongender.q_considernumaccurate"))+" ")]),_c('td',{staticClass:"centered_td",attrs:{"colspan":"2"}},[(!_vm.resaltGrey)?_c('v-radio-group',{attrs:{"row":"","disabled":!_vm.isEditable,"hide-details":"auto","rules":!_vm.isEditable ? [] : [(v) => v != null || _vm.$t('rules.plsselect')],"required":""},model:{value:(_vm.tempData.genderIsInformationAccurate),callback:function ($$v) {_vm.$set(_vm.tempData, "genderIsInformationAccurate", $$v)},expression:"tempData.genderIsInformationAccurate"}},[_c('v-radio',{attrs:{"name":"q_radioGrp","label":_vm.$t('basic.yes'),"value":true}}),_c('v-radio',{attrs:{"name":"q_radioGrp","label":_vm.$t('basic.no'),"value":false}})],1):_vm._e()],1),_c('td',{attrs:{"colspan":"3"}})])])]},proxy:true}])}),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }