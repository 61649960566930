var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiondeath.q13_title")))])])]),_c('tr',[_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiondeath.q13_causes")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiondeath.q13_hemophilia")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiondeath.q13_vwd")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiondeath.q13_other")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiondeath.q13_nodata")))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiondeath.q13_cause_bleeding"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_Bleeding_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_Bleeding_PatientsHemophilia_Total,"disabled":!_vm.isEditable || _vm.tempData.death_Bleeding_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_Bleeding_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.death_Bleeding_PatientsHemophilia_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "death_Bleeding_PatientsHemophilia_Total", _vm._n($$v))},expression:"\n                tempData.death_Bleeding_PatientsHemophilia_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_Bleeding_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_Bleeding_PatientsVWD_Total,"disabled":!_vm.isEditable || _vm.tempData.death_Bleeding_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_Bleeding_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.death_Bleeding_PatientsVWD_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "death_Bleeding_PatientsVWD_Total", _vm._n($$v))},expression:"tempData.death_Bleeding_PatientsVWD_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_Bleeding_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_Bleeding_PatientsOtherBleedingDisorders_Total,"disabled":!_vm.isEditable || _vm.tempData.death_Bleeding_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_Bleeding_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.death_Bleeding_PatientsOtherBleedingDisorders_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "death_Bleeding_PatientsOtherBleedingDisorders_Total", _vm._n($$v))},expression:"\n                tempData.death_Bleeding_PatientsOtherBleedingDisorders_Total\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearDeathBleedingRow()}},model:{value:(_vm.tempData.death_Bleeding_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "death_Bleeding_HasNoData", $$v)},expression:"tempData.death_Bleeding_HasNoData"}})],1)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectiondeath.q13_cause_hiv")))]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_HIV_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_HIV_PatientsHemophilia_Total,"disabled":!_vm.isEditable || _vm.tempData.death_HIV_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_HIV_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.death_HIV_PatientsHemophilia_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "death_HIV_PatientsHemophilia_Total", _vm._n($$v))},expression:"tempData.death_HIV_PatientsHemophilia_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_HIV_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_HIV_PatientsVWD_Total,"disabled":!_vm.isEditable || _vm.tempData.death_HIV_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_HIV_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.death_HIV_PatientsVWD_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "death_HIV_PatientsVWD_Total", _vm._n($$v))},expression:"tempData.death_HIV_PatientsVWD_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_HIV_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_HIV_PatientsOtherBleedingDisorders_Total,"disabled":!_vm.isEditable || _vm.tempData.death_HIV_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_HIV_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.death_HIV_PatientsOtherBleedingDisorders_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "death_HIV_PatientsOtherBleedingDisorders_Total", _vm._n($$v))},expression:"\n                tempData.death_HIV_PatientsOtherBleedingDisorders_Total\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearDeathHIVRow()}},model:{value:(_vm.tempData.death_HIV_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "death_HIV_HasNoData", $$v)},expression:"tempData.death_HIV_HasNoData"}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiondeath.q13_cause_liverdisease"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_LiverDisease_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_LiverDisease_PatientsHemophilia_Total,"disabled":!_vm.isEditable || _vm.tempData.death_LiverDisease_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_LiverDisease_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.death_LiverDisease_PatientsHemophilia_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "death_LiverDisease_PatientsHemophilia_Total", _vm._n($$v))},expression:"\n                tempData.death_LiverDisease_PatientsHemophilia_Total\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_LiverDisease_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_LiverDisease_PatientsVWD_Total,"disabled":!_vm.isEditable || _vm.tempData.death_LiverDisease_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_LiverDisease_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.death_LiverDisease_PatientsVWD_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "death_LiverDisease_PatientsVWD_Total", _vm._n($$v))},expression:"tempData.death_LiverDisease_PatientsVWD_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_LiverDisease_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_LiverDisease_PatientsOtherBleedingDisorders_Total,"disabled":!_vm.isEditable || _vm.tempData.death_LiverDisease_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_LiverDisease_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.death_LiverDisease_PatientsOtherBleedingDisorders_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "death_LiverDisease_PatientsOtherBleedingDisorders_Total", _vm._n($$v))},expression:"\n                tempData.death_LiverDisease_PatientsOtherBleedingDisorders_Total\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearDeathLiverDiseaseRow()}},model:{value:(_vm.tempData.death_LiverDisease_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "death_LiverDisease_HasNoData", $$v)},expression:"tempData.death_LiverDisease_HasNoData"}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectiondeath.q13_cause_other"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_Other_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_Other_PatientsHemophilia_Total,"disabled":!_vm.isEditable || _vm.tempData.death_Other_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_Other_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.death_Other_PatientsHemophilia_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "death_Other_PatientsHemophilia_Total", _vm._n($$v))},expression:"tempData.death_Other_PatientsHemophilia_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_Other_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_Other_PatientsVWD_Total,"disabled":!_vm.isEditable || _vm.tempData.death_Other_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_Other_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.death_Other_PatientsVWD_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "death_Other_PatientsVWD_Total", _vm._n($$v))},expression:"tempData.death_Other_PatientsVWD_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.death_Other_HasNoData
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.death_Other_PatientsOtherBleedingDisorders_Total,"disabled":!_vm.isEditable || _vm.tempData.death_Other_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.death_Other_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.death_Other_PatientsOtherBleedingDisorders_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "death_Other_PatientsOtherBleedingDisorders_Total", _vm._n($$v))},expression:"\n                tempData.death_Other_PatientsOtherBleedingDisorders_Total\n              "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearDeathOtherRow()}},model:{value:(_vm.tempData.death_Other_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "death_Other_HasNoData", $$v)},expression:"tempData.death_Other_HasNoData"}})],1)])])]},proxy:true}])}),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }