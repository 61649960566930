<template>
  <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :filename="header.title + ' ' + header.subtitle"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @hasDownloaded="finishDownload"
      ref="html2Pdf"
  >
    <!-- @hasDownloaded="hasDownloaded($event)" -->
    <!-- @beforeDownload="beforeDownload($event)"  -->
    <section class="white" slot="pdf-content">
      <v-app class="white">
        <frontpage class="white" :picture="header.img ? header.img :img" :title="header.title" :subtitle="header.subtitle"/>
        <div class="html2pdf__page-break"/>
        <div class="white pa-2" v-for="(item,index) in elements" :key="index">
          <template>
            <component :is="item" />
            <div class="html2pdf__page-break"/>
          </template>
        </div>
      </v-app>
    </section>
  </VueHtml2pdf>
</template>

<script>
import Vue from "vue";
import VueHtml2pdf from "vue-html2pdf";
import frontpage from './FrontPage'
import img from "../../assets/img/WFH-En.png"

export default Vue.extend({
  name: "BaseExport",
  components:{VueHtml2pdf, frontpage},
  props:{
    elements: Array,
    header: Object|null,
    downloadAction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      img
    };
  },
  computed:{
    isPrinting: {
      //Could be a props
      get() {
        return this.$store.getters["datamanager/isPrinting"];
      },
      set(value) {
        return this.$store.dispatch("datamanager/setIsPrinting",value);
      },
    },
  },
  methods:{
    generateReport() {
      console.log("Generating PDF...")
      this.isPrinting = true
      const html2pdfComponent = this.$refs.html2Pdf;
      html2pdfComponent.generatePdf();
    },
    finishDownload() {
      this.$emit('downloadCompleted')
      this.isPrinting = false
    }
  },
  created(){
  },
  watch:{
    downloadAction(){
      if (this.downloadAction){
        this.generateReport()
      }
    }
  }
});
</script>
