var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q9_a_title")))]),_c('v-alert',{staticClass:"caption ma-1",attrs:{"text":"","dense":"","color":"teal","border":"left"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_text")))])])],1)]),_c('tr',[_c('td'),_c('td',{staticClass:"centered_td border-right"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_productavailable")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_treatednumber")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_nodata")))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionproduct.q9_a_plasma"))+" ")]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsHemophiliaProduct_Plasma_IsAvailable,
                    _vm.tempData.patientsHemophiliaProduct_Plasma_TreatedTotal,
                    _vm.tempData.patientsHemophiliaProduct_Plasma_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_Plasma_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_Plasma_IsAvailable", $$v)},expression:"\n                  tempData.patientsHemophiliaProduct_Plasma_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaProduct_Plasma_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaProduct_Plasma_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaProduct_Plasma_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaProduct_Plasma_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_Plasma_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_Plasma_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaProduct_Plasma_TreatedTotal\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaProductPlasmaRow()}},model:{value:(_vm.tempData.patientsHemophiliaProduct_Plasma_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_Plasma_HasNoData", $$v)},expression:"tempData.patientsHemophiliaProduct_Plasma_HasNoData"}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionproduct.q9_a_cryoprecipitate"))+" ")]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsHemophiliaProduct_Cryoprecipitate_IsAvailable,
                    _vm.tempData.patientsHemophiliaProduct_Cryoprecipitate_TreatedTotal,
                    _vm.tempData.patientsHemophiliaProduct_Cryoprecipitate_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_Cryoprecipitate_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_Cryoprecipitate_IsAvailable", $$v)},expression:"\n                  tempData.patientsHemophiliaProduct_Cryoprecipitate_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaProduct_Cryoprecipitate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaProduct_Cryoprecipitate_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaProduct_Cryoprecipitate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaProduct_Cryoprecipitate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_Cryoprecipitate_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_Cryoprecipitate_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaProduct_Cryoprecipitate_TreatedTotal\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaProductCryoprecipitateRow()}},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_Cryoprecipitate_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_Cryoprecipitate_HasNoData", $$v)},expression:"\n                  tempData.patientsHemophiliaProduct_Cryoprecipitate_HasNoData\n                "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionproduct.q9_a_plasmaderivedconcentrate" ))+" ")]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_IsAvailable,
                    _vm.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_TreatedTotal,
                    _vm.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_PlasmaDerivedConcentrate_IsAvailable", $$v)},expression:"\n                  tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_PlasmaDerivedConcentrate_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_TreatedTotal\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaProductPlasmaDerivedConcentrateRow()}},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_PlasmaDerivedConcentrate_HasNoData", $$v)},expression:"\n                  tempData.patientsHemophiliaProduct_PlasmaDerivedConcentrate_HasNoData\n                "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionproduct.q9_a_recombinantconcentrate" ))+" ")]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsHemophiliaProduct_RecombinantConcentrate_IsAvailable,
                    _vm.tempData.patientsHemophiliaProduct_RecombinantConcentrate_TreatedTotal,
                    _vm.tempData.patientsHemophiliaProduct_RecombinantConcentrate_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_RecombinantConcentrate_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_RecombinantConcentrate_IsAvailable", $$v)},expression:"\n                  tempData.patientsHemophiliaProduct_RecombinantConcentrate_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaProduct_RecombinantConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaProduct_RecombinantConcentrate_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaProduct_RecombinantConcentrate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaProduct_RecombinantConcentrate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_RecombinantConcentrate_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_RecombinantConcentrate_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaProduct_RecombinantConcentrate_TreatedTotal\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaProductRecombinantConcentrateRow()}},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_RecombinantConcentrate_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_RecombinantConcentrate_HasNoData", $$v)},expression:"\n                  tempData.patientsHemophiliaProduct_RecombinantConcentrate_HasNoData\n                "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionproduct.q9_a_recombinantextendedhalflifeconcentrate" ))+" ")]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_IsAvailable,
                    _vm.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_TreatedTotal,
                    _vm.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_IsAvailable", $$v)},expression:"\n                  tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_TreatedTotal\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaProductRecombinantExtendedHalfLifeConcentrateRow()}},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_HasNoData", $$v)},expression:"\n                  tempData.patientsHemophiliaProduct_RecombinantExtendedHalfLifeConcentrate_HasNoData\n                "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionproduct.q9_a_ddavp"))+" ")]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsHemophiliaProduct_DDAVP_IsAvailable,
                    _vm.tempData.patientsHemophiliaProduct_DDAVP_TreatedTotal,
                    _vm.tempData.patientsHemophiliaProduct_DDAVP_HasNoData
                  )},model:{value:(_vm.tempData.patientsHemophiliaProduct_DDAVP_IsAvailable),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_DDAVP_IsAvailable", $$v)},expression:"tempData.patientsHemophiliaProduct_DDAVP_IsAvailable"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaProduct_DDAVP_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaProduct_DDAVP_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaProduct_DDAVP_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaProduct_DDAVP_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaProduct_DDAVP_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_DDAVP_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaProduct_DDAVP_TreatedTotal\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaProductDDAVPRow()}},model:{value:(_vm.tempData.patientsHemophiliaProduct_DDAVP_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaProduct_DDAVP_HasNoData", $$v)},expression:"tempData.patientsHemophiliaProduct_DDAVP_HasNoData"}})],1)])])]},proxy:true}])}),_c('v-divider')],1),_c('v-container',[_c('div',{staticClass:"html2pdf__page-break"}),_c('v-simple-table',{class:_vm.isPrinting ? 'mt-2' : '',scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q9_b_title")))]),_c('v-alert',{staticClass:"caption ma-1",attrs:{"text":"","dense":"","color":"teal","border":"left"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_text")))])])],1)]),_c('tr',[_c('td'),_c('td',{staticClass:"centered_td border-right"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_productavailable")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_treatednumber")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_nodata")))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionproduct.q9_b_emicizumab"))+" ")]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_IsAvailable,
                    _vm.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_TreatedTotal,
                    _vm.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaWithInhibitorsProduct_Emicizumab_IsAvailable", $$v)},expression:"\n                  tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaWithInhibitorsProduct_Emicizumab_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_TreatedTotal\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaWithInhibitorsProductEmicizumabRow()}},model:{value:(
                  _vm.tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaWithInhibitorsProduct_Emicizumab_HasNoData", $$v)},expression:"\n                  tempData.patientsHemophiliaWithInhibitorsProduct_Emicizumab_HasNoData\n                "}})],1)])])]},proxy:true}])}),_c('v-divider')],1),_c('v-container',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q9_c_title")))]),_c('v-alert',{staticClass:"caption ma-1",attrs:{"text":"","dense":"","color":"teal","border":"left"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_text")))])])],1)]),_c('tr',[_c('td'),_c('td',{staticClass:"centered_td border-right"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_productavailable")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_treatednumber")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_nodata")))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionproduct.q9_c_emicizumab"))+" ")]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_IsAvailable,
                    _vm.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_TreatedTotal,
                    _vm.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_IsAvailable", $$v)},expression:"\n                  tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_TreatedTotal\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaWithoutInhibitorsProductEmicizumabRow()}},model:{value:(
                  _vm.tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_HasNoData", $$v)},expression:"\n                  tempData.patientsHemophiliaWithoutInhibitorsProduct_Emicizumab_HasNoData\n                "}})],1)])])]},proxy:true}])}),_c('v-divider')],1),_c('v-container',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"8"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q10_title")))]),_c('v-alert',{staticClass:"caption ma-1",attrs:{"text":"","dense":"","color":"teal","border":"left"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_text")))])])],1)]),_c('tr',[_c('td'),_c('td',{staticClass:"centered_td border-right"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_productavailable")))])]),_c('td',{attrs:{"colspan":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_treatednumber")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionproduct.q_nodata")))])])]),_c('tr',[_c('td'),_c('td'),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.total")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.male")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.female")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.unknownSex")))])]),_c('td')]),_c('tr',[_c('td',[_c('span',{class:_vm.isTotalNotSumErrors.includes('q10_plasma') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionproduct.q10_plasma"))+" ")])]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsVWDProduct_Plasma_IsAvailable,
                    _vm.tempData.patientsVWDProduct_Plasma_TreatedTotal,
                    _vm.tempData.patientsVWDProduct_Plasma_HasNoData
                  )},model:{value:(_vm.tempData.patientsVWDProduct_Plasma_IsAvailable),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Plasma_IsAvailable", $$v)},expression:"tempData.patientsVWDProduct_Plasma_IsAvailable"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWDProduct_Plasma_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_Plasma_TreatedTotal,"disabled":!_vm.isEditable || _vm.tempData.patientsVWDProduct_Plasma_HasNoData,"rules":!_vm.isEditable || _vm.tempData.patientsVWDProduct_Plasma_HasNoData
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWDProduct_Plasma_TreatedTotal,
                        _vm.tempData.patientsVWDProduct_Plasma_TreatedMale
                          ? _vm.tempData.patientsVWDProduct_Plasma_TreatedMale
                          : 0,
                        _vm.tempData.patientsVWDProduct_Plasma_TreatedFemale
                          ? _vm.tempData.patientsVWDProduct_Plasma_TreatedFemale
                          : 0,
                        _vm.tempData.patientsVWDProduct_Plasma_TreatedUnknown
                          ? _vm.tempData.patientsVWDProduct_Plasma_TreatedUnknown
                          : 0,
                        'q10_plasma',
                        _vm.tempData.patientsVWDProduct_Plasma_HasNoData
                      )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_Plasma_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Plasma_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_Plasma_TreatedTotal\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWDProduct_Plasma_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_Plasma_TreatedMale,"disabled":!_vm.isEditable || _vm.tempData.patientsVWDProduct_Plasma_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_Plasma_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_Plasma_TreatedMale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Plasma_TreatedMale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_Plasma_TreatedMale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWDProduct_Plasma_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_Plasma_TreatedFemale,"disabled":!_vm.isEditable || _vm.tempData.patientsVWDProduct_Plasma_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_Plasma_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_Plasma_TreatedFemale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Plasma_TreatedFemale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_Plasma_TreatedFemale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWDProduct_Plasma_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_Plasma_TreatedUnknown,"disabled":!_vm.isEditable || _vm.tempData.patientsVWDProduct_Plasma_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_Plasma_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_Plasma_TreatedUnknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Plasma_TreatedUnknown", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_Plasma_TreatedUnknown\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDProductPlasmaRow()}},model:{value:(_vm.tempData.patientsVWDProduct_Plasma_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Plasma_HasNoData", $$v)},expression:"tempData.patientsVWDProduct_Plasma_HasNoData"}})],1)]),_c('tr',[_c('td',[_c('span',{class:_vm.isTotalNotSumErrors.includes('q10_cryoprecipitate')
                    ? 'errorred'
                    : ''},[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionproduct.q10_cryoprecipitate" ))+" ")])]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsVWDProduct_Cryoprecipitate_IsAvailable,
                    _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedTotal,
                    _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Cryoprecipitate_IsAvailable", $$v)},expression:"\n                  tempData.patientsVWDProduct_Cryoprecipitate_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData,"rules":!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedTotal,
                        _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedMale
                          ? _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedMale
                          : 0,
                        _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedFemale
                          ? _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedFemale
                          : 0,
                        _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedUnknown
                          ? _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedUnknown
                          : 0,
                        'q10_cryoprecipitate',
                        _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                      )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Cryoprecipitate_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_Cryoprecipitate_TreatedTotal\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedMale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedMale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Cryoprecipitate_TreatedMale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_Cryoprecipitate_TreatedMale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedFemale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedFemale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Cryoprecipitate_TreatedFemale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_Cryoprecipitate_TreatedFemale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedUnknown,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_TreatedUnknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Cryoprecipitate_TreatedUnknown", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_Cryoprecipitate_TreatedUnknown\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDProductCryoprecipitateRow()}},model:{value:(
                  _vm.tempData.patientsVWDProduct_Cryoprecipitate_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_Cryoprecipitate_HasNoData", $$v)},expression:"\n                  tempData.patientsVWDProduct_Cryoprecipitate_HasNoData\n                "}})],1)]),_c('tr',[_c('td',[_c('span',{class:_vm.isTotalNotSumErrors.includes('q10_plasmaderivedconcentrate')
                    ? 'errorred'
                    : ''},[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionproduct.q10_plasmaderivedconcentrate" ))+" ")])]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_IsAvailable,
                    _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedTotal,
                    _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_PlasmaDerivedConcentrate_IsAvailable", $$v)},expression:"\n                  tempData.patientsVWDProduct_PlasmaDerivedConcentrate_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData,"rules":!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedTotal,
                        _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedMale
                          ? _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedMale
                          : 0,
                        _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedFemale
                          ? _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedFemale
                          : 0,
                        _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedUnknown
                          ? _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedUnknown
                          : 0,
                        'q10_plasmaderivedconcentrate',
                        _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                      )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_PlasmaDerivedConcentrate_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedTotal\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedMale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedMale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_PlasmaDerivedConcentrate_TreatedMale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedMale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedFemale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedFemale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_PlasmaDerivedConcentrate_TreatedFemale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedFemale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedUnknown,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedUnknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_PlasmaDerivedConcentrate_TreatedUnknown", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_PlasmaDerivedConcentrate_TreatedUnknown\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDProductPlasmaDerivedConcentrateRow()}},model:{value:(
                  _vm.tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData", $$v)},expression:"\n                  tempData.patientsVWDProduct_PlasmaDerivedConcentrate_HasNoData\n                "}})],1)]),_c('tr',[_c('td',[_c('span',{class:_vm.isTotalNotSumErrors.includes('q10_ddavp') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionproduct.q10_ddavp"))+" ")])]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsVWDProduct_DDAVP_IsAvailable,
                    _vm.tempData.patientsVWDProduct_DDAVP_TreatedTotal,
                    _vm.tempData.patientsVWDProduct_DDAVP_HasNoData
                  )},model:{value:(_vm.tempData.patientsVWDProduct_DDAVP_IsAvailable),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_IsAvailable", $$v)},expression:"tempData.patientsVWDProduct_DDAVP_IsAvailable"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWDProduct_DDAVP_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_TreatedTotal,"disabled":!_vm.isEditable || _vm.tempData.patientsVWDProduct_DDAVP_HasNoData,"rules":!_vm.isEditable || _vm.tempData.patientsVWDProduct_DDAVP_HasNoData
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWDProduct_DDAVP_TreatedTotal,
                        _vm.tempData.patientsVWDProduct_DDAVP_TreatedMale
                          ? _vm.tempData.patientsVWDProduct_DDAVP_TreatedMale
                          : 0,
                        _vm.tempData.patientsVWDProduct_DDAVP_TreatedFemale
                          ? _vm.tempData.patientsVWDProduct_DDAVP_TreatedFemale
                          : 0,
                        _vm.tempData.patientsVWDProduct_DDAVP_TreatedUnknown
                          ? _vm.tempData.patientsVWDProduct_DDAVP_TreatedUnknown
                          : 0,
                        'q10_ddavp',
                        _vm.tempData.patientsVWDProduct_DDAVP_HasNoData
                      )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_TreatedTotal\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWDProduct_DDAVP_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_TreatedMale,"disabled":!_vm.isEditable || _vm.tempData.patientsVWDProduct_DDAVP_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_DDAVP_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.patientsVWDProduct_DDAVP_TreatedMale),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_TreatedMale", _vm._n($$v))},expression:"tempData.patientsVWDProduct_DDAVP_TreatedMale"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWDProduct_DDAVP_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_TreatedFemale,"disabled":!_vm.isEditable || _vm.tempData.patientsVWDProduct_DDAVP_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_DDAVP_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_TreatedFemale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_TreatedFemale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_TreatedFemale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWDProduct_DDAVP_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_TreatedUnknown,"disabled":!_vm.isEditable || _vm.tempData.patientsVWDProduct_DDAVP_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_DDAVP_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_TreatedUnknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_TreatedUnknown", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_TreatedUnknown\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDProductDDAVPRow()}},model:{value:(_vm.tempData.patientsVWDProduct_DDAVP_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_HasNoData", $$v)},expression:"tempData.patientsVWDProduct_DDAVP_HasNoData"}})],1)]),_c('tr',[_c('td',[_c('span',{class:_vm.isTotalNotSumErrors.includes('q10_ddavp_nasal')
                    ? 'errorred'
                    : ''},[_vm._v("   "+_vm._s(_vm.$t("datamanager.patients.sectionproduct.q10_ddavp_nasal"))+" ")])]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsVWDProduct_DDAVP_Nasal_IsAvailable,
                    _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedTotal,
                    _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData
                  )},model:{value:(_vm.tempData.patientsVWDProduct_DDAVP_Nasal_IsAvailable),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_Nasal_IsAvailable", $$v)},expression:"tempData.patientsVWDProduct_DDAVP_Nasal_IsAvailable"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData,"rules":!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedTotal,
                        _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedMale
                          ? _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedMale
                          : 0,
                        _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedFemale
                          ? _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedFemale
                          : 0,
                        _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedUnknown
                          ? _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedUnknown
                          : 0,
                        'q10_ddavp_nasal',
                        _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData
                      )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_Nasal_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_Nasal_TreatedTotal\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedMale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedMale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_Nasal_TreatedMale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_Nasal_TreatedMale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedFemale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedFemale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_Nasal_TreatedFemale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_Nasal_TreatedFemale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedUnknown,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_Nasal_TreatedUnknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_Nasal_TreatedUnknown", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_Nasal_TreatedUnknown\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDProductDDAVPNasalRow()}},model:{value:(_vm.tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_Nasal_HasNoData", $$v)},expression:"tempData.patientsVWDProduct_DDAVP_Nasal_HasNoData"}})],1)]),_c('tr',[_c('td',[_c('span',{class:_vm.isTotalNotSumErrors.includes('q10_ddavp_ivsub')
                    ? 'errorred'
                    : ''},[_vm._v("   "+_vm._s(_vm.$t("datamanager.patients.sectionproduct.q10_ddavp_ivsub"))+" ")])]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_IsAvailable,
                    _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedTotal,
                    _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_IVOrSubcutaneous_IsAvailable", $$v)},expression:"\n                  tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData,"rules":!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedTotal,
                        _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedMale
                          ? _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedMale
                          : 0,
                        _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedFemale
                          ? _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedFemale
                          : 0,
                        _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedUnknown
                          ? _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedUnknown
                          : 0,
                        'q10_ddavp_ivsub',
                        _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                      )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedTotal\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedMale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedMale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedMale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedMale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedFemale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedFemale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedFemale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedFemale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedUnknown,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedUnknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedUnknown", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_TreatedUnknown\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDProductDDAVPIVOrSubcutaneousRow()}},model:{value:(
                  _vm.tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData", $$v)},expression:"\n                  tempData.patientsVWDProduct_DDAVP_IVOrSubcutaneous_HasNoData\n                "}})],1)]),_c('tr',[_c('td',[_c('span',{class:_vm.isTotalNotSumErrors.includes('q10_recombinantconcentrate')
                    ? 'errorred'
                    : ''},[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionproduct.q10_recombinantconcentrate" ))+" ")])]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsVWDProduct_RecombinantConcentrate_IsAvailable,
                    _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedTotal,
                    _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_RecombinantConcentrate_IsAvailable", $$v)},expression:"\n                  tempData.patientsVWDProduct_RecombinantConcentrate_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData,"rules":!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedTotal,
                        _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedMale
                          ? _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedMale
                          : 0,
                        _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedFemale
                          ? _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedFemale
                          : 0,
                        _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedUnknown
                          ? _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedUnknown
                          : 0,
                        'q10_recombinantconcentrate',
                        _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                      )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_RecombinantConcentrate_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_RecombinantConcentrate_TreatedTotal\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedMale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedMale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_RecombinantConcentrate_TreatedMale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_RecombinantConcentrate_TreatedMale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedFemale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedFemale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_RecombinantConcentrate_TreatedFemale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_RecombinantConcentrate_TreatedFemale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedUnknown,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_TreatedUnknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_RecombinantConcentrate_TreatedUnknown", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_RecombinantConcentrate_TreatedUnknown\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDProductRecombinantConcentrateRow()}},model:{value:(
                  _vm.tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_RecombinantConcentrate_HasNoData", $$v)},expression:"\n                  tempData.patientsVWDProduct_RecombinantConcentrate_HasNoData\n                "}})],1)]),_c('tr',[_c('td',[_c('span',{class:_vm.isTotalNotSumErrors.includes('q10_tranexamicacid')
                    ? 'errorred'
                    : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionproduct.q10_tranexamicacid"))+" ")])]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsVWDProduct_TranexamicAcid_IsAvailable,
                    _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedTotal,
                    _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsVWDProduct_TranexamicAcid_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_TranexamicAcid_IsAvailable", $$v)},expression:"\n                  tempData.patientsVWDProduct_TranexamicAcid_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData,"rules":!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedTotal,
                        _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedMale
                          ? _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedMale
                          : 0,
                        _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedFemale
                          ? _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedFemale
                          : 0,
                        _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedUnknown
                          ? _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedUnknown
                          : 0,
                        'q10_tranexamicacid',
                        _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData
                      )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_TranexamicAcid_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_TranexamicAcid_TreatedTotal\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedMale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedMale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_TranexamicAcid_TreatedMale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_TranexamicAcid_TreatedMale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedFemale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedFemale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_TranexamicAcid_TreatedFemale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_TranexamicAcid_TreatedFemale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedUnknown,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_TranexamicAcid_TreatedUnknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_TranexamicAcid_TreatedUnknown", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_TranexamicAcid_TreatedUnknown\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDProductTranexamicAcidRow()}},model:{value:(_vm.tempData.patientsVWDProduct_TranexamicAcid_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_TranexamicAcid_HasNoData", $$v)},expression:"tempData.patientsVWDProduct_TranexamicAcid_HasNoData"}})],1)]),_c('tr',[_c('td',[_c('span',{class:_vm.isTotalNotSumErrors.includes('q10_hormonaltherapyFrom2022')
                    ? 'errorred'
                    : ''},[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionproduct.q10_hormonaltherapyFrom2022" ))+" ")])]),_c('td',{staticClass:"centered_td border-right"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable,"rules":_vm.mustBeChecked(
                    _vm.tempData.patientsVWDProduct_HormonalTherapy_IsAvailable,
                    _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedTotal,
                    _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                  )},model:{value:(
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_IsAvailable
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_HormonalTherapy_IsAvailable", $$v)},expression:"\n                  tempData.patientsVWDProduct_HormonalTherapy_IsAvailable\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedTotal,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData,"rules":!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedTotal,
                        _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedMale
                          ? _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedMale
                          : 0,
                        _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedFemale
                          ? _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedFemale
                          : 0,
                        _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedUnknown
                          ? _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedUnknown
                          : 0,
                        'q10_hormonaltherapyFrom2022',
                        _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                      )},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedTotal
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_HormonalTherapy_TreatedTotal", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_HormonalTherapy_TreatedTotal\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedMale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedMale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_HormonalTherapy_TreatedMale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_HormonalTherapy_TreatedMale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedFemale,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedFemale
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_HormonalTherapy_TreatedFemale", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_HormonalTherapy_TreatedFemale\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedUnknown,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_TreatedUnknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_HormonalTherapy_TreatedUnknown", _vm._n($$v))},expression:"\n                  tempData.patientsVWDProduct_HormonalTherapy_TreatedUnknown\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDProductHormonalTherapyRow()}},model:{value:(
                  _vm.tempData.patientsVWDProduct_HormonalTherapy_HasNoData
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWDProduct_HormonalTherapy_HasNoData", $$v)},expression:"\n                  tempData.patientsVWDProduct_HormonalTherapy_HasNoData\n                "}})],1)])])]},proxy:true}])}),_c('v-divider')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }