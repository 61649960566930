
import Vue from "vue";
import FormEditFooter from "../UI-Components/FormEditFooter.vue";
import {OrganizationSection} from "@/models/organizationsection";
import {SaveRes} from "@/models/saveres";
import {Snackbar} from "@/models/snackbar";
import formrevalidate from "../../mixins/formrevalidate";
import i18n from "@/i18n";

export default Vue.extend({
  name: "Organization",
  mixins: [formrevalidate],
  props: {
    isAllowedToEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isEditable: false,
    headers: [
      {text: "basic.country", value: "organizationId"},
      {text: "basic.name", value: "organizationName"},
      {text: "basic.city", value: "organizationCity"},
      {text: "basic.phone", value: "organizationPhone"},
      {text: "basic.email", value: "organizationEmail"},
    ],
    tempData: {} as OrganizationSection,
    initData: {} as OrganizationSection,
    shallSkipDialog: false,
    hasSmthChanged: false,
    isFormValid: true,
    rules: {
      name: [(v: string) => !!v || i18n.t("rules.namerequired")],
      email: [
        (v: string) => !!v || i18n.t("rules.emailrequired"),
        (v: string) =>
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([;|\s]{1}\s*([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/.test(
                v
            ) || i18n.t("rules.emailmustbevalid"),
      ],
      phone: [(v: string) => !!v || i18n.t("rules.phonerequired")],
      city: [(v: string) => !!v || i18n.t("rules.cityrequired")],
    },
  }),
  computed: {
    isPrinting(){
      return this.$store.getters["datamanager/isPrinting"];
    },
    isSurveyYearModifiable() {
      //Could be a props
      return this.$store.getters["datamanager/getIsSurveyYearModifiable"];
    },
    sectionsStatuses() {
      //Could be a props
      return this.$store.getters["datamanager/getSectionsStatuses"];
    },
    organizationSection() {
      return this.$store.getters["datamanager/getOrganizationSection"];
    },
    countrySelected() {
      //Could be a props
      return this.$store.getters["datamanager/getCountrySelected"];
    },
    organizationSectionSaveRes() {
      return this.$store.getters["datamanager/getOrganizationSectionSaveRes"];
    },
    hasUnsavedChanges: {
      //Could be a props
      get() {
        return this.$store.getters["datamanager/getHasUnsavedChanges"];
      },
      set(value: boolean) {
        return this.$store.dispatch(
            "datamanager/changeHasUnsavedChanges",
            value
        );
      },
    },
  },
  methods: {
    async fetchSectionsStatuses() {
      //Could be a props
      await this.$store.dispatch("datamanager/fetchSectionsStatuses", {
        country: this.countrySelected,
      });
    },
    triggerSnack(snack: Snackbar) {
      //Could be a props
      this.$store.dispatch("general/changeSnackbar", snack);
    },
    resetValidation() {
      //Could be a props?
      let refForm: any = this.$refs.form;
      if (refForm != undefined) refForm.resetValidation();
    },
    saveit() {
      this.isEditable = false;
      this.shallSkipDialog = false;
      this.saveSurveyOrganizationSection()
          .then((e) => {
            if (e.status == 200) {
              let snack = {
                isSnacking: true,
                snackColor: "success",
                snackMessage: this.$t("basic.savesuccess").toString(),
                snackTimeout: "2000",
              };
              this.triggerSnack(snack);
              this.fetchSectionsStatuses();
            }
          })
          .catch((err) => {
            if (err.response.status == 422) {
              let snack = {
                isSnacking: true,
                snackColor: "orange",
                snackMessage:
                    this.$t("basic.savewarning").toString() +
                    err.response.statusText,
                snackTimeout: "2000",
              };
              this.triggerSnack(snack);
            } else {
              let snack = {
                isSnacking: true,
                snackColor: "error",
                snackMessage:
                    this.$t("basic.saveerror").toString() + err.response.statusText,
                snackTimeout: "2000",
              };
              this.triggerSnack(snack);
            }
            this.isEditable = true;
          });
    },
    willSave() {
      this.tempData.organizationSectionIsComplete = true;
      this.saveit();
    },
    willSaveAsDraft() {
      this.tempData.organizationSectionIsComplete = false;
      this.saveit();
    },

    reloadTempData() {
      //Could be a props ?
      this.tempData = {...this.initData};
      this.resetValidation();
    },
    async saveSurveyOrganizationSection() {
      await this.$store.dispatch("datamanager/saveSurveyOrganizationSection", {
        country: this.countrySelected,
        newSurveyOrgData: this.tempData,
      });
      return this.organizationSectionSaveRes as SaveRes;
    },
  },
  watch: {
    organizationSection: {
      handler(res) {
        this.initData = {...res};
        if (res.organizationSectionIsComplete != null) {
          this.isEditable = false;
        } else {
          if (
              !this.sectionsStatuses.isApproved &&
              this.isAllowedToEdit &&
              this.isSurveyYearModifiable
          ) {
            this.isEditable = true;
          }
          this.shallSkipDialog = true;
        }
        this.reloadTempData();
      },
      deep: true,
    },
    isSurveyYearModifiable: {
      //Could be a props
      handler(res) {
        if (res == false) {
          this.isEditable = false; //Could be a props
        }
      },
      deep: true,
    },
    tempData: {
      handler(res) {
        if (JSON.stringify(res) === JSON.stringify(this.initData)) {
          this.hasUnsavedChanges = false;
        } else if (this.hasUnsavedChanges == false) {
          this.hasUnsavedChanges = true;
        }
      },
      deep: true,
    },
  },
  components: {
    "form-edit-footer": FormEditFooter,
  },
});
