var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninhibitor.q8_title")))])])]),_c('tr',[_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninhibitor.q8_typeofhemophilia")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninhibitor.q8_totalnumber")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninhibitor.q8_nodata")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninhibitor.q8_newcases", {'currentYear' : _vm.currentSurveyYear()})))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninhibitor.q8_nodata")))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectioninhibitor.q8_hemophiliaa"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Inhibitors_Total_IsNotKnown
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Inhibitors_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Inhibitors_Total_IsNotKnown,"rules":!_vm.isEditable?[]:_vm.tempData.patientsHemophiliaTypeA_Inhibitors_Total_IsNotKnown
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeA_Inhibitors_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Inhibitors_Total", _vm._n($$v))},expression:"\n                tempData.patientsHemophiliaTypeA_Inhibitors_Total\n              "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeAInhibitorsRow()}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeA_Inhibitors_Total_IsNotKnown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Inhibitors_Total_IsNotKnown", $$v)},expression:"\n                tempData.patientsHemophiliaTypeA_Inhibitors_Total_IsNotKnown\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Inhibitors_NewCases_IsNotKnown
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Inhibitors_NewCases,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeA_Inhibitors_NewCases_IsNotKnown,"rules":!_vm.isEditable?[]:_vm.tempData.patientsHemophiliaTypeA_Inhibitors_NewCases_IsNotKnown
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeA_Inhibitors_NewCases
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Inhibitors_NewCases", _vm._n($$v))},expression:"\n                tempData.patientsHemophiliaTypeA_Inhibitors_NewCases\n              "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeAInhibitorsRow()}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeA_Inhibitors_NewCases_IsNotKnown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Inhibitors_NewCases_IsNotKnown", $$v)},expression:"\n                tempData.patientsHemophiliaTypeA_Inhibitors_NewCases_IsNotKnown\n              "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectioninhibitor.q8_hemophiliab"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Inhibitors_Total_IsNotKnown
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Inhibitors_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Inhibitors_Total_IsNotKnown,"rules":!_vm.isEditable?[]:_vm.tempData.patientsHemophiliaTypeB_Inhibitors_Total_IsNotKnown
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeB_Inhibitors_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Inhibitors_Total", _vm._n($$v))},expression:"\n                tempData.patientsHemophiliaTypeB_Inhibitors_Total\n              "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeBInhibitorsRow()}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeB_Inhibitors_Total_IsNotKnown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Inhibitors_Total_IsNotKnown", $$v)},expression:"\n                tempData.patientsHemophiliaTypeB_Inhibitors_Total_IsNotKnown\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Inhibitors_NewCases_IsNotKnown
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Inhibitors_NewCases,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeB_Inhibitors_NewCases_IsNotKnown,"rules":!_vm.isEditable?[]:_vm.tempData.patientsHemophiliaTypeB_Inhibitors_NewCases_IsNotKnown
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeB_Inhibitors_NewCases
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Inhibitors_NewCases", _vm._n($$v))},expression:"\n                tempData.patientsHemophiliaTypeB_Inhibitors_NewCases\n              "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeBInhibitorsRow()}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeB_Inhibitors_NewCases_IsNotKnown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Inhibitors_NewCases_IsNotKnown", $$v)},expression:"\n                tempData.patientsHemophiliaTypeB_Inhibitors_NewCases_IsNotKnown\n              "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectioninhibitor.q8_vwdFrom2022"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_Total_IsNotKnown
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_Total,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_Total_IsNotKnown,"rules":!_vm.isEditable?[]:_vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_Total_IsNotKnown
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_Total
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeVWD_Inhibitors_Total", _vm._n($$v))},expression:"\n                tempData.patientsHemophiliaTypeVWD_Inhibitors_Total\n              "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeVWDInhibitorsRow()}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_Total_IsNotKnown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeVWD_Inhibitors_Total_IsNotKnown", $$v)},expression:"\n                tempData.patientsHemophiliaTypeVWD_Inhibitors_Total_IsNotKnown\n              "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_NewCases_IsNotKnown
                  ? 'remove-underline-text-area'
                  : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_NewCases,"disabled":!_vm.isEditable ||
                _vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_NewCases_IsNotKnown,"rules":!_vm.isEditable?[]:_vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_NewCases_IsNotKnown
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_NewCases
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeVWD_Inhibitors_NewCases", _vm._n($$v))},expression:"\n                tempData.patientsHemophiliaTypeVWD_Inhibitors_NewCases\n              "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeVWDInhibitorsRow()}},model:{value:(
                _vm.tempData.patientsHemophiliaTypeVWD_Inhibitors_NewCases_IsNotKnown
              ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeVWD_Inhibitors_NewCases_IsNotKnown", $$v)},expression:"\n                tempData.patientsHemophiliaTypeVWD_Inhibitors_NewCases_IsNotKnown\n              "}})],1)])])]},proxy:true}])}),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }