
import Vue from 'vue'
import FormEditFooter from '../UI-Components/FormEditFooter.vue'
import { DataSourceSection } from '@/models/datasourcesection'
import { SaveRes } from '@/models/saveres'
import { Snackbar } from '@/models/snackbar'
import formrevalidate from '../../mixins/formrevalidate'

export default Vue.extend({
  name: 'DataSource',
  mixins: [formrevalidate],
  props: {
    isAllowedToEdit: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    isEditable: false,
    isFormValid: true,
    checkbox: true,
    show: false,
    tempData: {} as DataSourceSection,
    initData: {} as DataSourceSection,
    shallSkipDialog: false,
    reqErrors: [] as string[],
  }),
  computed: {
    isPrinting(){
      return this.$store.getters["datamanager/isPrinting"];
    },
    isSurveyYearModifiable() {
      return this.$store.getters['datamanager/getIsSurveyYearModifiable']
    },
    sectionsStatuses() {
      return this.$store.getters['datamanager/getSectionsStatuses']
    },
    dataSourceSection(): DataSourceSection {
      return this.$store.getters[
        'datamanager/getDataSourceSection'
      ] as DataSourceSection
    },
    countrySelected(): string {
      return this.$store.getters['datamanager/getCountrySelected'] as string
    },
    dataSourceSectionSaveRes() {
      return this.$store.getters['datamanager/getDataSourceSectionSaveRes']
    },
    hasUnsavedChanges: {
      get() {
        return this.$store.getters['datamanager/getHasUnsavedChanges']
      },
      set(value: boolean) {
        return this.$store.dispatch(
          'datamanager/changeHasUnsavedChanges',
          value
        )
      },
    },
  },
  methods: {
    async fetchSectionsStatuses() {
      await this.$store.dispatch('datamanager/fetchSectionsStatuses', {
        country: this.countrySelected,
      })
    },
    atLeastOneSelectedRule(
      question: string,
      var1: boolean,
      var2: boolean,
      var3: boolean,
      var4: boolean = false,
      var5: boolean = false,
      var6: boolean = false
    ) {
      if (this.isEditable) {
        if (!var1 && !var2 && !var3 && !var4 && !var5 && !var6) {
          if (!this.reqErrors.includes(question)) {
            this.reqErrors.push(question)
          }
          return [false]
        } else {
          let index = this.reqErrors.indexOf(question)
          if (index > -1) {
            this.reqErrors.splice(index, 1)
          }
          return []
        }
      }
    },
    triggerSnack(snack: Snackbar) {
      this.$store.dispatch('general/changeSnackbar', snack)
    },
    isTxtRequiredRule(cond: boolean, type: boolean = true) {
      return [
        (v: string) => {
          if ((v == null || v.length == 0) && cond === type) {
            return this.$t('rules.required')
          } else return true
        },
      ]
    },
    resetValidation() {
      this.reqErrors.splice(0)
      let refForm: any = this.$refs.form
      if (refForm != undefined) refForm.resetValidation()
    },
    saveit: function() {
      this.isEditable = false
      this.shallSkipDialog = false
      //Enlever le text des commentaires qui sont pas "afficher"
      if (!this.tempData.dataSource_IsOther) {
        this.tempData.dataSource_IsOther_Detail = ''
      }
      if (!this.tempData.databaseUpdateFrequency_IsOther) {
        this.tempData.databaseUpdateFrequency_IsOther_Detail = ''
      }
      if (!this.tempData.databaseUpdatedBy_IsOther) {
        this.tempData.databaseUpdatedBy_IsOther_Detail = ''
      }
      if (this.tempData.isAllIdentifiedPatientsIncluded) {
        this.tempData.isAllIdentifiedPatientsIncluded_Detail = ''
      }
      this.saveSurveyDataSourceSection()
        .then((e) => {
          if (e.status == 200) {
            let snack = {
              isSnacking: true,
              snackColor: 'success',
              snackMessage: this.$t('basic.savesuccess').toString(),
              snackTimeout: '2000',
            }
            this.triggerSnack(snack)
            this.fetchSectionsStatuses()
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let snack = {
              isSnacking: true,
              snackColor: 'orange',
              snackMessage:
                this.$t('basic.savewarning').toString() +
                err.response.statusText,
              snackTimeout: '2000',
            }
            this.triggerSnack(snack)
          } else {
            let snack = {
              isSnacking: true,
              snackColor: 'error',
              snackMessage:
                this.$t('basic.saveerror').toString() + err.response.statusText,
              snackTimeout: '2000',
            }
            this.triggerSnack(snack)
          }
          this.isEditable = true
        })
    },
    willSave() {
      this.tempData.dataSourceSectionIsComplete = true
      this.saveit()
    },
    willSaveAsDraft() {
      this.tempData.dataSourceSectionIsComplete = false
      this.saveit()
    },
    reloadTempData() {
      this.tempData = { ...this.initData }
      this.resetValidation()
    },
    async saveSurveyDataSourceSection() {
      await this.$store.dispatch('datamanager/saveSurveyDataSourceSection', {
        country: this.countrySelected,
        newData: this.tempData,
      })
      return this.dataSourceSectionSaveRes as SaveRes
    },
  },
  watch: {
    dataSourceSection: {
      handler(res) {
        this.initData = { ...res }
        if (res.dataSourceSectionIsComplete != null) {     
          this.isEditable = false
        } else {
          if (!this.sectionsStatuses.isApproved && this.isAllowedToEdit && this.isSurveyYearModifiable) {
            this.isEditable = true
          }
          this.shallSkipDialog = true
        }
        this.reloadTempData()
      },
      deep: true,
    },
    isSurveyYearModifiable: {
      handler(res) {
        if (res == false) {
          this.isEditable = false
        }
      },
      deep: true,
    },
    tempData: {
      handler(res) {
        if (JSON.stringify(res) === JSON.stringify(this.initData)) {
          this.hasUnsavedChanges = false
        } else if (this.hasUnsavedChanges == false) {
          this.hasUnsavedChanges = true
        }
      },

      deep: true,
    },
  },
  components: {
    'form-edit-footer': FormEditFooter,
  },
})
