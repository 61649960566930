
import Vue from "vue";
import Care from "../components/DataManager/Care.vue";
import CompletedBy from "../components/DataManager/CompletedBy.vue";
import DataSource from "../components/DataManager/DataSource.vue";
import FactorUse from "../components/DataManager/FactorUse.vue";
import Organization from "../components/DataManager/Organization.vue";
import Patients from "../components/DataManager/Patients.vue";
import Products from "../components/DataManager/Products.vue";
import Currency from "../components/DataManager/Currency.vue";
import Comment from "../components/DataManager/Comment.vue";
import ConfirmDialog from "../components/UI-Components/ConfirmDialog.vue";

import { Survey } from "@/models/survey";
import CurrencyVue from "@/components/DataManager/Currency.vue";
import { AuthRole } from "@/models/authrole";
import moment from "moment";
import i18n from "@/i18n";
import { SaveRes } from "@/models/saveres";
import { CountriesSection } from "@/models/countriessection";
import { Country } from "@/models/country";
import { SectionsStatuses } from "@/models/sectionsstatuses";

import DownloadBase from "../components/ExportPDF/DownloadBase.vue";

Vue.component("download-base", DownloadBase);

export default Vue.extend({
  name: "NewData",
  data: () => ({
    Care,
    CompletedBy,
    DataSource,
    FactorUse,
    Currency,
    Comment,
    Organization,
    Patients,
    Products,
    dowloadState: false,
    dowloadReady: false,
    pageList: [
      {
        label: "datamanager.organization.label",
        page: Organization,
        sectionName: "organization",
      },
      {
        label: "datamanager.completedby.label",
        page: CompletedBy,
        sectionName: "completedBy",
      },
      {
        label: "datamanager.datasource.label",
        page: DataSource,
        sectionName: "dataSource",
      },
      {
        label: "datamanager.patients.label",
        page: Patients,
        sectionName: "patient",
      },
      { label: "datamanager.care.label", page: Care, sectionName: "care" },
      {
        label: "datamanager.factoruse.label",
        page: FactorUse,
        sectionName: "factorUse",
      },
      {
        label: "datamanager.currency.label",
        page: CurrencyVue,
        sectionName: "currency",
      },
      {
        label: "datamanager.products.label",
        page: Products,
        sectionName: "products",
      },
      {
        label: "datamanager.comment.label",
        page: Comment,
        sectionName: "comment",
      },
    ],
    headers: [
      { text: i18n.t("basic.creationdate"), value: "creationDate" },
      { text: i18n.t("basic.message"), value: "message" },
    ],
    isContinueDialog: false,
    isDialog: false,
    dialogType: "tab",
    tempTabSelected: 0 as number,
    fileInput: null,
    file: {} as File,
    isConfirmed: false,
    isSnacking: false,
    snackColor: "info",
    snackMessage: "",
    snackTimeout: 1500,
    showSnackBtn: false,
    showCountryDetails: false,
    canUpload: false,
    importLogDialog: false,
    sortedCountrySelected: [] as CountriesSection[],
    unfinishedwarning: [] as any,
    isApprovalLoading: false,
  }),
  computed: {
    isAllowedToEditSurvey(): boolean {
      let isAllowed = false;
      let userRole = this.$store.getters["auth/getLoggedInUserRole"];
      let isSurveyYearModifiable = this.$store.getters[
        "datamanager/getIsSurveyYearModifiable"
      ];
      if (userRole == AuthRole.Administrator || userRole == AuthRole.WFH) {
        isAllowed = true;
      } else if (isSurveyYearModifiable && userRole == AuthRole.NMO) {
        isAllowed = true;
      } //Authrole DDC is never allowed.
      return isAllowed;
    },
    isAllowedToPrintSurvey(): boolean {
      let isAllowed = false;
      let userRole = this.$store.getters["auth/getLoggedInUserRole"];
      //Commented following client request
      // let isSurveyYearModifiable = this.$store.getters[
      //   "datamanager/getIsSurveyYearModifiable"
      // ];
      if (userRole == AuthRole.Administrator || userRole == AuthRole.WFH || userRole == AuthRole.NMO) {
        isAllowed = true;
      } 
      // else if (!isSurveyYearModifiable && userRole == AuthRole.NMO) {
      //   isAllowed = true;
      // } 
      //Authrole DDC is never allowed.
      return isAllowed;
    },
    legend() {
      return [
        {
          label: i18n.t("datamanager.newdataentry.tabtooltip.submitted"),
          color: "success",
        },
        {
          label: i18n.t("datamanager.newdataentry.tabtooltip.draft"),
          color: "warning",
        },
        {
          label: i18n.t("datamanager.newdataentry.tabtooltip.error"),
          color: "error",
        },
        {
          label: i18n.t("datamanager.newdataentry.tabtooltip.empty"),
          color: "gray",
        },
      ];
    },
    lang() {
      return i18n.locale;
    },
    surveys() {
      return this.$store.getters["datamanager/getSurveys"];
    },
    isSurveyYearModifiable() {
      return this.$store.getters["datamanager/getIsSurveyYearModifiable"];
    },
    currentSurvey: {
      get() {
        return this.$store.getters["datamanager/getCurrentSurvey"] as Survey;
      },
      set(survey) {
        return this.$store.dispatch("datamanager/changeCurrentSurvey", survey);
      },
    },
    countries() {
      return this.$store.getters["datamanager/getCountries"];
    },
    sectionsStatuses() {
      return this.$store.getters["datamanager/getSectionsStatuses"];
    },
    fetchCountryByIdResult() {
      return this.$store.getters["datamanager/getFetchCountryByIdResult"];
    },
    countrySelected: {
      get() {
        return this.$store.getters["datamanager/getCountrySelected"] as Country;
      },
      set(value) {
        return this.$store.dispatch("datamanager/changeCountrySelected", value);
      },
    },
    tabSelected: {
      get() {
        return this.$store.getters["datamanager/getTabSelected"] as number;
      },
      set(value) {
        return this.$store.dispatch("datamanager/changeTabSelected", value);
      },
    },
    hasUnsavedChanges: {
      get() {
        return this.$store.getters["datamanager/getHasUnsavedChanges"];
      },
      set(value: boolean) {
        return this.$store.dispatch(
          "datamanager/changeHasUnsavedChanges",
          value
        );
      },
    },
    loggedInUserRole() {
      return this.$store.getters["auth/getLoggedInUserRole"];
    },
    loggedInUserCountryId() {
      return this.$store.getters["auth/getLoggedInUserCountryId"];
    },
    importLog() {
      return this.$store.getters["datamanager/getImportLog"];
    },
  },
  filters: {
    moment: function(date: string) {
      return moment.utc(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },
  methods: {
    sortCountrySelected() {
      let countries = [...this.$store.getters["datamanager/getCountries"]];
      this.sortedCountrySelected = [];
      if (countries.length > 0) {
        if (i18n.locale == "fr") {
          this.sortedCountrySelected = this.sortCountries(countries, "fr");
        } else if (i18n.locale == "es") {
          this.sortedCountrySelected = this.sortCountries(countries, "es");
        } else {
          this.sortedCountrySelected = this.sortCountries(countries, "en");
        }
      }
    },
    sortCountries(countries: CountriesSection[], tong: string) {
      if (tong == "fr") {
        return countries.sort((a: CountriesSection, b: CountriesSection) => {
          let fa = a.name_fr.toLowerCase(),
            fb = b.name_fr.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else if (tong == "es") {
        return countries.sort((a: CountriesSection, b: CountriesSection) => {
          let fa = a.name_es.toLowerCase(),
            fb = b.name_es.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else {
        return countries.sort((a: CountriesSection, b: CountriesSection) => {
          let fa = a.name_en.toLowerCase(),
            fb = b.name_en.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      }
    },
    isAllowToChangeApproval() {
      return (
        this.loggedInUserRole == AuthRole.Administrator ||
        this.loggedInUserRole == AuthRole.WFH
      );
    },
    isAllowedToUpload() {
      return (
        this.loggedInUserRole == AuthRole.Administrator ||
        this.loggedInUserRole == AuthRole.NMO ||
        this.loggedInUserRole == AuthRole.WFH
      );
    },
    isNMO() {
      return this.loggedInUserRole == AuthRole.NMO;
    },
    flexProp(type: string) {
      if (type == "tab") {
        return {
          title: "confirmdialogunsavedchanges.title",
          text: "confirmdialogunsavedchanges.text",
          btnLeftLabel: "confirmdialogunsavedchanges.btnleftlabel",
          btnRightLabel: "confirmdialogunsavedchanges.btnrightlabel",
        };
      } else if (type == "file") {
        return {
          title: "confirmdialog.titleupload",
          text: "confirmdialog.textupload",
        };
      } else if (type == "approval") {
        return {
          title: "confirmdialog.titleapproval",
          text: "confirmdialog.textapproval",
          multilineText: this.unfinishedwarning,
        };
      } else if (type == "revokeapproval") {
        return {
          title: "confirmdialog.titlerevokeapproval",
          text: "confirmdialog.textrevokeapproval",
        };
      }
    },
    wantsToChangeTab(tab: number) {
      this.tempTabSelected = tab;
      if (this.hasUnsavedChanges) {
        this.isContinueDialog = true;
        this.dialogType = "tab";
        this.isDialog = true;
      } else {
        this.tabSelected = tab;
      }
    },
    wantsToChangeApproval(isApproved: boolean) {
      this.isApprovalLoading = true;
      if (isApproved) {
        let newUnfinishedWarning = [];
        if (!this.sectionsStatuses.careSectionIsComplete) {
          let care =
            "- " +
            i18n.t("datamanager.care.label") +
            ": " +
            i18n.t("confirmdialog.incompletesectionapproval");
          if (this.sectionsStatuses.careSectionErrorCount) {
            care +=
              " " +
              i18n.t("confirmdialog.incsectionapprovalandhas") +
              " " +
              this.sectionsStatuses.careSectionErrorCount +
              " " +
              i18n.t("confirmdialog.incsectionapprovalerror");
          }
          care += "\r";
          newUnfinishedWarning.push(care);
        }
        if (!this.sectionsStatuses.completedBySectionIsComplete) {
          let cb =
            "- " +
            i18n.t("datamanager.completedby.label") +
            ": " +
            i18n.t("confirmdialog.incompletesectionapproval");
          if (this.sectionsStatuses.completedBySectionErrorCount) {
            cb +=
              " " +
              i18n.t("confirmdialog.incsectionapprovalandhas") +
              " " +
              this.sectionsStatuses.completedBySectionErrorCount +
              " " +
              i18n.t("confirmdialog.incsectionapprovalerror");
          }
          cb += "\r";
          newUnfinishedWarning.push(cb);
        }
        if (!this.sectionsStatuses.currencySectionIsComplete) {
          let currency =
            "- " +
            i18n.t("datamanager.currency.label") +
            ": " +
            i18n.t("confirmdialog.incompletesectionapproval");
          if (this.sectionsStatuses.currencySectionErrorCount) {
            currency +=
              " " +
              i18n.t("confirmdialog.incsectionapprovalandhas") +
              " " +
              this.sectionsStatuses.currencySectionErrorCount +
              " " +
              i18n.t("confirmdialog.incsectionapprovalerror");
          }
          currency += "\r";
          newUnfinishedWarning.push(currency);
        }
        if (!this.sectionsStatuses.dataSourceSectionIsComplete) {
          let ds =
            "- " +
            i18n.t("datamanager.datasource.label") +
            ": " +
            i18n.t("confirmdialog.incompletesectionapproval");
          if (this.sectionsStatuses.dataSourceSectionErrorCount) {
            ds +=
              " " +
              i18n.t("confirmdialog.incsectionapprovalandhas") +
              " " +
              this.sectionsStatuses.dataSourceSectionErrorCount +
              " " +
              i18n.t("confirmdialog.incsectionapprovalerror");
          }
          ds += "\r";
          newUnfinishedWarning.push(ds);
        }
        if (!this.sectionsStatuses.factorUseSectionIsComplete) {
          let fu =
            "- " +
            i18n.t("datamanager.factoruse.label") +
            ": " +
            i18n.t("confirmdialog.incompletesectionapproval");
          if (this.sectionsStatuses.factorUseSectionErrorCount) {
            fu +=
              " " +
              i18n.t("confirmdialog.incsectionapprovalandhas") +
              " " +
              this.sectionsStatuses.factorUseSectionErrorCount +
              " " +
              i18n.t("confirmdialog.incsectionapprovalerror");
          }
          fu += "\r";
          newUnfinishedWarning.push(fu);
        }
        if (!this.sectionsStatuses.organizationSectionIsComplete) {
          let org =
            "- " +
            i18n.t("datamanager.organization.label") +
            ": " +
            i18n.t("confirmdialog.incompletesectionapproval");
          if (this.sectionsStatuses.organizationSectionErrorCount) {
            org +=
              " " +
              i18n.t("confirmdialog.incsectionapprovalandhas") +
              " " +
              this.sectionsStatuses.organizationSectionErrorCount +
              " " +
              i18n.t("confirmdialog.incsectionapprovalerror");
          }
          org += "\r";
          newUnfinishedWarning.push(org);
        }
        if (!this.sectionsStatuses.patientSectionIsComplete) {
          let patient =
            "- " +
            i18n.t("datamanager.patients.label") +
            ": " +
            i18n.t("confirmdialog.incompletesectionapproval");
          if (this.sectionsStatuses.patientSectionErrorCount) {
            patient +=
              " " +
              i18n.t("confirmdialog.incsectionapprovalandhas") +
              " " +
              this.sectionsStatuses.patientSectionErrorCount +
              " " +
              i18n.t("confirmdialog.incsectionapprovalerror");
          }
          patient += "\r";
          newUnfinishedWarning.push(patient);
        }
        this.unfinishedwarning = newUnfinishedWarning;
        this.isContinueDialog = true;
        this.dialogType = "approval";
        this.isDialog = true;
      } else {
        this.isContinueDialog = true;
        this.dialogType = "revokeapproval";
        this.isDialog = true;
      }
      this.isApprovalLoading = false;
    },
    async changeSurveyApproval(country: Country, isApproved: Boolean) {
      await this.$store.dispatch("datamanager/changeSurveyApproval", {
        countryId: country.id,
        isApproved: isApproved,
      });
      await this.$store.dispatch("datamanager/fetchSectionsStatuses", {
        country: this.countrySelected,
      });
    },
    async fetchCountries() {
      await this.$store.dispatch("datamanager/fetchCountries");
    },
    async fetchCountryById(loggedInUserid: string) {
      await this.$store.dispatch("datamanager/fetchCountryById", {
        id: loggedInUserid,
      });
      return this.fetchCountryByIdResult as SaveRes;
    },
    async fetchCurrentSurvey() {
      await this.$store.dispatch("datamanager/fetchCurrentSurvey", {
        beforeCurrentDate: true,
      });
    },
    async fetchSurveys() {
      await this.$store.dispatch("datamanager/fetchSurveys", {
        beforeCurrentDate: true,
      });
    },
    async fetchImportLog() {
      await this.$store.dispatch("datamanager/fetchImportLog", {
        country: this.countrySelected,
      });
    },
    async fetchSectionsStatuses() {
      await this.$store.dispatch("datamanager/fetchSectionsStatuses", {
        country: this.countrySelected,
      });
    },
    async reloadTabSelectedData() {
      if (this.countrySelected && this.currentSurvey) {
        switch (this.tabSelected) {
          //Organization
          case 0:
            await this.$store.dispatch(
              "datamanager/fetchSurveyOrganizationSection",
              {
                country: this.countrySelected,
              }
            );
            break;
          //CompletedBy
          case 1:
            await this.$store.dispatch(
              "datamanager/fetchSurveyCompletedBySection",
              {
                country: this.countrySelected,
              }
            );
            break;
          //DataSource
          case 2:
            await this.$store.dispatch(
              "datamanager/fetchSurveyDataSourceSection",
              {
                country: this.countrySelected,
              }
            );
            break;
          //Patients
          case 3:
            await this.$store.dispatch(
              "datamanager/fetchSurveyPatientSection",
              {
                country: this.countrySelected,
              }
            );
            break;
          //Care
          case 4:
            await this.$store.dispatch("datamanager/fetchSurveyCareSection", {
              country: this.countrySelected,
            });
            break;
          //Factor Use
          case 5:
            await this.$store.dispatch(
              "datamanager/fetchSurveyFactorUseSection",
              {
                country: this.countrySelected,
              }
            );
            break;
          //Currency
          case 6:
            await this.$store.dispatch(
              "datamanager/fetchSurveyCurrencySection",
              {
                country: this.countrySelected,
              }
            );
            break;
          //Products
          case 7:
            await this.$store.dispatch(
              "datamanager/fetchSurveyProductSection",
              {
                country: this.countrySelected,
              }
            );
            await this.$store.dispatch("datamanager/fetchManufacturers");
            await this.$store.dispatch("datamanager/fetchProductTypes");
            break;
          //Comment
          case 8:
            await this.$store.dispatch("datamanager/fetchCommentSection", {
              country: this.countrySelected,
            });
            break;
        }
      }
    },
    setUploadBtn() {
      this.file = this.fileInput ?? ({} as File);
      if (this.file.size > 0) this.canUpload = true;
      else this.canUpload = false;
    },
    wantsToUploadFile() {
      if (this.file.size > 0) {
        this.dialogType = "file";
        this.isDialog = true;
      }
    },
    openImportLog() {
      this.importLogDialog = true;
    },
    async uploadFile(): Promise<any> {
      if (this.file.size > 0) {
        let formData = new FormData();
        formData.append("file", this.file, this.file.name);
        return await this.$store
          .dispatch("datamanager/uploadSurveyDocument", {
            country: this.countrySelected,
            formData: formData,
          })
          .then(() => {
            this.snackColor = "success";
            this.snackMessage = this.$t("basic.uploadsuccess").toString();
            this.snackTimeout = 1500;
            this.showSnackBtn = false;
            this.isSnacking = true;
            this.fetchImportLog();
            this.reloadPageData();
          })
          .catch((err) => {
            var errorMessage = err.response.statusText;
            if (err.response?.data?.errors?.file != undefined)
              errorMessage = err.response.data.errors.file;

            this.snackColor = "error";
            this.snackMessage =
              this.$t("basic.uploaderror").toString() + errorMessage;
            this.snackTimeout = -1;
            this.showSnackBtn = true;
            this.isSnacking = true;
          });
      } else {
        console.log("No file selected!");
      }
    },

    hasConfirmed(isConfirmed: boolean, type: string = "tab") {
      if (type == "tab") {
        this.isContinueDialog = false;
        this.isDialog = false;
        if (isConfirmed) {
          this.hasUnsavedChanges = false;
          this.tabSelected = this.tempTabSelected;
        } else {
          this.tempTabSelected = this.tabSelected as number;
        }
      } else if (type == "file") {
        if (isConfirmed) {
          this.uploadFile();
        }
        this.isDialog = false;
      } else if (type == "approval") {
        if (isConfirmed) {
          this.changeSurveyApproval(this.countrySelected as Country, true);
        }
        this.isDialog = false;
      } else if (type == "revokeapproval") {
        if (isConfirmed) {
          this.changeSurveyApproval(this.countrySelected as Country, false);
        }
        this.isDialog = false;
      }
    },
    reloadPageData() {
      this.fileInput = null;
      this.canUpload = false;
      this.reloadTabSelectedData();
      if (
        this.countrySelected &&
        this.currentSurvey !== "" &&
        this.isAllowedToUpload()
      )
        this.fetchImportLog();

      if (this.countrySelected && this.currentSurvey !== "")
        this.fetchSectionsStatuses();
    },
    GetSectionStatusColor(sectionName: string) {
      const sectionIsComplete = this.sectionsStatuses[
        (sectionName + "SectionIsComplete") as keyof SectionsStatuses
      ];
      const sectionErrorCount = this.sectionsStatuses[
        (sectionName + "SectionErrorCount") as keyof SectionsStatuses
      ];
      if (sectionIsComplete === undefined) return "";
      if (sectionIsComplete === null) return "grey";
      if (sectionErrorCount != undefined && sectionErrorCount > 0)
        return "error";
      if (!sectionIsComplete) return "warning";
      if (sectionIsComplete) return "success";
      return "";
    },
    GetSectionStatusTooltip(sectionName: string) {
      const sectionIsComplete = this.sectionsStatuses[
        (sectionName + "SectionIsComplete") as keyof SectionsStatuses
      ];
      const sectionErrorCount = this.sectionsStatuses[
        (sectionName + "SectionErrorCount") as keyof SectionsStatuses
      ];
      if (sectionIsComplete === undefined) return "";
      if (sectionIsComplete === null)
        return i18n.t("datamanager.newdataentry.tabtooltip.empty");
      if (sectionErrorCount != undefined && sectionErrorCount > 0)
        return i18n.t("datamanager.newdataentry.tabtooltip.error");
      if (!sectionIsComplete)
        return i18n.t("datamanager.newdataentry.tabtooltip.draft");
      if (sectionIsComplete)
        return i18n.t("datamanager.newdataentry.tabtooltip.submitted");
      return "";
    },
    ShowSectionStatusTooltip(sectionName: string) {
      const sectionIsComplete = this.sectionsStatuses[
        (sectionName + "SectionIsComplete") as keyof SectionsStatuses
      ];
      if (sectionIsComplete === undefined) return true;
      else return false;
    },
  },
  watch: {
    async countrySelected() {
      // handler: "reloadPageData",
      // It is necessary load all data here to can print PDF
      this.dowloadReady = false;
      await this.$store.dispatch("datamanager/fetchSurveyOrganizationSection", {
        country: this.countrySelected,
      });
      await this.$store.dispatch("datamanager/fetchSurveyCompletedBySection", {
        country: this.countrySelected,
      });
      await this.$store.dispatch("datamanager/fetchSurveyDataSourceSection", {
        country: this.countrySelected,
      });
      await this.$store.dispatch("datamanager/fetchSurveyPatientSection", {
        country: this.countrySelected,
      });
      await this.$store.dispatch("datamanager/fetchSurveyCareSection", {
        country: this.countrySelected,
      });
      await this.$store.dispatch("datamanager/fetchSurveyFactorUseSection", {
        country: this.countrySelected,
      });
      await this.$store.dispatch("datamanager/fetchSurveyCurrencySection", {
        country: this.countrySelected,
      });
      // Products
      await this.$store.dispatch("datamanager/fetchSurveyProductSection", {
        country: this.countrySelected,
      });
      await this.$store.dispatch("datamanager/fetchManufacturers");
      await this.$store.dispatch("datamanager/fetchProductTypes");
      await this.$store.dispatch("datamanager/fetchCommentSection", {
        country: this.countrySelected,
      });

      await this.$store.dispatch("datamanager/fetchCommentSection", {
        country: this.countrySelected,
      });
      this.fetchSectionsStatuses();
      this.dowloadReady = true;
    },
    tabSelected: {
      handler: "reloadTabSelectedData",
    },
    lang: {
      handler: "sortCountrySelected",
    },
    countries: {
      handler: "sortCountrySelected",
    },
  },
  created() {
    this.fetchCountries().then(() => {
      this.sortCountrySelected();
      if (this.isNMO())
        this.countrySelected = this.sortedCountrySelected.find(
          (x) => x.id == this.loggedInUserCountryId
        );
      else this.reloadPageData();
    });
    if (!this.currentSurvey) this.fetchCurrentSurvey();
    this.fetchSurveys();
    this.tabSelected = 0;
    if (
      this.countrySelected &&
      this.currentSurvey !== "" &&
      this.isAllowedToUpload()
    )
      this.fetchImportLog();
  },
  components: {
    "confirm-dialog": ConfirmDialog,
  },
});
