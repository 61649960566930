var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6a_titleFrom2022")))]),_c('v-alert',{staticClass:"caption ma-1",attrs:{"text":"","dense":"","color":"teal","border":"left"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionseverity.severity_rule1"))+" ")]),_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.severity_rule2" ))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.severity_rule3" ))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.severity_rule4" ))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.severity_rule5From2022" ))+" ")])])])],1)]),_c('tr',[_c('td',{staticClass:"width-25p"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_typeofhemophilia")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_mild")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_moderate")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_severe")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_severityunknown")))])]),_c('td',[_c('b',{class:_vm.totalNotSumMaleErrors.length > 0 ? 'errorred' : ''},[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_totalexpected")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_nodata")))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.q6_hemophiliaamaleFrom2022" ))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Severity_Mild_Male,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_Mild_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_Mild_Male", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_Mild_Male\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Severity_Moderate_Male,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_Moderate_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_Moderate_Male", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_Moderate_Male\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Severity_Severe_Male,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_Severe_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_Severe_Male", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_Severe_Male\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Severity_Unknown_Male,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_Unknown_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_Unknown_Male", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_Unknown_Male\n                "}})],1),_c('td',[_c('v-text-field',{class:_vm.totalNotSumMaleErrors.includes('HAM')
                    ? 'errorred remove-underline-text-area'
                    : 'remove-underline-text-area',staticStyle:{"min-width":"130px"},attrs:{"outlined":false,"v-model":_vm.sumOfHAM,"disabled":true,"value":_vm.sumOfHAM +
                    ' / ' +
                    (_vm.tempData.patientsHemophiliaTypeA_Gender_Male || 0),"rules":_vm.ruleTotalSum('HAM')}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeASeverityMaleRow()}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_HasNoData_Male", $$v)},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_HasNoData_Male\n                "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.q6_hemophiliabmaleFrom2022" ))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Severity_Mild_Male,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_Mild_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_Mild_Male", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_Mild_Male\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Severity_Moderate_Male,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_Moderate_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_Moderate_Male", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_Moderate_Male\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Severity_Severe_Male,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_Severe_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_Severe_Male", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_Severe_Male\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Severity_Unknown_Male,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_Unknown_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_Unknown_Male", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_Unknown_Male\n                "}})],1),_c('td',[_c('v-text-field',{class:_vm.totalNotSumMaleErrors.includes('HBM')
                    ? 'errorred remove-underline-text-area'
                    : 'remove-underline-text-area',attrs:{"outlined":false,"disabled":true,"v-model":_vm.sumOfHBM,"value":_vm.sumOfHBM +
                    ' / ' +
                    (_vm.tempData.patientsHemophiliaTypeB_Gender_Male || 0),"rules":_vm.ruleTotalSum('HBM')}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeBSeverityMaleRow()}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_HasNoData_Male", $$v)},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_HasNoData_Male\n                "}})],1)]),_c('tr')])]},proxy:true}])}),_c('br'),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"8"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6b_titleFrom2022")))])])]),_c('tr',[_c('td',{staticClass:"width-25p"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_typeofhemophilia")))])]),_c('td',{staticClass:"border-right border-left"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_carrierFrom2022")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t( "datamanager.patients.sectionseverity.q6_mildFemaleFrom2022" )))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_moderate")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_severe")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_severityunknown")))])]),_c('td',[_c('b',{class:_vm.totalNotSumFemaleErrors.length > 0 ? 'errorred' : ''},[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_totalexpected")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_nodata")))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.q6_hemophiliaafemaleFrom2022" ))+" ")]),_c('td',{staticClass:"border-right border-left"},[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Severity_Carrier_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_Carrier_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_Carrier_Female", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_Carrier_Female\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Severity_Mild_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_Mild_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_Mild_Female", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_Mild_Female\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Severity_Moderate_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_Moderate_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_Moderate_Female", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_Moderate_Female\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Severity_Severe_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_Severe_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_Severe_Female", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_Severe_Female\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeA_Severity_Unknown_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_Unknown_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_Unknown_Female", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_Unknown_Female\n                "}})],1),_c('td',[_c('v-text-field',{class:_vm.totalNotSumFemaleErrors.includes('HAF')
                    ? 'errorred remove-underline-text-area'
                    : 'remove-underline-text-area',staticStyle:{"min-width":"130px"},attrs:{"outlined":false,"v-model":_vm.sumOfHAF,"disabled":true,"value":_vm.sumOfHAF +
                    ' / ' +
                    (_vm.tempData.patientsHemophiliaTypeA_Gender_Female || 0),"rules":_vm.ruleTotalSum('HAF')}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeASeverityFemaleRow()}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeA_Severity_HasNoData_Female", $$v)},expression:"\n                  tempData.patientsHemophiliaTypeA_Severity_HasNoData_Female\n                "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.q6_hemophiliabfemaleFrom2022" ))+" ")]),_c('td',{staticClass:"border-right border-left"},[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Severity_Carrier_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_Carrier_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_Carrier_Female", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_Carrier_Female\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Severity_Mild_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_Mild_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_Mild_Female", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_Mild_Female\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Severity_Moderate_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_Moderate_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_Moderate_Female", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_Moderate_Female\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Severity_Severe_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_Severe_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_Severe_Female", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_Severe_Female\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsHemophiliaTypeB_Severity_Unknown_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_Unknown_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_Unknown_Female", _vm._n($$v))},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_Unknown_Female\n                "}})],1),_c('td',[_c('v-text-field',{class:_vm.totalNotSumFemaleErrors.includes('HBF')
                    ? 'errorred remove-underline-text-area'
                    : 'remove-underline-text-area',staticStyle:{"min-width":"130px"},attrs:{"outlined":false,"disabled":true,"v-model":_vm.sumOfHBF,"value":_vm.sumOfHBF +
                    ' / ' +
                    (_vm.tempData.patientsHemophiliaTypeB_Gender_Female || 0),"rules":_vm.ruleTotalSum('HBF')}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHemophiliaTypeBSeverityFemaleRow()}},model:{value:(
                  _vm.tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsHemophiliaTypeB_Severity_HasNoData_Female", $$v)},expression:"\n                  tempData.patientsHemophiliaTypeB_Severity_HasNoData_Female\n                "}})],1)]),_c('tr',[_c('td',{class:_vm.resultGrey ? 'grey--text' : '',attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.q_considernumaccurate" ))+" ")]),_c('td',{attrs:{"colspan":"2"}},[(!_vm.resultGrey)?_c('v-radio-group',{attrs:{"hide-details":"auto","row":"","disabled":!_vm.isEditable,"rules":!_vm.isEditable
                    ? []
                    : [(v) => v != null || _vm.$t('rules.plsselect')],"required":""},model:{value:(_vm.tempData.severityHemophiliaIsInformationAccurate),callback:function ($$v) {_vm.$set(_vm.tempData, "severityHemophiliaIsInformationAccurate", $$v)},expression:"tempData.severityHemophiliaIsInformationAccurate"}},[_c('v-radio',{attrs:{"name":"q6_radioGrp","label":_vm.$t('basic.yes'),"value":true}}),_c('v-radio',{attrs:{"name":"q6_radioGrp","label":_vm.$t('basic.no'),"value":false}})],1):_vm._e()],1),_c('td',{attrs:{"colspan":"4"}})]),_c('tr',[_c('td',{attrs:{"colspan":"8"}},[_c('v-alert',{staticClass:"caption ma-1",attrs:{"text":"","dense":"","color":"teal","border":"left"}},[_c('ul',[_c('li',{class:_vm.totalNotSumMaleErrors.includes('HAM') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_rule1"))+" ")]),_c('li',{class:_vm.totalNotSumFemaleErrors.includes('HAF')
                        ? 'errorred'
                        : ''},[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.q6_rule2From2022" ))+" ")]),_c('li',{class:_vm.totalNotSumMaleErrors.includes('HBM') ? 'errorred' : ''},[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectionseverity.q6_rule3"))+" ")]),_c('li',{class:_vm.totalNotSumFemaleErrors.includes('HBF')
                        ? 'errorred'
                        : ''},[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.q6_rule4From2022" ))+" ")])])])],1)])])]},proxy:true}])}),_c('v-divider')],1),_c('v-container',[_c('div',{staticClass:"html2pdf__page-break"}),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q7_title")))])])]),_c('tr',[_c('td'),_c('td',[_c('b',{class:_vm.isTotalNotSumErrors.includes('q7_total') ? 'errorred' : ''},[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q7_total")))])]),_c('td',{staticClass:"centered_td pr-0 q7-nodata"},[_c('b',{staticClass:"severity-ckbox"},[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q7_nodata")))])]),_c('td',[_c('b',{class:_vm.isTotalNotSumErrors.includes('q7_replacementtherapytotal')
                    ? 'errorred'
                    : ''},[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.q7_replacementtherapytotal" ))+" ")])]),_c('td',{staticClass:"centered_td pr-0 q7-nodata"},[_c('b',{staticClass:"severity-ckbox"},[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectionseverity.q7_nodata")))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.total"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWD_Severe_Total_IsNotKnown
                    ? 'remove-underline-text-area text-center'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Severe_Total,"disabled":!_vm.isEditable || _vm.tempData.patientsVWD_Severe_Total_IsNotKnown,"rules":!_vm.isEditable || _vm.tempData.patientsVWD_Severe_Total_IsNotKnown
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWD_Severe_Total,
                        _vm.tempData.patientsVWD_Severe_Male
                          ? _vm.tempData.patientsVWD_Severe_Male
                          : 0,
                        _vm.tempData.patientsVWD_Severe_IsNotKnown_Male,
                        _vm.tempData.patientsVWD_Severe_Female
                          ? _vm.tempData.patientsVWD_Severe_Female
                          : 0,
                        _vm.tempData.patientsVWD_Severe_IsNotKnown_Female,
                        _vm.tempData.patientsVWD_Severe_Unknown
                          ? _vm.tempData.patientsVWD_Severe_Unknown
                          : 0,
                        _vm.tempData.patientsVWD_Severe_IsNotKnown_Unknown,
                        'q7_total',
                        _vm.tempData.patientsVWD_Severe_Total_IsNotKnown
                      )},model:{value:(_vm.tempData.patientsVWD_Severe_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_Total", _vm._n($$v))},expression:"tempData.patientsVWD_Severe_Total"}})],1),_c('td',{staticClass:"centered_td pr-0 q7-nodata"},[_c('v-checkbox',{staticClass:"severity-ckbox",attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDSevereTotalRow(1)}},model:{value:(_vm.tempData.patientsVWD_Severe_Total_IsNotKnown),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_Total_IsNotKnown", $$v)},expression:"tempData.patientsVWD_Severe_Total_IsNotKnown"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total_IsNotKnown
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total_IsNotKnown,"rules":!_vm.isEditable ||
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total_IsNotKnown
                    ? []
                    : _vm.ruleTotalIsSum(
                        _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total,
                        _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Male
                          ? _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Male
                          : 0,
                        _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Male,
                        _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Female
                          ? _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Female
                          : 0,
                        _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Female,
                        _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Unknown
                          ? _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Unknown
                          : 0,
                        _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Unknown,
                        'q7_replacementtherapytotal',
                        _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total_IsNotKnown
                      )},model:{value:(
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_ReceivingReplacementTherapy_Total", _vm._n($$v))},expression:"\n                  tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total\n                "}})],1),_c('td',{staticClass:"centered_td pr-0 q7-nodata"},[_c('v-checkbox',{staticClass:"severity-ckbox",attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDSevereTotalRow(2)}},model:{value:(
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total_IsNotKnown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_ReceivingReplacementTherapy_Total_IsNotKnown", $$v)},expression:"\n                  tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Total_IsNotKnown\n                "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.male"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWD_Severe_IsNotKnown_Male
                    ? 'remove-underline-text-area text-center'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Severe_Male,"disabled":!_vm.isEditable || _vm.tempData.patientsVWD_Severe_IsNotKnown_Male,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWD_Severe_IsNotKnown_Male
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},model:{value:(_vm.tempData.patientsVWD_Severe_Male),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_Male", _vm._n($$v))},expression:"tempData.patientsVWD_Severe_Male"}})],1),_c('td',{staticClass:"centered_td pr-0 q7-nodata"},[_c('v-checkbox',{staticClass:"severity-ckbox",attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDSevereMaleRow(1)}},model:{value:(_vm.tempData.patientsVWD_Severe_IsNotKnown_Male),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_IsNotKnown_Male", $$v)},expression:"tempData.patientsVWD_Severe_IsNotKnown_Male"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Male
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Male,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Male,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Male
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},model:{value:(
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_ReceivingReplacementTherapy_Male", _vm._n($$v))},expression:"\n                  tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Male\n                "}})],1),_c('td',{staticClass:"centered_td pr-0 q7-nodata"},[_c('v-checkbox',{staticClass:"severity-ckbox",attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDSevereMaleRow(2)}},model:{value:(
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Male
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Male", $$v)},expression:"\n                  tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Male\n                "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.female"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.patientsVWD_Severe_IsNotKnown_Female
                    ? 'remove-underline-text-area text-center'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Severe_Female,"disabled":!_vm.isEditable || _vm.tempData.patientsVWD_Severe_IsNotKnown_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWD_Severe_IsNotKnown_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},model:{value:(_vm.tempData.patientsVWD_Severe_Female),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_Female", _vm._n($$v))},expression:"tempData.patientsVWD_Severe_Female"}})],1),_c('td',{staticClass:"centered_td pr-0 q7-nodata"},[_c('v-checkbox',{staticClass:"severity-ckbox",attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDSevereFemaleRow(1)}},model:{value:(_vm.tempData.patientsVWD_Severe_IsNotKnown_Female),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_IsNotKnown_Female", $$v)},expression:"tempData.patientsVWD_Severe_IsNotKnown_Female"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Female
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Female,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Female,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Female
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},model:{value:(
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_ReceivingReplacementTherapy_Female", _vm._n($$v))},expression:"\n                  tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Female\n                "}})],1),_c('td',{staticClass:"centered_td pr-0 q7-nodata"},[_c('v-checkbox',{staticClass:"severity-ckbox",attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDSevereFemaleRow(2)}},model:{value:(
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Female
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Female", $$v)},expression:"\n                  tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Female\n                "}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.unknown"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWD_Severe_IsNotKnown_Unknown
                    ? 'remove-underline-text-area text-center'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Severe_Unknown,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWD_Severe_IsNotKnown_Unknown,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWD_Severe_IsNotKnown_Unknown
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},model:{value:(_vm.tempData.patientsVWD_Severe_Unknown),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_Unknown", _vm._n($$v))},expression:"tempData.patientsVWD_Severe_Unknown"}})],1),_c('td',{staticClass:"centered_td pr-0 q7-nodata"},[_c('v-checkbox',{staticClass:"severity-ckbox",attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDSevereUnknownRow(1)}},model:{value:(_vm.tempData.patientsVWD_Severe_IsNotKnown_Unknown),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_IsNotKnown_Unknown", $$v)},expression:"tempData.patientsVWD_Severe_IsNotKnown_Unknown"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable ||
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Unknown
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Unknown,"disabled":!_vm.isEditable ||
                    _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Unknown,"rules":!_vm.isEditable
                    ? []
                    : _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Unknown
                    ? [_vm.rules.mustBeNull]
                    : [_vm.rules.requis]},model:{value:(
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Unknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_ReceivingReplacementTherapy_Unknown", _vm._n($$v))},expression:"\n                  tempData.patientsVWD_Severe_ReceivingReplacementTherapy_Unknown\n                "}})],1),_c('td',{staticClass:"centered_td pr-0 q7-nodata"},[_c('v-checkbox',{staticClass:"severity-ckbox",attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearVWDSevereUnknownRow(2)}},model:{value:(
                  _vm.tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Unknown
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Unknown", $$v)},expression:"\n                  tempData.patientsVWD_Severe_ReceivingReplacementTherapy_IsNotKnown_Unknown\n                "}})],1)]),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectionseverity.q_considernumaccurate" ))+" ")]),_c('td',{attrs:{"colspan":"5"}},[_c('v-radio-group',{attrs:{"hide-details":"auto","row":"","disabled":!_vm.isEditable,"rules":!_vm.isEditable
                    ? []
                    : [(v) => v != null || _vm.$t('rules.plsselect')],"required":""},model:{value:(_vm.tempData.severityVWDIsInformationAccurate),callback:function ($$v) {_vm.$set(_vm.tempData, "severityVWDIsInformationAccurate", $$v)},expression:"tempData.severityVWDIsInformationAccurate"}},[_c('v-radio',{attrs:{"name":"q7_radioGrp","label":_vm.$t('basic.yes'),"value":true}}),_c('v-radio',{attrs:{"name":"q7_radioGrp","label":_vm.$t('basic.no'),"value":false}})],1)],1)])])]},proxy:true}])}),_c('v-divider')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }