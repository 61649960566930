
import Vue from "vue";

export default Vue.extend({
  name: "SectionDeath",
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
    },
  },
  methods: {
    clearDeathBleedingRow() {
      if (this.tempData.death_Bleeding_HasNoData) {
        this.tempData.death_Bleeding_PatientsHemophilia_Total = null;
        this.tempData.death_Bleeding_PatientsVWD_Total = null;
        this.tempData.death_Bleeding_PatientsOtherBleedingDisorders_Total =
          null;
      }
    },
    clearDeathHIVRow() {
      if (this.tempData.death_HIV_HasNoData) {
        this.tempData.death_HIV_PatientsHemophilia_Total = null;
        this.tempData.death_HIV_PatientsVWD_Total = null;
        this.tempData.death_HIV_PatientsOtherBleedingDisorders_Total = null;
      }
    },
    clearDeathLiverDiseaseRow() {
      if (this.tempData.death_LiverDisease_HasNoData) {
        this.tempData.death_LiverDisease_PatientsHemophilia_Total = null;
        this.tempData.death_LiverDisease_PatientsVWD_Total = null;
        this.tempData.death_LiverDisease_PatientsOtherBleedingDisorders_Total =
          null;
      }
    },
    clearDeathOtherRow() {
      if (this.tempData.death_Other_HasNoData) {
        this.tempData.death_Other_PatientsHemophilia_Total = null;
        this.tempData.death_Other_PatientsVWD_Total = null;
        this.tempData.death_Other_PatientsOtherBleedingDisorders_Total = null;
      }
    },
  },
});
