
import Vue from "vue";
import FormEditFooter from "../UI-Components/FormEditFooter.vue";
import {CompletedBySection} from "@/models/completedbysection";
import {SaveRes} from "@/models/saveres";
import {Snackbar} from "@/models/snackbar";
import formrevalidate from "../../mixins/formrevalidate";
import i18n from "@/i18n";

export default Vue.extend({
  name: "CompletedBy",
  mixins: [formrevalidate],
  props: {
    isAllowedToEdit: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    isEditable: false,
    headers: [
      {text: "basic.country", value: "completedById"},
      {text: "basic.firstname", value: "completedByFirstName"},
      {text: "basic.lastname", value: "completedByLastName"},
      {text: "basic.email", value: "completedByEmail"},
    ],
    tempData: {} as CompletedBySection,
    initData: {} as CompletedBySection,
    shallSkipDialog: false,
    isFormValid: true,
    rules: {
      firstname: [(v: string) => !!v || i18n.t("rules.firstnamerequired")],
      lastname: [(v: string) => !!v || i18n.t("rules.lastnamerequired")],
      email: [
        (v: string) => !!v || i18n.t("rules.emailrequired"),
        (v: string) =>
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([;|\s]{1}\s*([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/.test(
                v
            ) || i18n.t("rules.emailmustbevalid"),
      ],
      phone: [(v: string) => !!v || i18n.t("rules.phonerequired")],
      city: [(v: string) => !!v || i18n.t("rules.cityrequired")],
    },
  }),
  computed: {
    isPrinting() {
      return this.$store.getters["datamanager/isPrinting"];
    },
    isSurveyYearModifiable() {
      return this.$store.getters["datamanager/getIsSurveyYearModifiable"];
    },
    sectionsStatuses() {
      return this.$store.getters["datamanager/getSectionsStatuses"];
    },
    completedBySection(): CompletedBySection {
      return this.$store.getters[
          "datamanager/getCompletedBySection"
          ] as CompletedBySection;
    },
    countrySelected() {
      return this.$store.getters["datamanager/getCountrySelected"];
    },
    tabSelected() {
      return this.$store.getters["datamanager/getTabSelected"];
    },
    completedBySectionSaveRes() {
      return this.$store.getters["datamanager/getCompletedBySectionSaveRes"];
    },
    hasUnsavedChanges: {
      get() {
        return this.$store.getters["datamanager/getHasUnsavedChanges"];
      },
      set(value: boolean) {
        return this.$store.dispatch(
            "datamanager/changeHasUnsavedChanges",
            value
        );
      },
    },
  },
  methods: {
    async fetchSectionsStatuses() {
      await this.$store.dispatch("datamanager/fetchSectionsStatuses", {
        country: this.countrySelected,
      });
    },
    triggerSnack(snack: Snackbar) {
      this.$store.dispatch("general/changeSnackbar", snack);
    },
    resetValidation() {
      let refForm: any = this.$refs.form;
      if (refForm != undefined) refForm.resetValidation();
    },
    saveit() {
      this.isEditable = false;
      this.shallSkipDialog = false;
      this.saveSurveyComlpetedBySection()
          .then((e) => {
            if (e.status == 200) {
              let snack = {
                isSnacking: true,
                snackColor: "success",
                snackMessage: this.$t("basic.savesuccess").toString(),
                snackTimeout: "2000",
              };
              this.triggerSnack(snack);
              this.fetchSectionsStatuses();
            }
          })
          .catch((err) => {
            if (err.response.status == 422) {
              let snack = {
                isSnacking: true,
                snackColor: "orange",
                snackMessage:
                    this.$t("basic.savewarning").toString() +
                    err.response.statusText,
                snackTimeout: "2000",
              };
              this.triggerSnack(snack);
            } else {
              let snack = {
                isSnacking: true,
                snackColor: "error",
                snackMessage:
                    this.$t("basic.saveerror").toString() + err.response.statusText,
                snackTimeout: "2000",
              };
              this.triggerSnack(snack);
            }
            this.isEditable = true;
          });
    },
    willSave() {
      this.tempData.completedBySectionIsComplete = true;
      this.saveit();
    },
    willSaveAsDraft() {
      this.tempData.completedBySectionIsComplete = false;
      this.saveit();
    },

    reloadTempData() {
      this.tempData = {...this.initData};
      this.resetValidation();
    },
    async saveSurveyComlpetedBySection() {
      await this.$store.dispatch("datamanager/saveSurveyCompletedBySection", {
        country: this.countrySelected,
        newData: this.tempData,
      });
      return this.completedBySectionSaveRes as SaveRes;
    },
  },
  watch: {
    completedBySection: {
      handler(res) {
        this.initData = {...res};
        if (res.completedBySectionIsComplete != null) {
          this.isEditable = false;
        } else {
          if (
              !this.sectionsStatuses.isApproved &&
              this.isAllowedToEdit &&
              this.isSurveyYearModifiable
          ) {
            this.isEditable = true;
          }
          this.shallSkipDialog = true;
        }
        this.reloadTempData();
      },
      deep: true,
    },
    isSurveyYearModifiable: {
      handler(res) {
        if (res == false) {
          this.isEditable = false;
        }
      },
      deep: true,
    },
    tempData: {
      handler(res) {
        if (JSON.stringify(res) === JSON.stringify(this.initData)) {
          this.hasUnsavedChanges = false;
        } else if (this.hasUnsavedChanges == false) {
          this.hasUnsavedChanges = true;
        }
      },
      deep: true,
    },
  },
  components: {
    "form-edit-footer": FormEditFooter,
  },
});
