var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninfection.q11_title")))])])]),_c('tr',[_c('td'),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninfection.q_hemophilia")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninfection.q_vwd")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninfection.q_other")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninfection.q_nodata")))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectioninfection.q11_hivtotal"))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hiV_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hiV_PatientsHemophilia_Total,"disabled":!_vm.isEditable || _vm.tempData.hiV_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hiV_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.hiV_PatientsHemophilia_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "hiV_PatientsHemophilia_Total", _vm._n($$v))},expression:"tempData.hiV_PatientsHemophilia_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hiV_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hiV_PatientsVWD_Total,"disabled":!_vm.isEditable || _vm.tempData.hiV_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hiV_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.hiV_PatientsVWD_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "hiV_PatientsVWD_Total", _vm._n($$v))},expression:"tempData.hiV_PatientsVWD_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hiV_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hiV_PatientsOtherBleedingDisorders_Total,"disabled":!_vm.isEditable || _vm.tempData.hiV_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hiV_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.hiV_PatientsOtherBleedingDisorders_Total
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "hiV_PatientsOtherBleedingDisorders_Total", _vm._n($$v))},expression:"\n                  tempData.hiV_PatientsOtherBleedingDisorders_Total\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHIVRow()}},model:{value:(_vm.tempData.hiV_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "hiV_HasNoData", $$v)},expression:"tempData.hiV_HasNoData"}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectioninfection.q11_newhivtotal", {'currentYear' : _vm.currentSurveyYear()}))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hiV_New_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hiV_NewPatientsHemophilia_Total,"disabled":!_vm.isEditable || _vm.tempData.hiV_New_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hiV_New_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.hiV_NewPatientsHemophilia_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "hiV_NewPatientsHemophilia_Total", _vm._n($$v))},expression:"tempData.hiV_NewPatientsHemophilia_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hiV_New_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hiV_NewPatientsVWD_Total,"disabled":!_vm.isEditable || _vm.tempData.hiV_New_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hiV_New_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.hiV_NewPatientsVWD_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "hiV_NewPatientsVWD_Total", _vm._n($$v))},expression:"tempData.hiV_NewPatientsVWD_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hiV_New_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hiV_NewPatientsOtherBleedingDisorders_Total,"disabled":!_vm.isEditable || _vm.tempData.hiV_New_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hiV_New_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.hiV_NewPatientsOtherBleedingDisorders_Total
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "hiV_NewPatientsOtherBleedingDisorders_Total", _vm._n($$v))},expression:"\n                  tempData.hiV_NewPatientsOtherBleedingDisorders_Total\n                "}})],1),_c('td',{staticClass:"centered_td"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHIVNewRow()}},model:{value:(_vm.tempData.hiV_New_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "hiV_New_HasNoData", $$v)},expression:"tempData.hiV_New_HasNoData"}})],1)])])]},proxy:true}])}),_c('v-divider')],1),_c('v-container',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninfection.q12_title")))])])]),_c('tr',[_c('td'),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninfection.q_hemophilia")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninfection.q_vwd")))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninfection.q_other")))])]),_c('td',{staticClass:"centered_td"},[_c('b',[_vm._v(_vm._s(_vm.$t("datamanager.patients.sectioninfection.q_nodata")))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectioninfection.q12_infectedhepatitisctotal" ))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hepatitisC_Infected_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hepatitisC_InfectedPatientsHemophilia_Total,"disabled":!_vm.isEditable || _vm.tempData.hepatitisC_Infected_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hepatitisC_Infected_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.hepatitisC_InfectedPatientsHemophilia_Total
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_InfectedPatientsHemophilia_Total", _vm._n($$v))},expression:"\n                  tempData.hepatitisC_InfectedPatientsHemophilia_Total\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hepatitisC_Infected_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hepatitisC_InfectedPatientsVWD_Total,"disabled":!_vm.isEditable || _vm.tempData.hepatitisC_Infected_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hepatitisC_Infected_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.hepatitisC_InfectedPatientsVWD_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_InfectedPatientsVWD_Total", _vm._n($$v))},expression:"tempData.hepatitisC_InfectedPatientsVWD_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hepatitisC_Infected_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hepatitisC_InfectedPatientsOtherBleedingDisorders_Total,"disabled":!_vm.isEditable || _vm.tempData.hepatitisC_Infected_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hepatitisC_Infected_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.hepatitisC_InfectedPatientsOtherBleedingDisorders_Total
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_InfectedPatientsOtherBleedingDisorders_Total", _vm._n($$v))},expression:"\n                  tempData.hepatitisC_InfectedPatientsOtherBleedingDisorders_Total\n                "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHepatitisCInfectedRow()}},model:{value:(_vm.tempData.hepatitisC_Infected_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_Infected_HasNoData", $$v)},expression:"tempData.hepatitisC_Infected_HasNoData"}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectioninfection.q12_activehepatitisctotal" ))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hepatitisC_Active_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hepatitisC_ActivePatientsHemophilia_Total,"disabled":!_vm.isEditable || _vm.tempData.hepatitisC_Active_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hepatitisC_Active_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.hepatitisC_ActivePatientsHemophilia_Total
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_ActivePatientsHemophilia_Total", _vm._n($$v))},expression:"\n                  tempData.hepatitisC_ActivePatientsHemophilia_Total\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hepatitisC_Active_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hepatitisC_ActivePatientsVWD_Total,"disabled":!_vm.isEditable || _vm.tempData.hepatitisC_Active_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hepatitisC_Active_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.hepatitisC_ActivePatientsVWD_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_ActivePatientsVWD_Total", _vm._n($$v))},expression:"tempData.hepatitisC_ActivePatientsVWD_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hepatitisC_Active_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hepatitisC_ActivePatientsOtherBleedingDisorders_Total,"disabled":!_vm.isEditable || _vm.tempData.hepatitisC_Active_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hepatitisC_Active_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.hepatitisC_ActivePatientsOtherBleedingDisorders_Total
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_ActivePatientsOtherBleedingDisorders_Total", _vm._n($$v))},expression:"\n                  tempData.hepatitisC_ActivePatientsOtherBleedingDisorders_Total\n                "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHepatitisCActiveRow()}},model:{value:(_vm.tempData.hepatitisC_Active_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_Active_HasNoData", $$v)},expression:"tempData.hepatitisC_Active_HasNoData"}})],1)]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "datamanager.patients.sectioninfection.q12_newhepatitisctotal", {'currentYear' : _vm.currentSurveyYear()} ))+" ")]),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hepatitisC_New_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hepatitisC_NewPatientsHemophilia_Total,"disabled":!_vm.isEditable || _vm.tempData.hepatitisC_New_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hepatitisC_New_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.hepatitisC_NewPatientsHemophilia_Total
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_NewPatientsHemophilia_Total", _vm._n($$v))},expression:"\n                  tempData.hepatitisC_NewPatientsHemophilia_Total\n                "}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hepatitisC_New_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hepatitisC_NewPatientsVWD_Total,"disabled":!_vm.isEditable || _vm.tempData.hepatitisC_New_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hepatitisC_New_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(_vm.tempData.hepatitisC_NewPatientsVWD_Total),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_NewPatientsVWD_Total", _vm._n($$v))},expression:"tempData.hepatitisC_NewPatientsVWD_Total"}})],1),_c('td',[_c('v-text-field',{class:!_vm.isEditable || _vm.tempData.hepatitisC_New_HasNoData
                    ? 'remove-underline-text-area'
                    : '',attrs:{"outlined":_vm.isEditable,"hide-details":"auto","value":_vm.tempData.hepatitisC_NewPatientsOtherBleedingDisorders_Total,"disabled":!_vm.isEditable || _vm.tempData.hepatitisC_New_HasNoData,"rules":!_vm.isEditable?[]:_vm.tempData.hepatitisC_New_HasNoData
                  ?[_vm.rules.mustBeNull] : [_vm.rules.requis]},nativeOn:{"keypress":function($event){return _vm.onlyAcceptNumber.apply(null, arguments)}},model:{value:(
                  _vm.tempData.hepatitisC_NewPatientsOtherBleedingDisorders_Total
                ),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_NewPatientsOtherBleedingDisorders_Total", _vm._n($$v))},expression:"\n                  tempData.hepatitisC_NewPatientsOtherBleedingDisorders_Total\n                "}})],1),_c('td',{staticClass:"centered_td pr-1"},[_c('v-checkbox',{attrs:{"hide-details":"auto","disabled":!_vm.isEditable},on:{"change":function($event){return _vm.clearHepatitisCNewRow()}},model:{value:(_vm.tempData.hepatitisC_New_HasNoData),callback:function ($$v) {_vm.$set(_vm.tempData, "hepatitisC_New_HasNoData", $$v)},expression:"tempData.hepatitisC_New_HasNoData"}})],1)]),_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('v-alert',{staticClass:"caption ma-1",attrs:{"text":"","dense":"","color":"teal","border":"left"}},[_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectioninfection.q12_text1"))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t("datamanager.patients.sectioninfection.q12_text2"))+" ")])])])],1)])])]},proxy:true}])}),_c('v-divider')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }