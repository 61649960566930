
import Vue from "vue";
import FormEditFooter from "../UI-Components/FormEditFooter.vue";
import SectionGender from "./patients/SectionGender.vue";
import SectionAge from "./patients/SectionAge.vue";
import SectionAgeFrom2022 from "./patients/SectionAgeFrom2022.vue";
import SectionSeverity from "./patients/SectionSeverity.vue";
import SectionSeverityFrom2022 from "./patients/SectionSeverityFrom2022.vue";
import SectionSeverityFrom2023 from "./patients/SectionSeverityFrom2023.vue";
import SectionInhibitor from "./patients/SectionInhibitor.vue";
import SectionInhibitorFrom2022 from "./patients/SectionInhibitorFrom2022.vue";
import SectionProduct from "./patients/SectionProduct.vue";
import SectionInfection from "./patients/SectionInfection.vue";
import SectionDeath from "./patients/SectionDeath.vue";
import SectionComment from "./patients/SectionComment.vue";
import { PatientSection } from "@/models/patientsection";
import { SaveRes } from "@/models/saveres";
import { AuthRole } from "@/models/authrole";
import formrevalidate from "../../mixins/formrevalidate";
import i18n from "@/i18n";
import SectionProductFrom2022 from "./patients/SectionProductFrom2022.vue";

export default Vue.extend({
  name: "Patients",
  mixins: [formrevalidate],
  data: () => ({
    isEditable: false,
    tempData: {} as PatientSection,
    initData: {} as PatientSection,
    shallSkipDialog: false,
    isFormValid: true,
    isSnacking: false,
    snackColor: "info",
    snackMessage: "",
    snackTimeout: 1500,
    showSnackBtn: false,
    rules: {
      requis: (v: number) =>
        (v != null && typeof v == "number") || i18n.t("rules.required"),
      mustBeNull: (v: number) => v == null || i18n.t("rules.mustBeNull"),
    },
    hasCancelled: 0,
    incrementedKey: 0,
  }),
  computed: {
    isPrinting(): boolean {
      return this.$store.getters["datamanager/isPrinting"];
    },
    //Ete obliger de copy paste ca ici.. jetais pas capable de passer ca en props... erreur de VSCode?
    isAllowedToEdit(): boolean {
      let isAllowed = false;
      let userRole = this.$store.getters["auth/getLoggedInUserRole"];
      let isSurveyYearModifiable =
        this.$store.getters["datamanager/getIsSurveyYearModifiable"];
      if (userRole == AuthRole.Administrator || userRole == AuthRole.WFH) {
        isAllowed = true;
      } else if (isSurveyYearModifiable && userRole == AuthRole.NMO) {
        isAllowed = true;
      } //Authrole DDC is never allowed.
      return isAllowed;
    },
    yearSelected() {
      return this.$store.getters["datamanager/getCurrentSurvey"].year;
    },
    isSurveyYearModifiable() {
      return this.$store.getters["datamanager/getIsSurveyYearModifiable"];
    },
    sectionsStatuses() {
      return this.$store.getters["datamanager/getSectionsStatuses"];
    },
    patientSection() {
      return this.$store.getters["datamanager/getPatientSection"];
    },
    patientSectionSaveRes() {
      return this.$store.getters["datamanager/getPatientSectionSaveRes"];
    },
    countrySelected() {
      return this.$store.getters["datamanager/getCountrySelected"];
    },
    hasUnsavedChanges: {
      get() {
        return this.$store.getters["datamanager/getHasUnsavedChanges"];
      },
      set(value: boolean) {
        return this.$store.dispatch(
          "datamanager/changeHasUnsavedChanges",
          value
        );
      },
    },
  },
  methods: {
    async fetchSectionsStatuses() {
      await this.$store.dispatch("datamanager/fetchSectionsStatuses", {
        country: this.countrySelected,
      });
    },
    resetValidation() {
      let refForm: any = this.$refs.form;
      if (refForm != undefined) refForm.resetValidation();
    },
    saveit() {
      this.isEditable = false;
      (this.shallSkipDialog = false),
        this.saveSurveyPatientSection()
          .then((e) => {
            if (e.status == 200) {
              this.snackColor = "success";
              this.snackMessage = this.$t("basic.savesuccess").toString();
              this.snackTimeout = 1500;
              this.showSnackBtn = false;
              this.isSnacking = true;
              this.fetchSectionsStatuses();
            }
          })
          .catch((err) => {
            if (err.response.status == 422) {
              this.isEditable = true;
              this.snackColor = "warning";
              this.snackMessage =
                this.$t("basic.savewarning").toString() +
                this.$t("error.backendbrokenrules").toString();
              this.snackTimeout = -1;
              this.showSnackBtn = true;
            } else {
              this.isEditable = true;
              this.snackColor = "error";
              this.snackMessage =
                this.$t("basic.saveerror").toString() + err.response.statusText;
              this.snackTimeout = -1;
              this.showSnackBtn = true;
            }
            this.isSnacking = true;
            this.isEditable = true;
          });
    },
    willSave() {
      this.tempData.patientSectionIsComplete = true;
      this.saveit();
    },
    willSaveAsDraft() {
      this.tempData.patientSectionIsComplete = false;
      this.saveit();
    },

    reloadTempData() {
      this.tempData = { ...this.initData };
      this.incrementedKey += 1
      this.hasCancelled = this.hasCancelled + 1
      this.resetValidation();
    },
    async saveSurveyPatientSection(): Promise<any> {
      return await this.$store
        .dispatch("datamanager/saveSurveyPatientSection", {
          country: this.countrySelected,
          newData: this.tempData,
        })
        .then(() => {
          return this.patientSectionSaveRes as SaveRes;
        });
    },
  },
  watch: {
    patientSection: {
      handler(res) {
        if (res.patientSectionIsComplete != null) this.isEditable = false;
        else {
          this.shallSkipDialog = true;
          if (
            !this.sectionsStatuses.isApproved &&
            this.isAllowedToEdit &&
            this.isSurveyYearModifiable
          ) {
            this.isEditable = true;
          }
        }
        this.initData = { ...res };
        this.reloadTempData();
      },
      deep: true,
    },
    isSurveyYearModifiable: {
      handler(res) {
        if (res == false) {
          this.isEditable = false;
        }
      },
      deep: true,
    },
    tempData: {
      handler(res) {
        if (JSON.stringify(res) === JSON.stringify(this.initData)) {
          this.hasUnsavedChanges = false;
        } else if (this.hasUnsavedChanges == false) {
          this.hasUnsavedChanges = true;
        }
      },
      deep: true,
    },
  },
  created() {
    this.reloadTempData();
  },
  components: {
    FormEditFooter,
    SectionGender,
    SectionAge,
    SectionAgeFrom2022,
    SectionSeverity,
    SectionSeverityFrom2022,
    SectionSeverityFrom2023,
    SectionInhibitor,
    SectionInhibitorFrom2022,
    SectionProduct,
    SectionInfection,
    SectionDeath,
    SectionComment,
    SectionProductFrom2022,
  },
});
