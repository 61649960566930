
import Vue from "vue";
import FormEditFooter from "../UI-Components/FormEditFooter.vue";
import { CurrencySection } from "@/models/currencysection";
import { Snackbar } from "@/models/snackbar";
import i18n from "@/i18n";
import formrevalidate from "../../mixins/formrevalidate";

export default Vue.extend({
  name: "Currency",
  mixins: [formrevalidate],
  props: {
    isAllowedToEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isEditable: false,
    tempData: {} as CurrencySection,
    initData: {} as CurrencySection,
    shallSkipDialog: false,
    isFormValid: true,
    rules: {
      taxInc: [
        (v: boolean | null) => (v != null ? true : i18n.t("rules.plsselect")),
      ],
    },
  }),

  computed: {
    isPrinting(){
      return this.$store.getters["datamanager/isPrinting"];
    },
    isSurveyYearModifiable() {
      return this.$store.getters["datamanager/getIsSurveyYearModifiable"];
    },
    sectionsStatuses() {
      return this.$store.getters["datamanager/getSectionsStatuses"];
    },
    currencySection() {
      return this.$store.getters["datamanager/getCurrencySection"];
    },
    currencySectionSaveRes() {
      return this.$store.getters["datamanager/getCurrencySectionSaveRes"];
    },
    countrySelected() {
      return this.$store.getters["datamanager/getCountrySelected"];
    },
    hasUnsavedChanges: {
      get() {
        return this.$store.getters["datamanager/getHasUnsavedChanges"];
      },
      set(value: boolean) {
        return this.$store.dispatch(
          "datamanager/changeHasUnsavedChanges",
          value
        );
      },
    },
    taxIncRateRule() {
      return [
        (v: string) => {
          if (
            (v == null || v.length == 0) &&
            this.tempData.price_IsTaxIncluded === true
          ) {
            return this.$t("basic.required");
          } else return true;
        },
      ];
    },
  },
  methods: {
    async fetchSectionsStatuses() {
      await this.$store.dispatch("datamanager/fetchSectionsStatuses", {
        country: this.countrySelected,
      });
    },
    triggerSnack(snack: Snackbar) {
      this.$store.dispatch("general/changeSnackbar", snack);
    },
    resetValidation() {
      let refForm: any = this.$refs.form;
      if (refForm != undefined) refForm.resetValidation();
    },
    saveit() {
      this.isEditable = false;
      this.shallSkipDialog = false;
      if (
        this.tempData.currency_Type != null &&
        this.tempData.currency_Type.length > 0
      ) {
        this.tempData.currency_HasNoData = false;
      } else {
        this.tempData.currency_HasNoData = true;
      }
      this.tempData.countryId = this.countrySelected.id;
      this.saveSurveyCurrencySection()
        .then((e) => {
          if (e.status == 200) {
            let snack = {
              isSnacking: true,
              snackColor: "success",
              snackMessage: this.$t("basic.savesuccess").toString(),
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
            this.fetchSectionsStatuses();
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let snack = {
              isSnacking: true,
              snackColor: "orange",
              snackMessage:
                this.$t("basic.savewarning").toString() +
                err.response.statusText,
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          } else {
            let snack = {
              isSnacking: true,
              snackColor: "error",
              snackMessage:
                this.$t("basic.saveerror").toString() + err.response.statusText,
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          }
        });
    },
    willSave() {
      this.tempData.currencySectionIsComplete = true;
      this.saveit();
    },
    willSaveAsDraft() {
      this.tempData.currencySectionIsComplete = false;
      this.saveit();
    },

    reloadTempData() {
      this.tempData = { ...this.initData };
      this.resetValidation();
    },
    updateCheckNoData() {
      if (this.tempData.currency_HasNoData) {
        this.tempData.currency_Type = "";
      }
    },
    async saveSurveyCurrencySection() {
      await this.$store.dispatch("datamanager/saveSurveyCurrencySection", {
        country: this.countrySelected,
        newData: this.tempData,
      });
      let res = this.currencySectionSaveRes;
      return res;
    },
  },
  watch: {
    tempData: {
      deep: true,
      handler(res) {
        if (JSON.stringify(res) === JSON.stringify(this.initData)) {
          this.hasUnsavedChanges = false;
        } else if (this.hasUnsavedChanges == false) {
          this.hasUnsavedChanges = true;
        }
      },
    },
    isSurveyYearModifiable: {
      handler(res) {
        if (res == false) {
          this.isEditable = false;
        }
      },
      deep: true,
    },
    currencySection: {
      handler(res) {
        this.initData = { ...res };
        if (res.currencySectionIsComplete != null) {
          this.isEditable = false;
        } else {
          if (
            !this.sectionsStatuses.isApproved &&
            this.isAllowedToEdit &&
            this.isSurveyYearModifiable
          ) {
            this.isEditable = true;
          }
          this.shallSkipDialog = true;
        }
        this.reloadTempData();
      },
      deep: true,
    },
  },
  components: {
    "form-edit-footer": FormEditFooter,
  },
});
