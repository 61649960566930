
import Vue from "vue";

export default Vue.extend({
  name: "SectionGender",
  data() {
    return {
      isTotalNotSumErrors: [] as string[],
    };
  },
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
    },
  },
  computed: {
    isPrinting(){
      return this.$store.getters["datamanager/isPrinting"];
    },
    resaltGrey() {
      if (
          this.tempData.patientsHemophiliaTypeA_Gender_HasNoData
          && this.tempData.patientsHemophiliaTypeB_Gender_HasNoData
          && this.tempData.patientsVWD_Gender_HasNoData
          && this.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData
          && this.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
          && this.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
          && this.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
          && this.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
          && this.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData
          && this.tempData.patientsFactorXIDeficiency_Gender_HasNoData
          && this.tempData.patientsFactorXDeficiency_Gender_HasNoData
          && this.tempData.patientsFactorVIIDeficiency_Gender_HasNoData
          && this.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData
          && this.tempData.patientsFactorVDeficiency_Gender_HasNoData
          && this.tempData.patientsFactorIIDeficiency_Gender_HasNoData
          && this.tempData.patientsFactorIDeficiency_Gender_HasNoData
      ) {
        this.tempData.genderIsInformationAccurate = null
        return true
      }
      return false
    }
  },
  watch: {
    isEditable(res) {
      if (!res) {
        this.isTotalNotSumErrors = [];
      }
    },
  },
  methods: {
    ruleTotalIsSum(
        total: number,
        men: number,
        women: number,
        unkwn: number,
        question: string,
        nodata: boolean
    ) {
      // so faire comme dans care. Une array liste de string qui disent ou ya des "bugs". Pi delete quand besoin.
      if (!nodata) {
        if (total != null && total >= 0 && typeof total == "number") {
          if (total - men - women - unkwn != 0) {
            if (!this.isTotalNotSumErrors.includes(question)) {
              this.isTotalNotSumErrors.push(question);
            }
            return [false];
          } else {
            this.removeFromErrors(question);
            return [];
          }
        } else {
          this.removeFromErrors(question);
          return [this.$i18n.t("rules.required")];
        }
      } else {
        this.removeFromErrors(question);
        return [];
      }
    },
    removeFromErrors(question: string) {
      let index = this.isTotalNotSumErrors.indexOf(question);
      if (index > -1) {
        this.isTotalNotSumErrors.splice(index, 1);
      }
    },
    clearHemophiliaTypeAGenderRow() {
      if (this.tempData.patientsHemophiliaTypeA_Gender_HasNoData) {
        this.tempData.patientsHemophiliaTypeA_Gender_Total = null;
        this.tempData.patientsHemophiliaTypeA_Gender_Male = null;
        this.tempData.patientsHemophiliaTypeA_Gender_Female = null;
        this.tempData.patientsHemophiliaTypeA_Gender_Unknown = null;
        this.removeFromErrors("q1");
      }
    },
    clearHemophiliaTypeBGenderRow() {
      if (this.tempData.patientsHemophiliaTypeB_Gender_HasNoData) {
        this.tempData.patientsHemophiliaTypeB_Gender_Total = null;
        this.tempData.patientsHemophiliaTypeB_Gender_Male = null;
        this.tempData.patientsHemophiliaTypeB_Gender_Female = null;
        this.tempData.patientsHemophiliaTypeB_Gender_Unknown = null;
        this.removeFromErrors("q2");
      }
    },
    clearHemophiliaTypeUnknownGenderRow() {
      if (this.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData) {
        this.tempData.patientsHemophiliaTypeUnknown_Gender_Total = null;
        this.tempData.patientsHemophiliaTypeUnknown_Gender_Male = null;
        this.tempData.patientsHemophiliaTypeUnknown_Gender_Female = null;
        this.tempData.patientsHemophiliaTypeUnknown_Gender_Unknown = null;
        this.removeFromErrors("q3");
      }
    },
    clearVWDGenderRow() {
      if (this.tempData.patientsVWD_Gender_HasNoData) {
        this.tempData.patientsVWD_Gender_Total = null;
        this.tempData.patientsVWD_Gender_Male = null;
        this.tempData.patientsVWD_Gender_Female = null;
        this.tempData.patientsVWD_Gender_Unknown = null;
        this.removeFromErrors("q4");
      }
    },
    clearFactorIDeficiencyGenderRow() {
      if (this.tempData.patientsFactorIDeficiency_Gender_HasNoData) {
        this.tempData.patientsFactorIDeficiency_Gender_Total = null;
        this.tempData.patientsFactorIDeficiency_Gender_Male = null;
        this.tempData.patientsFactorIDeficiency_Gender_Female = null;
        this.tempData.patientsFactorIDeficiency_Gender_Unknown = null;
        this.removeFromErrors("q5");
      }
    },
    clearFactorIIDeficiencyGenderRow() {
      if (this.tempData.patientsFactorIIDeficiency_Gender_HasNoData) {
        this.tempData.patientsFactorIIDeficiency_Gender_Total = null;
        this.tempData.patientsFactorIIDeficiency_Gender_Male = null;
        this.tempData.patientsFactorIIDeficiency_Gender_Female = null;
        this.tempData.patientsFactorIIDeficiency_Gender_Unknown = null;
        this.removeFromErrors("q6");
      }
    },
    clearFactorVDeficiencyGenderRow() {
      if (this.tempData.patientsFactorVDeficiency_Gender_HasNoData) {
        this.tempData.patientsFactorVDeficiency_Gender_Total = null;
        this.tempData.patientsFactorVDeficiency_Gender_Male = null;
        this.tempData.patientsFactorVDeficiency_Gender_Female = null;
        this.tempData.patientsFactorVDeficiency_Gender_Unknown = null;
        this.removeFromErrors("q7");
      }
    },
    clearFactorVAndVIIIDeficiencyGenderRow() {
      if (this.tempData.patientsFactorVAndVIIIDeficiency_Gender_HasNoData) {
        this.tempData.patientsFactorVAndVIIIDeficiency_Gender_Total = null;
        this.tempData.patientsFactorVAndVIIIDeficiency_Gender_Male = null;
        this.tempData.patientsFactorVAndVIIIDeficiency_Gender_Female = null;
        this.tempData.patientsFactorVAndVIIIDeficiency_Gender_Unknown = null;
        this.removeFromErrors("q8");
      }
    },
    clearFactorVIIDeficiencyGenderRow() {
      if (this.tempData.patientsFactorVIIDeficiency_Gender_HasNoData) {
        this.tempData.patientsFactorVIIDeficiency_Gender_Total = null;
        this.tempData.patientsFactorVIIDeficiency_Gender_Male = null;
        this.tempData.patientsFactorVIIDeficiency_Gender_Female = null;
        this.tempData.patientsFactorVIIDeficiency_Gender_Unknown = null;
        this.removeFromErrors("q9");
      }
    },
    clearFactorXDeficiencyGenderRow() {
      if (this.tempData.patientsFactorXDeficiency_Gender_HasNoData) {
        this.tempData.patientsFactorXDeficiency_Gender_Total = null;
        this.tempData.patientsFactorXDeficiency_Gender_Male = null;
        this.tempData.patientsFactorXDeficiency_Gender_Female = null;
        this.tempData.patientsFactorXDeficiency_Gender_Unknown = null;
        this.removeFromErrors("q10");
      }
    },
    clearFactorXIDeficiencyGenderRow() {
      if (this.tempData.patientsFactorXIDeficiency_Gender_HasNoData) {
        this.tempData.patientsFactorXIDeficiency_Gender_Total = null;
        this.tempData.patientsFactorXIDeficiency_Gender_Male = null;
        this.tempData.patientsFactorXIDeficiency_Gender_Female = null;
        this.tempData.patientsFactorXIDeficiency_Gender_Unknown = null;
        this.removeFromErrors("q11");
      }
    },
    clearFactorXIIIDeficiencyGenderRow() {
      if (this.tempData.patientsFactorXIIIDeficiency_Gender_HasNoData) {
        this.tempData.patientsFactorXIIIDeficiency_Gender_Total = null;
        this.tempData.patientsFactorXIIIDeficiency_Gender_Male = null;
        this.tempData.patientsFactorXIIIDeficiency_Gender_Female = null;
        this.tempData.patientsFactorXIIIDeficiency_Gender_Unknown = null;
        this.removeFromErrors("q12");
      }
    },
    clearOtherHereditaryBleedingDisordersGenderRow() {
      if (
          this.tempData.patientsOtherHereditaryBleedingDisorders_Gender_HasNoData
      ) {
        this.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Total =
            null;
        this.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Male =
            null;
        this.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Female =
            null;
        this.tempData.patientsOtherHereditaryBleedingDisorders_Gender_Unknown =
            null;
        this.removeFromErrors("q13");
      }
    },
    clearPlateletDisorderGlanzmannThrombastheniaGenderRow() {
      if (
          this.tempData
              .patientsPlateletDisorderGlanzmannThrombasthenia_Gender_HasNoData
      ) {
        this.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Total =
            null;
        this.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Male =
            null;
        this.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Female =
            null;
        this.tempData.patientsPlateletDisorderGlanzmannThrombasthenia_Gender_Unknown =
            null;
        this.removeFromErrors("q14");
      }
    },
    clearPlateletDisorderBernardSoulierSyndromeGenderRow() {
      if (
          this.tempData
              .patientsPlateletDisorderBernardSoulierSyndrome_Gender_HasNoData
      ) {
        this.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Total =
            null;
        this.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Male =
            null;
        this.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Female =
            null;
        this.tempData.patientsPlateletDisorderBernardSoulierSyndrome_Gender_Unknown =
            null;
        this.removeFromErrors("q15");
      }
    },
    clearPlateletDisorderOtherOrUnknownGenderRow() {
      if (
          this.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_HasNoData
      ) {
        this.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Total =
            null;
        this.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Male = null;
        this.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Female =
            null;
        this.tempData.patientsPlateletDisorderOtherOrUnknown_Gender_Unknown =
            null;
        this.removeFromErrors("q16");
      }
    },
  },
});
