
import Vue from "vue";

export default Vue.extend({
  name: "SectionAgeFrom2022",
  data() {
    return {
      totalNotSumQ1Errors: [] as string[],
      totalNotSumQ2Errors: [] as string[],
    };
  },
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
    },
  },
  computed: {
    resaltGrey() {
      if (
        // this.tempData.patientsVWD_Age_HasNoData
        this.tempData.patientsHemophiliaTypeUnknown_Age_HasNoData &&
        this.tempData.patientsHemophiliaTypeB_Age_HasNoData &&
        this.tempData.patientsHemophiliaTypeA_Age_HasNoData
      ) {
        this.tempData.ageIsInformationAccurate = null;
        return true;
      }
      return false;
    },
    sumOfQ1HA(): number {
      let x = this.tempData.patientsHemophiliaTypeA_Age_0To4 || 0;
      let y = this.tempData.patientsHemophiliaTypeA_Age_5To13 || 0;
      let z = this.tempData.patientsHemophiliaTypeA_Age_14To18 || 0;
      let a = this.tempData.patientsHemophiliaTypeA_Age_19To44 || 0;
      let b = this.tempData.patientsHemophiliaTypeA_Age_45OrOlder || 0;
      let c = this.tempData.patientsHemophiliaTypeA_Age_Unknown || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
    sumOfQ1HB(): number {
      let x = this.tempData.patientsHemophiliaTypeB_Age_0To4 || 0;
      let y = this.tempData.patientsHemophiliaTypeB_Age_5To13 || 0;
      let z = this.tempData.patientsHemophiliaTypeB_Age_14To18 || 0;
      let a = this.tempData.patientsHemophiliaTypeB_Age_19To44 || 0;
      let b = this.tempData.patientsHemophiliaTypeB_Age_45OrOlder || 0;
      let c = this.tempData.patientsHemophiliaTypeB_Age_Unknown || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
    sumOfQ1HC(): number {
      let x = this.tempData.patientsHemophiliaTypeUnknown_Age_0To4 || 0;
      let y = this.tempData.patientsHemophiliaTypeUnknown_Age_5To13 || 0;
      let z = this.tempData.patientsHemophiliaTypeUnknown_Age_14To18 || 0;
      let a = this.tempData.patientsHemophiliaTypeUnknown_Age_19To44 || 0;
      let b = this.tempData.patientsHemophiliaTypeUnknown_Age_45OrOlder || 0;
      let c = this.tempData.patientsHemophiliaTypeUnknown_Age_Unknown || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
    sumOfQ2(): number {
      let x = this.tempData.patientsVWD_Age_0To4 || 0;
      let y = this.tempData.patientsVWD_Age_5To13 || 0;
      let z = this.tempData.patientsVWD_Age_14To18 || 0;
      let a = this.tempData.patientsVWD_Age_19To44 || 0;
      let b = this.tempData.patientsVWD_Age_45OrOlder || 0;
      let c = this.tempData.patientsVWD_Age_Unknown || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
    sumOfQ2M(): number {
      let x = this.tempData.patientsVWD_Age_0To4_Gender_Male || 0;
      let y = this.tempData.patientsVWD_Age_5To13_Gender_Male || 0;
      let z = this.tempData.patientsVWD_Age_14To18_Gender_Male || 0;
      let a = this.tempData.patientsVWD_Age_19To44_Gender_Male || 0;
      let b = this.tempData.patientsVWD_Age_45OrOlder_Gender_Male || 0;
      let c = this.tempData.patientsVWD_Age_Unknown_Gender_Male || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
    sumOfQ2F(): number {
      let x = this.tempData.patientsVWD_Age_0To4_Gender_Female || 0;
      let y = this.tempData.patientsVWD_Age_5To13_Gender_Female || 0;
      let z = this.tempData.patientsVWD_Age_14To18_Gender_Female || 0;
      let a = this.tempData.patientsVWD_Age_19To44_Gender_Female || 0;
      let b = this.tempData.patientsVWD_Age_45OrOlder_Gender_Female || 0;
      let c = this.tempData.patientsVWD_Age_Unknown_Gender_Female || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
    sumOfQ2U(): number {
      let x = this.tempData.patientsVWD_Age_0To4_Gender_Unknown || 0;
      let y = this.tempData.patientsVWD_Age_5To13_Gender_Unknown || 0;
      let z = this.tempData.patientsVWD_Age_14To18_Gender_Unknown || 0;
      let a = this.tempData.patientsVWD_Age_19To44_Gender_Unknown || 0;
      let b = this.tempData.patientsVWD_Age_45OrOlder_Gender_Unknown || 0;
      let c = this.tempData.patientsVWD_Age_Unknown_Gender_Unknown || 0;
      let sum = (x + y + z + a + b + c) as number;
      return sum;
    },
  },
  methods: {
    isVwdTotalBalance(line: string): boolean {
      line = line.toLowerCase();

      if (this.tempData.patientsVWD_Age_HasNoData) return true;
      if (this.tempData.patientsVWD_Age_HasNoData_Gender_Male) return true;
      if (this.tempData.patientsVWD_Age_HasNoData_Gender_Female) return true;
      if (this.tempData.patientsVWD_Age_HasNoData_Gender_Unknown) return true;

      let male = 0;
      let female = 0;
      let unknown = 0;
      let total = 0;

      if (line == "0to4") {
        male = this.tempData.patientsVWD_Age_0To4_Gender_Male;
        female = this.tempData.patientsVWD_Age_0To4_Gender_Female;
        unknown = this.tempData.patientsVWD_Age_0To4_Gender_Unknown;

        total = this.tempData.patientsVWD_Age_0To4;
      } else if (line == "5to13") {
        male = this.tempData.patientsVWD_Age_5To13_Gender_Male;
        female = this.tempData.patientsVWD_Age_5To13_Gender_Female;
        unknown = this.tempData.patientsVWD_Age_5To13_Gender_Unknown;

        total = this.tempData.patientsVWD_Age_5To13;
      } else if (line == "14to18") {
        male = this.tempData.patientsVWD_Age_14To18_Gender_Male;
        female = this.tempData.patientsVWD_Age_14To18_Gender_Female;
        unknown = this.tempData.patientsVWD_Age_14To18_Gender_Unknown;

        total = this.tempData.patientsVWD_Age_14To18;
      } else if (line == "19to44") {
        male = this.tempData.patientsVWD_Age_19To44_Gender_Male;
        female = this.tempData.patientsVWD_Age_19To44_Gender_Female;
        unknown = this.tempData.patientsVWD_Age_19To44_Gender_Unknown;

        total = this.tempData.patientsVWD_Age_19To44;
      } else if (line == "45+") {
        male = this.tempData.patientsVWD_Age_45OrOlder_Gender_Male;
        female = this.tempData.patientsVWD_Age_45OrOlder_Gender_Female;
        unknown = this.tempData.patientsVWD_Age_45OrOlder_Gender_Unknown;

        total = this.tempData.patientsVWD_Age_45OrOlder;
      } else if (line == "unknown") {
        male = this.tempData.patientsVWD_Age_Unknown_Gender_Male;
        female = this.tempData.patientsVWD_Age_Unknown_Gender_Female;
        unknown = this.tempData.patientsVWD_Age_Unknown_Gender_Unknown;

        total = this.tempData.patientsVWD_Age_Unknown;
      }
      return total == male + female + unknown;
    },
    ruleTotalSum(column: string) {
      //Column = HemophiliaA (Q1A)
      if (
        column == "Q1A" &&
        !this.tempData.patientsHemophiliaTypeA_Age_HasNoData &&
        !this.tempData.patientsHemophiliaTypeA_Gender_HasNoData &&
        this.tempData.patientsHemophiliaTypeA_Gender_Total != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeA_Gender_Total - this.sumOfQ1HA !=
          0
        ) {
          if (!this.totalNotSumQ1Errors.includes(column)) {
            this.totalNotSumQ1Errors.push(column);
          }
          return [false];
        } else {
          this.removeFromQ1Errors("Q1A");
          return [true];
        }
      }
      //Column = HemophiliaB (Q1B)
      if (
        column == "Q1B" &&
        !this.tempData.patientsHemophiliaTypeB_Age_HasNoData &&
        !this.tempData.patientsHemophiliaTypeB_Gender_HasNoData &&
        this.tempData.patientsHemophiliaTypeB_Gender_Total != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeB_Gender_Total - this.sumOfQ1HB !=
          0
        ) {
          if (!this.totalNotSumQ1Errors.includes(column)) {
            this.totalNotSumQ1Errors.push(column);
          }
          return [false];
        } else {
          this.removeFromQ1Errors("Q1B");
          return [true];
        }
      }
      //Column = HemophiliaUnknown (Q1C)
      if (
        column == "Q1C" &&
        !this.tempData.patientsHemophiliaTypeUnknown_Age_HasNoData &&
        !this.tempData.patientsHemophiliaTypeUnknown_Gender_HasNoData &&
        this.tempData.patientsHemophiliaTypeUnknown_Gender_Total != null
      ) {
        if (
          this.tempData.patientsHemophiliaTypeUnknown_Gender_Total -
            this.sumOfQ1HC !=
          0
        ) {
          if (!this.totalNotSumQ1Errors.includes(column)) {
            this.totalNotSumQ1Errors.push(column);
          }
          return [false];
        } else {
          this.removeFromQ1Errors("Q1C");
          return [true];
        }
      }
      //Column = VWD (Q2)
      if (
        column == "Q2" &&
        !this.tempData.patientsVWD_Age_HasNoData &&
        !this.tempData.patientsVWD_Gender_HasNoData &&
        this.tempData.patientsVWD_Gender_Total != null
      ) {
        if (this.tempData.patientsVWD_Gender_Total - this.sumOfQ2 != 0) {
          if (!this.totalNotSumQ2Errors.includes(column)) {
            this.totalNotSumQ2Errors.push(column);
          }
          return [false];
        } else {
          this.removeFromQ2Errors("Q2");
          return [true];
        }
      }
      //Column = VWD (Q2M)
      if (
        column == "Q2M" &&
        !this.tempData.patientsVWD_Age_HasNoData_Gender_Male &&
        !this.tempData.patientsVWD_Gender_HasNoData &&
        this.tempData.patientsVWD_Gender_Male != null
      ) {
        if (this.tempData.patientsVWD_Gender_Male - this.sumOfQ2M != 0) {
          if (!this.totalNotSumQ2Errors.includes(column)) {
            this.totalNotSumQ2Errors.push(column);
          }
          return [false];
        } else {
          this.removeFromQ2Errors("Q2M");
          return [true];
        }
      }
      //Column = VWD (Q2F)
      if (
        column == "Q2F" &&
        !this.tempData.patientsVWD_Age_HasNoData_Gender_Female &&
        !this.tempData.patientsVWD_Gender_HasNoData &&
        this.tempData.patientsVWD_Gender_Female != null
      ) {
        if (this.tempData.patientsVWD_Gender_Female - this.sumOfQ2F != 0) {
          if (!this.totalNotSumQ2Errors.includes(column)) {
            this.totalNotSumQ2Errors.push(column);
          }
          return [false];
        } else {
          this.removeFromQ2Errors("Q2F");
          return [true];
        }
      }
      //Column = VWD (Q2U)
      if (
        column == "Q2U" &&
        !this.tempData.patientsVWD_Age_HasNoData_Gender_Unknown &&
        !this.tempData.patientsVWD_Gender_HasNoData &&
        this.tempData.patientsVWD_Gender_Unknown != null
      ) {
        if (this.tempData.patientsVWD_Gender_Unknown - this.sumOfQ2U != 0) {
          if (!this.totalNotSumQ2Errors.includes(column)) {
            this.totalNotSumQ2Errors.push(column);
          }
          return [false];
        } else {
          this.removeFromQ2Errors("Q2U");
          return [true];
        }
      }
    },
    removeFromQ1Errors(column: string) {
      let index = this.totalNotSumQ1Errors.indexOf(column);
      if (index > -1) {
        this.totalNotSumQ1Errors.splice(index, 1);
      }
    },
    removeFromQ2Errors(column: string) {
      let index = this.totalNotSumQ2Errors.indexOf(column);
      if (index > -1) {
        this.totalNotSumQ2Errors.splice(index, 1);
      }
    },
    clearHemophiliaTypeAAgeRow() {
      if (this.tempData.patientsHemophiliaTypeA_Age_HasNoData) {
        this.tempData.patientsHemophiliaTypeA_Age_0To4 = null;
        this.tempData.patientsHemophiliaTypeA_Age_5To13 = null;
        this.tempData.patientsHemophiliaTypeA_Age_14To18 = null;
        this.tempData.patientsHemophiliaTypeA_Age_19To44 = null;
        this.tempData.patientsHemophiliaTypeA_Age_45OrOlder = null;
        this.tempData.patientsHemophiliaTypeA_Age_Unknown = null;
        this.removeFromQ1Errors("Q1A");
      }
    },
    clearHemophiliaTypeBAgeRow() {
      if (this.tempData.patientsHemophiliaTypeB_Age_HasNoData) {
        this.tempData.patientsHemophiliaTypeB_Age_0To4 = null;
        this.tempData.patientsHemophiliaTypeB_Age_5To13 = null;
        this.tempData.patientsHemophiliaTypeB_Age_14To18 = null;
        this.tempData.patientsHemophiliaTypeB_Age_19To44 = null;
        this.tempData.patientsHemophiliaTypeB_Age_45OrOlder = null;
        this.tempData.patientsHemophiliaTypeB_Age_Unknown = null;
        this.removeFromQ1Errors("Q1B");
      }
    },
    clearHemophiliaTypeUnknownAgeRow() {
      if (this.tempData.patientsHemophiliaTypeUnknown_Age_HasNoData) {
        this.tempData.patientsHemophiliaTypeUnknown_Age_0To4 = null;
        this.tempData.patientsHemophiliaTypeUnknown_Age_5To13 = null;
        this.tempData.patientsHemophiliaTypeUnknown_Age_14To18 = null;
        this.tempData.patientsHemophiliaTypeUnknown_Age_19To44 = null;
        this.tempData.patientsHemophiliaTypeUnknown_Age_45OrOlder = null;
        this.tempData.patientsHemophiliaTypeUnknown_Age_Unknown = null;
        this.removeFromQ1Errors("Q1C");
      }
    },
    clearVWDAgeRow() {
      if (this.tempData.patientsVWD_Age_HasNoData) {
        this.tempData.patientsVWD_Age_0To4 = null;
        this.tempData.patientsVWD_Age_5To13 = null;
        this.tempData.patientsVWD_Age_14To18 = null;
        this.tempData.patientsVWD_Age_19To44 = null;
        this.tempData.patientsVWD_Age_45OrOlder = null;
        this.tempData.patientsVWD_Age_Unknown = null;
        this.removeFromQ2Errors("Q2");
      }
    },
    clearVWDAgeMaleRow() {
      if (this.tempData.patientsVWD_Age_HasNoData_Gender_Male) {
        this.tempData.patientsVWD_Age_0To4_Gender_Male = null;
        this.tempData.patientsVWD_Age_5To13_Gender_Male = null;
        this.tempData.patientsVWD_Age_14To18_Gender_Male = null;
        this.tempData.patientsVWD_Age_19To44_Gender_Male = null;
        this.tempData.patientsVWD_Age_45OrOlder_Gender_Male = null;
        this.tempData.patientsVWD_Age_Unknown_Gender_Male = null;
        this.removeFromQ2Errors("Q2M");
      }
    },
    clearVWDAgeFemaleRow() {
      if (this.tempData.patientsVWD_Age_HasNoData_Gender_Female) {
        this.tempData.patientsVWD_Age_0To4_Gender_Female = null;
        this.tempData.patientsVWD_Age_5To13_Gender_Female = null;
        this.tempData.patientsVWD_Age_14To18_Gender_Female = null;
        this.tempData.patientsVWD_Age_19To44_Gender_Female = null;
        this.tempData.patientsVWD_Age_45OrOlder_Gender_Female = null;
        this.tempData.patientsVWD_Age_Unknown_Gender_Female = null;
        this.removeFromQ2Errors("Q2F");
      }
    },
    clearVWDAgeUnknownRow() {
      if (this.tempData.patientsVWD_Age_HasNoData_Gender_Unknown) {
        this.tempData.patientsVWD_Age_0To4_Gender_Unknown = null;
        this.tempData.patientsVWD_Age_5To13_Gender_Unknown = null;
        this.tempData.patientsVWD_Age_14To18_Gender_Unknown = null;
        this.tempData.patientsVWD_Age_19To44_Gender_Unknown = null;
        this.tempData.patientsVWD_Age_45OrOlder_Gender_Unknown = null;
        this.tempData.patientsVWD_Age_Unknown_Gender_Unknown = null;
        this.removeFromQ2Errors("Q2U");
      }
    },
  },
  watch: {
    isEditable(res) {
      if (!res) {
        this.totalNotSumQ1Errors = [];
        this.totalNotSumQ2Errors = [];
      }
    },
  },
});
