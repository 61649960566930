
import Vue from "vue";

export default Vue.extend({
  name: "SectionInfection",
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    tempData: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
    },
  },
  methods: {
    currentSurveyYear(){
      return this.$store.getters["datamanager/getCurrentSurveyYear"];
    },
    clearHIVRow() {
      if (this.tempData.hiV_HasNoData) {
        this.tempData.hiV_PatientsHemophilia_Total = null;
        this.tempData.hiV_PatientsVWD_Total = null;
        this.tempData.hiV_PatientsOtherBleedingDisorders_Total = null;
      }
    },
    clearHIVNewRow() {
      if (this.tempData.hiV_New_HasNoData) {
        this.tempData.hiV_NewPatientsHemophilia_Total = null;
        this.tempData.hiV_NewPatientsVWD_Total = null;
        this.tempData.hiV_NewPatientsOtherBleedingDisorders_Total = null;
      }
    },
    clearHepatitisCInfectedRow() {
      if (this.tempData.hepatitisC_Infected_HasNoData) {
        this.tempData.hepatitisC_InfectedPatientsHemophilia_Total = null;
        this.tempData.hepatitisC_InfectedPatientsVWD_Total = null;
        this.tempData.hepatitisC_InfectedPatientsOtherBleedingDisorders_Total =
          null;
      }
    },
    clearHepatitisCActiveRow() {
      if (this.tempData.hepatitisC_Active_HasNoData) {
        this.tempData.hepatitisC_ActivePatientsHemophilia_Total = null;
        this.tempData.hepatitisC_ActivePatientsVWD_Total = null;
        this.tempData.hepatitisC_ActivePatientsOtherBleedingDisorders_Total =
          null;
      }
    },
    clearHepatitisCNewRow() {
      if (this.tempData.hepatitisC_New_HasNoData) {
        this.tempData.hepatitisC_NewPatientsHemophilia_Total = null;
        this.tempData.hepatitisC_NewPatientsVWD_Total = null;
        this.tempData.hepatitisC_NewPatientsOtherBleedingDisorders_Total = null;
      }
    },
  },
});
