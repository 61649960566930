
import Vue from "vue";
import FormEditFooter from "../UI-Components/FormEditFooter.vue";
import { FactorUseSection } from "@/models/factorusesection";
import { SaveRes } from "@/models/saveres";
import { Snackbar } from "@/models/snackbar";
import i18n from "@/i18n";
import formrevalidate from "../../mixins/formrevalidate";

export default Vue.extend({
  name: "FactorUse",
  mixins: [formrevalidate],
  props: {
    isAllowedToEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isEditable: false,
    tempData: {} as FactorUseSection,
    initData: {} as FactorUseSection,
    shallSkipDialog: false,
    isFormValid: true,
    hasFVIIITotalError: false,
    hasFIXTotalError: false,
    rules: {
      number: [
        (v: number | null) =>
          v != null || typeof v == "number" || i18n.t("rules.required"),
        (v: number) => v >= 0 || i18n.t("rules.positivenumber"),
      ],
      radio: [(v: boolean | null) => v != null || i18n.t("rules.plsselect")],
      mustBeNull: [
        (v: number | boolean) =>
          v == null || v == false || i18n.t("rules.mustBeNull"),
      ],
    },
  }),
  computed: {
    isPrinting() {
      return this.$store.getters["datamanager/isPrinting"];
    },
    isSurveyYearModifiable() {
      return this.$store.getters["datamanager/getIsSurveyYearModifiable"];
    },
    sectionsStatuses() {
      return this.$store.getters["datamanager/getSectionsStatuses"];
    },
    countrySelected() {
      return this.$store.getters["datamanager/getCountrySelected"];
    },
    factorUseSectionSaveRes() {
      return this.$store.getters["datamanager/getFactorUseSectionSaveRes"];
    },
    factorUseSection() {
      return this.$store.getters["datamanager/getFactorUseSection"];
    },
    currentSurveyYear() {
      return this.$store.getters["datamanager/getCurrentSurveyYear"];
    },
    hasUnsavedChanges: {
      get() {
        return this.$store.getters["datamanager/getHasUnsavedChanges"];
      },
      set(value: boolean) {
        return this.$store.dispatch(
          "datamanager/changeHasUnsavedChanges",
          value
        );
      },
    },
  },
  methods: {
    async fetchSectionsStatuses() {
      await this.$store.dispatch("datamanager/fetchSectionsStatuses", {
        country: this.countrySelected,
      });
    },
    validationTotalFVIII() {
      if (
        this.tempData.factorVIII_PlasmaDerivedConcentrates_IsNotKnown == true &&
        this.tempData.factorVIII_RecombinantConcentrates_IsNotKnown == true &&
        this.tempData
          .factorVIII_RecombinantExtendedHalfLifeConcentrates_IsNotKnown == true
      ) {
        this.hasFVIIITotalError = false;
        return [true];
      } else if (
        (this.tempData.factorVIII_FactorConcentrates_TotalIU || 0) -
          (this.tempData.factorVIII_PlasmaDerivedConcentrates_TotalIU || 0) -
          (this.tempData.factorVIII_RecombinantConcentrates_TotalIU || 0) -
          (this.tempData
            .factorVIII_RecombinantExtendedHalfLifeConcentrates_TotalIU || 0) !=
        0
      ) {
        this.hasFVIIITotalError = true;
        return [false]; //Doit etre egale a la somme total
      } else {
        this.hasFVIIITotalError = false;
        return [true];
      }
    },
    validationTotalFIX() {
      if (
        this.tempData.factorIX_PlasmaDerivedConcentrates_IsNotKnown == true &&
        this.tempData.factorIX_RecombinantConcentrates_IsNotKnown == true &&
        this.tempData
          .factorIX_RecombinantExtendedHalfLifeConcentrates_IsNotKnown == true
      ) {
        this.hasFIXTotalError = false;
        return [true];
      } else if (
        (this.tempData.factorIX_FactorConcentrates_TotalIU || 0) -
          (this.tempData.factorIX_PlasmaDerivedConcentrates_TotalIU || 0) -
          (this.tempData.factorIX_RecombinantConcentrates_TotalIU || 0) -
          (this.tempData
            .factorIX_RecombinantExtendedHalfLifeConcentrates_TotalIU || 0) !=
        0
      ) {
        this.hasFIXTotalError = true;
        return [false]; //Doit etre egale a la somme total
      } else {
        this.hasFIXTotalError = false;
        return [true];
      }
    },

    triggerSnack(snack: Snackbar) {
      this.$store.dispatch("general/changeSnackbar", snack);
    },
    resetValidation() {
      let refForm: any = this.$refs.form;
      if (refForm != undefined) refForm.resetValidation();
    },
    removeValueCauseNotKnown(selectedNotKnown: number) {
      //si coche isNotKnown - met text a null
      switch (selectedNotKnown) {
        case 1:
          this.tempData.factorVIII_IsUnableToReport = false;
          this.tempData.factorVIII_FactorConcentrates_TotalIU = null;
          this.hasFVIIITotalError = false;
          break;
        case 2:
          this.tempData.factorVIII_IsUnableToReport = false;
          this.tempData.factorVIII_PlasmaDerivedConcentrates_TotalIU = null;
          break;
        case 3:
          this.tempData.factorVIII_IsUnableToReport = false;
          this.tempData.factorVIII_RecombinantConcentrates_TotalIU = null;
          break;
        case 4:
          this.tempData.factorVIII_IsUnableToReport = false;
          this.tempData.factorVIII_RecombinantExtendedHalfLifeConcentrates_TotalIU = null;
          break;
        case 5:
          this.tempData.factorIX_IsUnableToReport = false;
          this.tempData.factorIX_FactorConcentrates_TotalIU = null;
          this.hasFIXTotalError = false;
          break;
        case 6:
          this.tempData.factorIX_IsUnableToReport = false;
          this.tempData.factorIX_PlasmaDerivedConcentrates_TotalIU = null;
          break;
        case 7:
          this.tempData.factorIX_IsUnableToReport = false;
          this.tempData.factorIX_RecombinantConcentrates_TotalIU = null;
          break;
        case 8:
          this.tempData.factorIX_IsUnableToReport = false;
          this.tempData.factorIX_RecombinantExtendedHalfLifeConcentrates_TotalIU = null;
          break;
        case 9:
          this.tempData.factorVIII_DonatedFactorConcentrates_TotalIU = null;
          break;
        case 10:
          this.tempData.factorIX_DonatedFactorConcentrates_TotalIU = null;
          break;
        case 11:
          this.tempData.purchasedHemlibra_TotalMg = null;
          break;
        case 12:
          this.tempData.purchasedFactorVIIA_TotalMg = null;
          break;
        case 13:
          this.tempData.purchasedFEIBA_TotalIU = null;
          break;
        case 14:
          this.tempData.geneTherapy_SurveyYearTotal = null;
          break;
        case 15:
          this.tempData.geneTherapy_Total = null;
          break;
      }
    },
    removeFactorVIIICauseIsUnableToReport() {
      //si coche unableToReport - met toute la section a null
      if (this.tempData.factorVIII_IsUnableToReport) {
        this.tempData.factorVIII_FactorConcentrates_TotalIU = null;
        this.tempData.factorVIII_FactorConcentrates_IsNotKnown = false;
        this.tempData.factorVIII_PlasmaDerivedConcentrates_TotalIU = null;
        this.tempData.factorVIII_PlasmaDerivedConcentrates_IsNotKnown = false;
        this.tempData.factorVIII_RecombinantConcentrates_TotalIU = null;
        this.tempData.factorVIII_RecombinantConcentrates_IsNotKnown = false;
        this.tempData.factorVIII_RecombinantExtendedHalfLifeConcentrates_TotalIU = null;
        this.tempData.factorVIII_RecombinantExtendedHalfLifeConcentrates_IsNotKnown = false;
      }
    },
    removeFactorIXCauseIsUnableToReport() {
      //si coche unableToReport - met toute la section a null
      if (this.tempData.factorIX_IsUnableToReport) {
        this.tempData.factorIX_FactorConcentrates_TotalIU = null;
        this.tempData.factorIX_FactorConcentrates_IsNotKnown = false;
        this.tempData.factorIX_PlasmaDerivedConcentrates_TotalIU = null;
        this.tempData.factorIX_PlasmaDerivedConcentrates_IsNotKnown = false;
        this.tempData.factorIX_RecombinantConcentrates_TotalIU = null;
        this.tempData.factorIX_RecombinantConcentrates_IsNotKnown = false;
        this.tempData.factorIX_RecombinantExtendedHalfLifeConcentrates_TotalIU = null;
        this.tempData.factorIX_RecombinantExtendedHalfLifeConcentrates_IsNotKnown = false;
      }
    },
    saveit() {
      this.shallSkipDialog = false;
      this.isEditable = false;
      this.saveSurveyFactorUseSection()
        .then((e) => {
          if (e.status == 200) {
            let snack = {
              isSnacking: true,
              snackColor: "success",
              snackMessage: this.$t("basic.savesuccess").toString(),
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
            this.fetchSectionsStatuses();
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let snack = {
              isSnacking: true,
              snackColor: "orange",
              snackMessage:
                this.$t("basic.savewarning").toString() +
                err.response.statusText,
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          } else {
            let snack = {
              isSnacking: true,
              snackColor: "error",
              snackMessage:
                this.$t("basic.saveerror").toString() + err.response.statusText,
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          }
          this.isEditable = true;
        });
    },
    willSave() {
      this.tempData.factorUseSectionIsComplete = true;
      this.saveit();
    },
    willSaveAsDraft() {
      this.tempData.factorUseSectionIsComplete = false;
      this.saveit();
    },

    reloadTempData() {
      this.tempData = { ...this.initData };
      this.resetValidation();
    },
    async saveSurveyFactorUseSection() {
      await this.$store.dispatch("datamanager/saveSurveyFactorUseSection", {
        country: this.countrySelected,
        newData: this.tempData,
      });
      return this.factorUseSectionSaveRes as SaveRes;
    },
  },
  watch: {
    factorUseSection: {
      handler(res) {
        this.initData = { ...res };
        if (res.factorUseSectionIsComplete != null) {
          this.isEditable = false;
        } else {
          if (
            !this.sectionsStatuses.isApproved &&
            this.isAllowedToEdit &&
            this.isSurveyYearModifiable
          ) {
            this.isEditable = true;
          }
          this.shallSkipDialog = true;
        }
        this.reloadTempData();
      },
      deep: true,
    },
    isSurveyYearModifiable: {
      handler(res) {
        if (res == false) {
          this.isEditable = false;
        }
      },
      deep: true,
    },
    tempData: {
      handler(res) {
        if (JSON.stringify(res) === JSON.stringify(this.initData)) {
          this.hasUnsavedChanges = false;
        } else if (this.hasUnsavedChanges == false) {
          this.hasUnsavedChanges = true;
        }
      },
      deep: true,
    },
  },
  components: {
    "form-edit-footer": FormEditFooter,
  },
});
