import Vue from "vue";

export default Vue.extend({
  computed: {
    language() {
      return this.$i18n.locale;
    },
  },
  watch: {
    language: {
      handler(res) {
        let refForm: any = this.$refs.form;
        if (refForm != undefined) refForm.validate();
      },
    },
  },
});
