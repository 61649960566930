
import Vue from "vue";
import { mapGetters } from "vuex";
import { ProductSection } from "@/models/productsection";
//import Currency from "./Currency.vue";
import FormEditFooter from "../UI-Components/FormEditFooter.vue";
import { Manufacturer } from "@/models/manufacturer";
import { ProductType } from "@/models/producttype";
import { SaveRes } from "@/models/saveres";
import i18n from "@/i18n";
import { Snackbar } from "@/models/snackbar";
import formrevalidate from "../../mixins/formrevalidate";

export default Vue.extend({
  name: "Products",
  mixins: [formrevalidate],
  props: {
    isAllowedToEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    initData: [] as ProductSection[],
    tempUsedProducts: [] as ProductSection[],
    tempNotUsedProducts: [] as ProductSection[],
    dirtyProducts: [] as ProductSection[],
    shallSkipDialog: false,
    isFormValid: true,
    show: false,
    search: "",

    newProduct: {
      key: null,
      isChecked: true,
      productKey: "",
      productBrandName: "",
      productTypeKey: "",
      productTypeName: "",
      manufacturerKey: "",
      manufacturer: "",
      price: "",
      isPerVial: false,
      isDirty: true,
      isValid: false,
    } as ProductSection,
    usedProdTableKey: "1",
    hasUsedAndNotUsedProductsLoaded: false,
    rules: {
      requiredString: [
        (v: string | null) =>
          v != null && v.length > 0 ? true : i18n.t("rules.required"),
      ],
    },
    isAddProdDisabled: false,
    isNewProductValid: false,
    isProductAdding: false,
  }),
  computed: {
    isPrinting(){
      return this.$store.getters["datamanager/isPrinting"];
    },
    isSurveyYearModifiable() {
      return this.$store.getters["datamanager/getIsSurveyYearModifiable"];
    },
    sectionsStatuses() {
      return this.$store.getters["datamanager/getSectionsStatuses"];
    },
    productSection() {
      return this.$store.getters["datamanager/getProductSection"];
    },
    productSectionSaveRes() {
      return this.$store.getters["datamanager/getProductSectionSaveRes"];
    },
    manufacturers() {
      return this.$store.getters["datamanager/getManufacturers"];
    },
    productTypes() {
      return this.$store.getters["datamanager/getProductTypes"];
    },
    countrySelected() {
      return this.$store.getters["datamanager/getCountrySelected"];
    },
    headers() {
      return [
        {
          text: i18n.t("datamanager.products.header_type"),
          value: "productTypeName",
          width: "15%",
        },
        {
          text: i18n.t("datamanager.products.header_brandname"),
          value: "productBrandName",
          width: "20%",
        },
        {
          text: i18n.t("datamanager.products.header_manufacturer"),
          value: "manufacturer",
          width: "25%",
        },
        {
          text: i18n.t("datamanager.products.header_priceperiu"),
          value: "price",
          width: "30%",
        },
        {
          text: i18n.t("datamanager.products.header_remove"),
          value: "isChecked",
          width: "8%",
          sortable: false,
        },
      ];
    },

    headers2() {
      return [
        {
          text: i18n.t("datamanager.products.header_type"),
          value: "productTypeName",
          width: "15%",
        },
        {
          text: i18n.t("datamanager.products.header_brandname"),
          value: "productBrandName",
          width: "20%",
        },
        {
          text: i18n.t("datamanager.products.header_manufacturer"),
          value: "manufacturer",
          width: "25%",
        },
        {
          text: i18n.t("datamanager.products.header_priceperiu"),
          value: "price",
          width: "30%",
        },
        {
          text: i18n.t("datamanager.products.header_add"),
          value: "isChecked",
          width: "7%",
        },
      ];
    },
    isReadOnly: {
      get() {
        return this.$store.getters["datamanager/getIsReadOnly"];
      },
      set(value: boolean) {
        return this.$store.dispatch("datamanager/changeIsReadOnly", value);
      },
    },
    hasUnsavedChanges: {
      get() {
        return this.$store.getters["datamanager/getHasUnsavedChanges"];
      },
      set(value: boolean) {
        return this.$store.dispatch(
          "datamanager/changeHasUnsavedChanges",
          value
        );
      },
    },
  },
  methods: {
    triggerSnack(snack: Snackbar) {
      this.$store.dispatch("general/changeSnackbar", snack);
    },
    checkIsAddProdDisabled() {
      if (this.newProduct.productBrandName.length == 0) {
        this.isAddProdDisabled = true;
        this.isNewProductValid = false;
      } else {
        this.isNewProductValid = true;
        this.isAddProdDisabled = false;
      }
    },
    validateNewProduct() {
      let refType = (this.$refs["type"] as unknown) as {
        validate: (b: boolean) => void;
      };
      let refBrandName = (this.$refs["brandname"] as unknown) as {
        validate: (b: boolean) => void;
      };
      let refManufacturer = (this.$refs["manufacturer"] as unknown) as {
        validate: (b: boolean) => void;
      };
      let refPrice = (this.$refs["price"] as unknown) as {
        validate: (b: boolean) => void;
      };
      if (refType != null) refType["validate"](true);
      if (refBrandName != null) refBrandName["validate"](true);
      if (refManufacturer != null) refManufacturer["validate"](true);
      if (refPrice != null) refPrice["validate"](true);
      this.checkIsAddProdDisabled();
    },

    cancelMod() {
      this.isReadOnly = true;
      if (this.dirtyProducts.length > 0) {
        this.getUsedAndNotUsedProducts();
      }
    },
    getUsedAndNotUsedProducts() {
      this.dirtyProducts = [];
      this.tempUsedProducts = [] as ProductSection[];
      this.tempNotUsedProducts = [] as ProductSection[];
      this.hasUsedAndNotUsedProductsLoaded = false;
      this.initData.forEach((prod) => {
        if (prod.isChecked) {
          this.tempUsedProducts.push({ ...prod });
        } else {
          this.tempNotUsedProducts.push({ ...prod });
        }
      });
      this.hasUsedAndNotUsedProductsLoaded = true;

      if (this.tempUsedProducts.length > 0) {
        this.isReadOnly = true;
        this.shallSkipDialog = false;
      } else {
        this.isReadOnly = false;
        this.shallSkipDialog = true;
      }
    },
    pricePerUiText() {
      if (this.newProduct.isPerVial) {
        this.newProduct.price = this.$t(
          "datamanager.products.pricepervialvialsize"
        ).toString();
      } else {
        this.newProduct.price = "";
      }
    },
    saveRes(res: Promise<SaveRes>) {
      this.shallSkipDialog = false;

      return res
        .then((e) => {
          if (e.status == 200) {
            this.updateSavedNewProductKey(e);
            let snack = {
              isSnacking: true,
              snackColor: "success",
              snackMessage: this.$t("basic.savesuccess").toString(),
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          }
          return e.status as Number;
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let snack = {
              isSnacking: true,
              snackColor: "orange",
              snackMessage:
                this.$t("basic.savewarning").toString() +
                err.response.statusText,
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          } else {
            let snack = {
              isSnacking: true,
              snackColor: "error",
              snackMessage:
                this.$t("basic.saveerror").toString() + err.response.statusText,
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          }
          return err.response.status as Number;
        });
    },
    makeItDirty(
      row: ProductSection,
      isUsed: boolean,
      changeRow: boolean = true
    ) {
      if (!isUsed && row.isDirty) {
        row.isDirty = false;
      } else {
        row.isDirty = true;
      }
      row.isChecked = !row.isChecked;
      if (!this.dirtyProducts.includes(row)) {
        this.dirtyProducts.push(row);
      } else if (row.key == null) {
        var index = this.dirtyProducts.indexOf(row);
        this.dirtyProducts.splice(index, 1);
      }
      if (changeRow) {
        if (isUsed) {
          this.tempUsedProducts.splice(
            this.tempUsedProducts.findIndex(
              (obj) => obj.productKey == row.productKey
            ),
            1
          );
          if (row.isValid) {
            this.tempNotUsedProducts.push(row);
          }
        } else {
          this.tempNotUsedProducts.splice(
            this.tempNotUsedProducts.findIndex(
              (obj) => obj.productKey == row.productKey
            ),
            1
          );
          this.tempUsedProducts.push(row);
        }
      }
    },
    updateNewProdType(row: ProductType) {
      this.newProduct.productTypeName = row.name;
      this.newProduct.productTypeKey = row.key;
    },
    updateNewProdManufacturer(row: Manufacturer) {
      this.newProduct.manufacturer = row.name;
      this.newProduct.manufacturerKey = row.key;
    },
    updateSavedNewProductKey(res: SaveRes) {
      let data = res.data;
      data.forEach((product: ProductSection) => {
        for (let i = this.tempUsedProducts.length; i > 0; i--) {
          if (
            product.productBrandName ==
              this.tempUsedProducts[i - 1].productBrandName &&
            product.productTypeName ==
              this.tempUsedProducts[i - 1].productTypeName &&
            product.manufacturer == this.tempUsedProducts[i - 1].manufacturer
          ) {
            //va chier car on peut avoir plein de prod pareil live...
            //voir si les values sont null les == vont work?
            this.tempUsedProducts[i - 1].productKey = product.productKey;
            this.tempUsedProducts[i - 1].key = product.key;
            i = 0;
          }
        }
      });
    },
    saveUpdateProduct() {
      if (this.dirtyProducts.length > 0) {
        let res = this.saveSurveyProductSection(this.dirtyProducts);
        this.saveRes(res).then((e) => {
          if (e == 200) {
            this.dirtyProducts = [] as ProductSection[];
            this.isReadOnly = true;
          }
        });
      } else {
        this.isReadOnly = true;
      }
    },
    checkIfNewProdIsUnique(): boolean {
      let response = true;
      this.tempUsedProducts.forEach((product: ProductSection) => {
        if (
          product.productBrandName == this.newProduct.productBrandName &&
          product.productTypeName == this.newProduct.productTypeName &&
          product.manufacturer == this.newProduct.manufacturer
        ) {
          response = false;
        }
      });
      return response;
    },
    addNewProduct() {
      if (this.$refs.form != undefined) {
        this.isProductAdding = true;
        let refForm: any = this.$refs.form;
        if (refForm != undefined && refForm.validate()) {
          if (this.checkIfNewProdIsUnique()) {
            this.dirtyProducts.push(this.newProduct);
            this.tempUsedProducts.push(this.newProduct);
            this.newProduct = {
              key: null,
              isChecked: true,
              productKey: "",
              productBrandName: "",
              productTypeKey: "",
              productTypeName: "",
              manufacturerKey: "",
              manufacturer: "",
              price: "",
              isPerVial: false,
              isDirty: true,
              isValid: false,
            };
            let snack = {
              isSnacking: true,
              snackColor: "info",
              snackMessage: this.$t(
                "datamanager.products.productaddedtousedlistsuccesfully"
              ).toString(),
              snackTimeout: "3500",
            };
            this.triggerSnack(snack);
          } else {
            let snack = {
              isSnacking: true,
              snackColor: "yellow",
              snackMessage: this.$t(
                "datamanager.products.productalreadyexisterror"
              ).toString(),
              snackTimeout: "3500",
            };
            this.triggerSnack(snack);
          }
        }
      } else {
        this.isAddProdDisabled = true;
        this.validateNewProduct();
      }
      this.isProductAdding = false;
    },
    async saveSurveyProductSection(prods: ProductSection[]) {
      await this.$store.dispatch("datamanager/saveSurveyProductSection", {
        country: this.countrySelected,
        newData: prods,
      });
      return this.productSectionSaveRes as SaveRes;
    },
  },
  watch: {
    newProduct: {
      deep: true,
      handler() {
        this.validateNewProduct();
      },
    },
    productSection: {
      handler(res) {
        this.initData = [...res];
        this.getUsedAndNotUsedProducts();
      },
      deep: true,
    },
    dirtyProducts: {
      handler(res) {
        if (res.length > 0) {
          this.hasUnsavedChanges = true;
        } else {
          this.hasUnsavedChanges = false;
        }
      },
      deep: true,
    },
  },
  components: {
    "form-edit-footer": FormEditFooter,
  },
});
